// Fix for Jest - not able to find hidden input without providing explicitly the role
import React, { CSSProperties, ReactNode } from "react";

import FormHelperText from "@mui/material/FormHelperText";

import FormInputLabel from "../../../common/components/FormInputLabel";
import TextFieldTTLEditor from "./TextFieldTTLEditor";

interface Props {
  id: string;
  title: string;
  description: string | ReactNode;
  value: string;
  onChange: (value: string) => void;
  errorText?: string;
  style?: CSSProperties;
  disabled?: boolean;
  withMilliseconds?: boolean;
}

export default function TTLField({
  id,
  title,
  description,
  value,
  onChange,
  errorText,
  style = {},
  disabled,
  withMilliseconds,
}: Props) {
  return (
    <div id={id} style={{ marginBottom: 32, ...style }}>
      <div style={{ display: "flex", marginTop: 4 }}>
        <div style={{ flex: 1 }}>
          <FormInputLabel id={`${id}-title`} label={title} optional={false} />
          <FormHelperText id={`${id}-description`} style={{ flex: 1, paddingRight: 8 }}>
            {description}
          </FormHelperText>
        </div>
        <div>
          <TextFieldTTLEditor
            id={`${id}-editor`}
            onChange={onChange}
            value={value}
            isError={!!errorText}
            disabled={disabled}
            withMilliseconds={withMilliseconds}
          />
          <input type="hidden" role="textbox" value={value} id={`ttl-field-${id}-value-input`} />
          <FormHelperText style={{ minHeight: 20 }} error>
            {errorText}
          </FormHelperText>
        </div>
      </div>
    </div>
  );
}
