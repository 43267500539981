import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import { OrganizationResponse, ServerResponse, WorkspaceResponse } from "@cloudentity/acp-admin";

import EmptyState from "../../../common/components/EmptyState";
import OrganizationEmptyStateIcon from "../../../common/components/icons/OrganizationEmptyStateIcon";
import { getFromLocalStorage } from "../../../common/utils/localStorage.utils";
import { useCheckTenantPermissions } from "../../services/adminPermissionsQuery";
import InfiniteScrollLoading from "../common/EnhancedTableAsync/InfiniteScrollLoading";
import { useInfiniteScrollForItemsWithQuery } from "../common/EnhancedTableAsync/useItemsWithQuery";
import useOrganizationsSeqOrCursor from "../common/EnhancedTableAsync/useOrganizationsSeqOrCursor";
import PageContent from "../common/PageContent";
import OrganizationCard from "../workspaceDirectory/organizations/OrganizationCard";
import OrganizationCardMenu from "../workspaceDirectory/organizations/OrganizationCardMenu";
import OrganizationsHierarchy from "../workspaceDirectory/organizations/OrganizationsHierarchy";
import OrganizationsToolbar from "../workspaceDirectory/organizations/OrganizationsToolbar";
import { GridMode } from "../workspaceDirectory/organizations/utils";

interface Props {
  title: string;
  description: string | React.ReactNode;
  parentServer: OrganizationResponse | ServerResponse | undefined;
  withEdit?: boolean;
  withCreateSuborganization?: boolean;
  gridModeLocalStorageKey: string;
  onSelect: (id: string) => void;
  onCreate: (parentId?: string) => void;
}

export default function Suborganizations({
  title,
  description,
  parentServer,
  withEdit,
  withCreateSuborganization,
  gridModeLocalStorageKey,
  onSelect,
  onCreate,
}: Props) {
  const [gridMode, setGridMode] = useState<GridMode>(
    (getFromLocalStorage(gridModeLocalStorageKey) as GridMode) || "cards"
  );
  const [workspaceMenu, setWorkspaceMenu] = useState<{
    anchorEl: HTMLElement | null;
    server: OrganizationResponse;
    rootServer?: OrganizationResponse | WorkspaceResponse;
  }>();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListOrganizations = !!checkTenantPermissionsQuery?.data?.list_organizations;
  const canCreateOrganization = !!checkTenantPermissionsQuery?.data?.create_organization;

  const data = useOrganizationsSeqOrCursor({
    forceMode: "seq",
    parentId: parentServer?.id,
    enabled: canListOrganizations,
    template: false,
  });

  useInfiniteScrollForItemsWithQuery({ onLastPage: data.onLastPage });

  const isEmpty = !data.totalData.length && !data.areFiltersApplied && !data.searchText;

  const toolbar = (
    <OrganizationsToolbar
      searchText={data.searchText}
      onUpdate={
        gridMode !== "hierarchy" ? text => data.onFiltersUpdate(text, data.filters) : undefined
      }
      searchDisabled={isEmpty}
      onCreate={() => onCreate(parentServer?.id || "")}
      createButtonLabel="Create organization"
      gridMode={canListOrganizations ? gridMode : undefined}
      setGridMode={canListOrganizations ? setGridMode : undefined}
      gridModeLocalStorageKey={gridModeLocalStorageKey}
    />
  );

  const emptyState = (
    <EmptyState
      title={title}
      description={canCreateOrganization ? description : ""}
      icon={OrganizationEmptyStateIcon}
      buttonContent="+ Create organization"
      onSubmit={canCreateOrganization ? () => onCreate(parentServer?.id || "") : undefined}
      style={{ marginTop: 80 }}
    />
  );

  return (
    <PageContent>
      {gridMode === "cards" && (
        <>
          {!isEmpty && canListOrganizations && canCreateOrganization && toolbar}
          {isEmpty ? (
            <div id="first-org-txt">{emptyState}</div>
          ) : data.totalData.length ? (
            <>
              <Grid container spacing={3}>
                {data.totalData.map(organization => (
                  <Grid item xs={12} sm={12} md={6} lg={3} key={organization.id}>
                    <OrganizationCard
                      server={organization}
                      onSelectServer={onSelect}
                      onMenuOpen={setWorkspaceMenu}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <div style={{ color: "gray" }}>No organizations found</div>
          )}
          <InfiniteScrollLoading isFetching={data.isFetching && !data.firstPageLoading} />
        </>
      )}

      {gridMode === "hierarchy" && (
        <OrganizationsHierarchy
          toolbar={toolbar}
          emptyState={emptyState}
          onSelectServer={onSelect}
          onMenuOpen={setWorkspaceMenu}
          rootOrganization={parentServer}
          showRootCard
        />
      )}

      {workspaceMenu && (
        <OrganizationCardMenu
          workspace={workspaceMenu.server}
          onSelectServer={onSelect}
          handleClose={() => setWorkspaceMenu(p => (p ? { ...p, anchorEl: null } : undefined))}
          mainMenuAnchorEl={workspaceMenu.anchorEl}
          gridMode={gridMode}
          withChangeParentOrganization={
            !!workspaceMenu.server?.parent_id && gridMode === "hierarchy"
          }
          withEdit={withEdit}
          withCreateSuborganization={withCreateSuborganization}
          rootServer={parentServer}
          onCreate={onCreate}
        />
      )}
    </PageContent>
  );
}
