import { useQuery } from "@tanstack/react-query";

import { WorkspacePermissionsResponse } from "@cloudentity/acp-admin";

import adminPermissionsApi from "./adminPermissionsApi";

const CHECK_TENANT_PERMISSIONS = ["CHECK_TENANT_PERMISSIONS"];
const CHECK_WORKSPACE_PERMISSIONS = ["CHECK_WORKSPACE_PERMISSIONS"];

export const getCheckWorkspacePermissionsQueryKey = (wid: string) => [
  ...CHECK_WORKSPACE_PERMISSIONS,
  wid,
];

export const useCheckTenantPermissions = (options?) =>
  useQuery({
    queryKey: CHECK_TENANT_PERMISSIONS,
    queryFn: async () => {
      const response = await adminPermissionsApi.checkTenantPermissions({});
      return response.data;
    },
    enabled: options?.enabled,
  });

export const useCheckWorkspacePermissions = (wid, options?) =>
  useQuery<WorkspacePermissionsResponse>({
    queryKey: getCheckWorkspacePermissionsQueryKey(wid),
    queryFn: async () => {
      const response = await adminPermissionsApi.checkWorkspacePermissions({ wid });
      return response.data;
    },
    ...{ ...options, enabled: options?.enabled ?? !!wid },
  });
