import React, { useEffect, useRef } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { acpBasePath, apiPrefix } from "../../common/api/paths";
import { cloudentityAuth } from "../../common/auth/actions/actions";
import { GlobalStoreContext } from "../GlobalStore/GlobalStore";
import { useCheckTenantPermissions } from "./adminTenantsQuery";
import { onMessage } from "./notificationsMap";

export const useReactQuerySubscription = (enabled = true) => {
  const queryClient = useQueryClient();
  const checkTenantPermissionsQuery = useCheckTenantPermissions({ enabled });
  const globalStoreContext = React.useContext(GlobalStoreContext);
  const wsRef = useRef<WebSocket>();

  React.useEffect(() => {
    if (checkTenantPermissionsQuery.data?.read_system_notifications && !wsRef.current) {
      let authToken = cloudentityAuth.getAccessToken();
      let url: string;

      if (process.env.NODE_ENV === "development") {
        url = `wss://localhost:8443${apiPrefix}/notifications`;
      } else {
        url = `wss://${window.location.host}${acpBasePath}${apiPrefix}/notifications`;
      }

      wsRef.current = new WebSocket(url);

      wsRef.current.onopen = () => wsRef.current?.send(`{"token": "${authToken}"}`);

      wsRef.current.onerror = (e: Event) => console.error(e);

      return () => {
        wsRef.current?.close();
      };
    }
  }, [checkTenantPermissionsQuery.data]);

  useEffect(() => {
    if (wsRef.current) {
      wsRef.current.onmessage = onMessage(queryClient, globalStoreContext);
    }
  }, [queryClient, globalStoreContext]);
};
