import React, { CSSProperties, ReactNode } from "react";

import { DialogProps } from "@mui/material/Dialog";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "tss-react/mui";

import Dialog from "./Dialog";

const useStyles = makeStyles<{ noPadding?: boolean }>()((theme, { noPadding }) => ({
  dialogRoot: {
    "& .MuiDialogContent-root": {
      padding: "0 !important",
    },
  },
  loading: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  content: {
    padding: noPadding ? 0 : 32,
    minHeight: "100%",
    boxSizing: "border-box",
    backgroundColor: theme.custom.greys.background,
  },
}));

interface Props extends Omit<DialogProps, "open" | "title" | "fullScreen"> {
  id: string;
  title?: string | ReactNode;
  onCancel?: () => void;
  onClose?: () => void;
  isLoading?: boolean;
  children: ReactNode;
  contentStyle?: CSSProperties;
  titleStyle?: CSSProperties;
  noPadding?: boolean;
  open?: boolean;
}

const DialogFullScreen = ({
  id,
  title,
  onCancel,
  onClose,
  isLoading,
  children,
  contentStyle = {},
  noPadding,
  titleStyle = {},
  open = true,
  ...dialogProps
}: Props) => {
  const { classes } = useStyles({ noPadding });

  return (
    <Dialog
      fullScreen
      id={id}
      onClose={onClose}
      onCancel={onCancel}
      withCloseIcon
      className={classes.dialogRoot}
      title={title}
      titleStyle={titleStyle}
      open={open}
      {...dialogProps}
    >
      {isLoading && <LinearProgress className={classes.loading} />}

      <div className={classes.content} style={contentStyle}>
        {children}
      </div>
    </Dialog>
  );
};

export default DialogFullScreen;
