import React, { useMemo, useState } from "react";

import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { BookOpen } from "react-feather";
import { makeStyles } from "tss-react/mui";

import {
  PasswordSettingsHashingMethodEnum,
  PoolAuthenticationMechanismsEnum,
  PoolResponse,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../common/api/paths";
import FormInputLabel from "../../../../../common/components/FormInputLabel";
import RouteLeavingGuard from "../../../../../common/components/RouteLeavingGuard";
import ApplicationReadOnlyInputWithCopy from "../../../../../common/components/applications/ApplicationReadOnlyInputWithCopy";
import IdentityPoolSideNavIcon from "../../../../../common/components/icons/IdentityPoolSideNavIcon";
import AutocompleteField from "../../../../../common/utils/forms/AutocompleteField";
import CheckboxField from "../../../../../common/utils/forms/CheckboxField";
import Form, { useForm } from "../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../common/utils/forms/FormFooter";
import { useGetAuthorizationServer } from "../../../../services/adminServersQuery";
import PageContent from "../../../common/PageContent";
import { convertDurationToDays, SaveFormType, usePoolRootUrl } from "../utils";
import { notAllowedAuthenticationMechanismsByServer } from "./IdentityPool";
import IdentityPoolAuthenticationMechanisms from "./IdentityPoolAuthenticationMechanisms";
import IdentityPoolOTPSettings from "./IdentityPoolOTPSettings";
import IdentityPoolPasswordSettings from "./IdentityPoolPasswordSettings";
import IdentityPoolSettingsAccordion from "./IdentityPoolSettingsAccordion";

const useStyles = makeStyles()(theme => ({
  checkboxes: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  options: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  paper: {
    background: "#F7FAFF",
    padding: 32,
  },
  divider: {
    marginTop: 24,
    marginBottom: 24,
    marginLeft: -32,
    marginRight: -32,
  },
  link: {
    display: "block",
    marginBottom: 16,
  },
  label: {
    fontWeight: "bold",
    fontSize: 12,
    color: theme.palette.secondary.light,
    textTransform: "uppercase",
    marginBottom: 16,
  },
}));

interface Props {
  handleUpdate: (data: SaveFormType) => void;
  expandedSettingsAccordions: string[];
  setExpandedSettingsAccordions: (accordion: string[]) => void;
  pool: PoolResponse | undefined;
  noManagePermission: boolean;
}

export default function IdentityPoolSigninAndSignup({
  handleUpdate,
  expandedSettingsAccordions,
  setExpandedSettingsAccordions,
  pool,
  noManagePermission,
}: Props) {
  const { classes } = useStyles();

  const [progress] = useState(false);

  const data = useMemo(
    () => ({
      ...pool,
      admin_initiated_registration: true,
      password_settings: {
        ...pool?.password_settings,
        expires_after: convertDurationToDays(pool?.password_settings?.expires_after),
      },
    }),
    [pool]
  );

  const serverQuery = useGetAuthorizationServer(getTenantId(), pool?.workspace_id, {
    enabled: !!pool?.workspace_id,
  });

  const form = useForm({
    id: "identity-pool",
    initialValues: data,
    progress,
    noManagePermission,
  });

  const { mode } = usePoolRootUrl();
  const authenticationMechanisms = form.watch("authentication_mechanisms") || [];
  const secondFactorAuthenticationMechanisms =
    form.watch("second_factor_authentication_mechanisms") || [];

  const handleAccordionChange = (panel: string) => (_, isExpanded: boolean) => {
    setExpandedSettingsAccordions(
      isExpanded ? [panel] : expandedSettingsAccordions.filter(a => panel !== a)
    );
  };

  return (
    <PageContent fullWidth={false}>
      <Form form={form}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Paper style={{ padding: 32 }}>
              <IdentityPoolSettingsAccordion
                id="sign-in"
                label="Sign-in"
                onChange={handleAccordionChange("sign-in")}
                expanded={expandedSettingsAccordions.includes("sign-in")}
              >
                <IdentityPoolAuthenticationMechanisms
                  workspaceId={pool?.workspace_id}
                  progress={progress}
                  settings={{
                    [PoolAuthenticationMechanismsEnum.Password]: () =>
                      setExpandedSettingsAccordions(["sign-in", "password"]),
                    [PoolAuthenticationMechanismsEnum.Otp]: () =>
                      setExpandedSettingsAccordions(["sign-in", "otp"]),
                  }}
                  noManagePermission={noManagePermission}
                />

                <>
                  <FormInputLabel
                    id="signin-identifier-settings"
                    label="Sign-in Identifier Settings"
                  />
                  <CheckboxField
                    name="identifier_case_insensitive"
                    label="Case Insensitive Identifiers"
                    helperText="When selected email and username case sensitivity will be ignored during user signin. This setting does not affect signup process."
                    disabled
                    withTooltip={
                      <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                        <Typography
                          variant="body2"
                          style={{ fontSize: 12, fontWeight: 600, color: "#F2F4FF" }}
                        >
                          Unable to edit property
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{
                            marginTop: 12,
                            fontWeight: 400,
                            color: "rgba(255, 255, 255, 0.80)",
                          }}
                        >
                          To {pool?.identifier_case_insensitive ? "disable" : "enable"} case
                          Insensitive Identifiers please contact support
                        </Typography>
                        <Link
                          href="https://cloudentity.atlassian.net/servicedesk/customer/portal/20"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            marginTop: 16,
                            display: "inline-block",
                            fontSize: 12,
                            fontWeight: 600,
                          }}
                        >
                          Contact Support
                        </Link>
                      </div>
                    }
                    style={{
                      marginBottom: 0,
                      padding: 24,
                      border: "1px solid #ECECEC",
                      borderRadius: 4,
                    }}
                  />
                </>
              </IdentityPoolSettingsAccordion>

              <IdentityPoolSettingsAccordion
                id="sign-up"
                label="Sign-up"
                onChange={handleAccordionChange("sign-up")}
                expanded={expandedSettingsAccordions.includes("sign-up")}
              >
                <FormInputLabel id="registration-modes" label="Registration Modes" />
                <div className={classes.checkboxes}>
                  <CheckboxField
                    name="public_registration_allowed"
                    label="Self Registration"
                    style={{ marginBottom: 0 }}
                  />

                  <CheckboxField
                    name="admin_initiated_registration"
                    label="Admin Initiated Registration"
                    disabled
                    style={{ marginBottom: 0 }}
                  />
                </div>
              </IdentityPoolSettingsAccordion>

              <IdentityPoolSettingsAccordion
                id="password"
                label="Password Policy"
                onChange={handleAccordionChange("password")}
                expanded={expandedSettingsAccordions.includes("password")}
              >
                <IdentityPoolPasswordSettings />
              </IdentityPoolSettingsAccordion>

              <IdentityPoolSettingsAccordion
                id="password-settings"
                label="Password Settings"
                onChange={handleAccordionChange("password-settings")}
                expanded={expandedSettingsAccordions.includes("password-settings")}
              >
                <AutocompleteField
                  name="password_settings.hashing_method"
                  label="Password Hashing Method"
                  options={[
                    PasswordSettingsHashingMethodEnum.Argon2,
                    PasswordSettingsHashingMethodEnum.Bcrypt,
                    PasswordSettingsHashingMethodEnum.Pbkdf2,
                    PasswordSettingsHashingMethodEnum.Sha,
                  ]}
                  optional={false}
                  rules={{
                    required: "Password Hashing Method is required",
                  }}
                />
              </IdentityPoolSettingsAccordion>

              <IdentityPoolSettingsAccordion
                id="otp"
                label="Verification Code Settings"
                onChange={handleAccordionChange("otp")}
                expanded={expandedSettingsAccordions.includes("otp")}
              >
                <IdentityPoolOTPSettings />
              </IdentityPoolSettingsAccordion>

              <FormFooter
                onSubmit={handleUpdate}
                disabled={
                  mode === "workspace" &&
                  notAllowedAuthenticationMechanismsByServer(
                    [...authenticationMechanisms, ...secondFactorAuthenticationMechanisms],
                    serverQuery.data?.authentication_mechanisms || []
                  ).length > 0
                }
              />
            </Paper>
          </Grid>
          <Grid item xs={5}>
            <Paper className={classes.paper}>
              <div className={classes.options}>
                <Avatar style={{ background: data.badge_color, marginBottom: 24 }}>
                  {data.name && data.name[0].toUpperCase()}
                </Avatar>
                <Typography fontFamily="monospace">{data.name}</Typography>
              </div>
              <Divider className={classes.divider} />
              <ApplicationReadOnlyInputWithCopy
                id="pool-id-info"
                label="Identity Pool Id"
                icon={IdentityPoolSideNavIcon}
                value={data.id}
              />
              <Divider className={classes.divider} />

              <ApplicationReadOnlyInputWithCopy
                id="learn-more"
                label="Learn more"
                icon={BookOpen}
                value={[
                  {
                    label: "User Authentication",
                    value:
                      "https://docs.secureauth.com/ciam/en/modern-authentication-for-saas-applications.html",
                  },
                  {
                    label: "User Registration",
                    value: "https://docs.secureauth.com/ciam/en/user-registration.html",
                  },
                  {
                    label: "User Registration and Management APIs",
                    value: "https://docs.secureauth.com/ciam-apis/identitysystem.html#tag/Users",
                  },
                ]}
                type="link"
              />
            </Paper>
          </Grid>
        </Grid>
        <RouteLeavingGuard />
      </Form>
    </PageContent>
  );
}
