import { QueryKey, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { TenantPermissionsResponse, WorkspacePermissionsResponse } from "@cloudentity/acp-admin";
import { PoolPermissionsResponse } from "@cloudentity/acp-identity";

import { handleErrorWithNotify } from "../../common/components/notifications/notificationService";
import { useCheckPoolPermissions } from "./adminIdentityPoolsQuery";
import { useCheckWorkspacePermissions } from "./adminPermissionsQuery";
import { useCheckTenantPermissions } from "./adminTenantsQuery";
import { queryClient } from "./queryClient";

export const invalidateQueries = (queryKeys: Array<QueryKey>) =>
  Promise.all(queryKeys.map(queryKey => queryClient.invalidateQueries({ queryKey })));

export const withQueryError =
  <T>(queryFunction: (...args: any) => Promise<T>, errorMessage: string) =>
  async (...args: any) => {
    try {
      return await queryFunction(...args);
    } catch (err) {
      handleErrorWithNotify(errorMessage)(err);
      throw err;
    }
  };

export const withError = (query, errorMessage: string) => {
  if (query.isError && query.isFetchedAfterMount) {
    handleErrorWithNotify(errorMessage)(query.error);
  }

  return query;
};

// function for blocking retries if net::ERR_BLOCKED_BY_CLIENT (e.g. with uBlock)
export const shouldRetryFunction = (failureCount: number, error: unknown) => {
  if (
    axios.isAxiosError(error) &&
    error.response?.status !== 401 &&
    error.message === "Network Error"
  ) {
    return false;
  } else if (axios.isAxiosError(error) && error.response?.status === 500) {
    // fix https://cloudentity.atlassian.net/browse/AUT-7993 - timescale not responding
    return false;
  } else if (axios.isAxiosError(error) && error.response?.status === 401) {
    return false;
  } else {
    return failureCount < 10;
  }
};

const useIsQueryEnabledByPoolAndPermission = (
  poolId: string,
  permission: keyof PoolPermissionsResponse,
  options?
) => {
  const permissions = useCheckPoolPermissions(poolId);

  return (options?.enabled ?? true) && !!permissions.data?.[permission];
};

export const useQueryWithPoolPermissionCheck = <T>(
  poolId: string,
  permission: keyof PoolPermissionsResponse,
  queryKey,
  queryFn: (...args: any) => Promise<T>,
  options?
) => {
  const enabled = useIsQueryEnabledByPoolAndPermission(poolId, permission, options);

  return useQuery<T>({
    queryKey,
    queryFn,
    ...{
      ...options,
      enabled,
    },
  });
};

const useIsQueryEnabledByWorkspaceAndPermission = (
  wid: string,
  permission: keyof WorkspacePermissionsResponse,
  options?
) => {
  const enabled = options?.enabled ?? true;
  const permissions = useCheckWorkspacePermissions(wid, { enabled });

  return enabled && !!permissions.data?.[permission];
};

export const useQueryWithWorkspacePermissionCheck = <T>(
  wid: string,
  permission: keyof WorkspacePermissionsResponse,
  queryKey,
  queryFn: (...args: any) => Promise<T>,
  options?
) => {
  const enabled = useIsQueryEnabledByWorkspaceAndPermission(wid, permission, options);

  return useQuery<T>({
    queryKey,
    queryFn,
    ...{
      ...options,
      enabled,
    },
  });
};

export const useIsQueryEnabledByTenantAndPermission = (
  permission: keyof TenantPermissionsResponse,
  options?
) => {
  let enabled = options?.enabled ?? true;
  const permissions = useCheckTenantPermissions({ enabled });

  return {
    enabled: enabled && !!permissions.data?.[permission],
    isLoading: permissions.isLoading,
  };
};

export const useQueryWithTenantPermissionCheck = <T>(
  permission: keyof TenantPermissionsResponse,
  queryKey,
  queryFn: (...args: any) => Promise<T>,
  options?
) => {
  const { enabled, isLoading } = useIsQueryEnabledByTenantAndPermission(permission, options);

  const result = useQuery<T>({
    queryKey,
    queryFn,
    ...{
      ...options,
      enabled,
    },
  });

  return {
    ...result,
    isLoading: result.isLoading || isLoading,
  };
};

export const useInfiniteQueryWithWorkspacePermissionCheck = <T>(
  wid: string,
  permission: keyof WorkspacePermissionsResponse,
  queryKey,
  queryFn: (...args: any) => Promise<T>,
  options?
) => {
  const enabled = useIsQueryEnabledByWorkspaceAndPermission(wid, permission, options);

  return useInfiniteQuery<T>({
    queryKey,
    queryFn,
    ...{
      ...options,
      enabled,
    },
  });
};
