import React, { ReactNode, useState } from "react";

import { makeStyles } from "tss-react/mui";

import { TenantMetadataRegistration } from "../../../admin/components/getStartedV2/utils";
import { useCheckTenantPermissions } from "../../../admin/services/adminPermissionsQuery";
import { useGetTenant } from "../../../admin/services/adminTenantsQuery";
import { useGetUserInfo } from "../../../admin/services/oauth2Query";
import { getTenantId } from "../../api/paths";
import LoadingButton from "../LoadingButton";
import {
  getFormData,
  handleMarketoFormSubmit,
  MARKETO_UPGRADE_REQUEST_FORM_ID,
} from "./marketo.utils";

const useStyles = makeStyles()(theme => ({
  submitButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  buttonLoading: {
    opacity: 0.8,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  loadingIndicator: {
    color: "white",
  },
}));

interface Props {
  label?: ReactNode;
  onUpgradeSent: () => void;
  isAccountExpired?: boolean;
}

export function UpgradeButton({ label, onUpgradeSent, isAccountExpired }: Props) {
  const { classes } = useStyles();
  const [progress, setProgress] = useState(false);

  const checkTenantPermissionsQuery = useCheckTenantPermissions({
    enabled: isAccountExpired !== true,
  });
  const canGetTenant = !!checkTenantPermissionsQuery.data?.get_tenant;

  const userinfoQuery = useGetUserInfo();
  const tenantQuery = useGetTenant(getTenantId(), {
    enabled: canGetTenant && isAccountExpired !== true,
  });

  const formData = getFormData(
    tenantQuery.data?.metadata?.registration as TenantMetadataRegistration,
    userinfoQuery.data
  );

  const loading =
    checkTenantPermissionsQuery.isLoading || userinfoQuery.isLoading || tenantQuery.isLoading;

  return (
    (canGetTenant || isAccountExpired) &&
    !loading && (
      <LoadingButton
        variant="contained"
        onClick={() =>
          handleMarketoFormSubmit(
            MARKETO_UPGRADE_REQUEST_FORM_ID,
            formData,
            setProgress,
            onUpgradeSent
          )
        }
        loading={progress}
        classes={{
          loadingIndicator: classes.loadingIndicator,
          root: classes.submitButton,
          loading: classes.buttonLoading,
        }}
      >
        {label || "Upgrade Now"}
      </LoadingButton>
    )
  );
}
