import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { QueryKey, useQueryClient, UseQueryResult } from "@tanstack/react-query";

import { getParameterByName, updateQueryParam } from "../../../../common/utils/query.utils";
import { getTableLimitInLocalStorage } from "../EnhancedTable";
import { Filters, FiltersSetup } from "./FiltersToolbar";

const INITIAL_SORT = "name";
const INITIAL_LIMIT = 99;

export type UseItemsReturnType<T> = {
  data: T[];
  totalData: T[];
  isLoading: boolean;
  isFetching: boolean;
  sort: string;
  order: "asc" | "desc";
  page: number;
  limit: number;
  nextPageAvailable: boolean;
  onPaginationUpdate: (
    newSort: string,
    newOrder: "asc" | "desc",
    newPage: number,
    newLimit: number
  ) => void;
  onParamsUpdate: (params: Partial<ItemQueryParams>) => void;
  onResetPage: () => void;
  onPrevPage: () => void;
  onNextPage: () => void;
  onLastPage: () => void;
  onFiltersUpdate: (newSearchText: string, newFilters: Filters) => void;
  filters: Filters;
  areFiltersApplied: boolean;
  searchText: string;
  firstPageLoading: boolean;
  isSuccess: boolean;
};

export type ItemQueryPage = {
  page: number;
  afterItemId: string | undefined;
  fetching: boolean;
  fetched: boolean;
  nextPageAvailable: boolean;
};

export type ItemQueryParams = {
  sort: string;
  order: "asc" | "desc";
  limit: number;
  searchText: string;
  filters: Filters;
};

const firstPage: ItemQueryPage = {
  page: 0,
  afterItemId: undefined,
  fetching: false,
  fetched: false,
  nextPageAvailable: true,
};

export default function useItemsWithQuery<T, Q>({
  id,
  filtersSetup = [],
  idGetter,
  getArray,
  getTotalItemsQueryKey,
  useQueryFn,
  initialSort = INITIAL_SORT,
  enabled = true,
  ignoreUrlParams = false,
  keepPreviousTotalData = false,
}: {
  id: string;
  filtersSetup?: FiltersSetup;
  idGetter: (item: T, queryData: Q) => string | undefined;
  getArray: (
    queryResult: Q | undefined,
    params: ItemQueryParams
  ) => { data: T[]; nextPageAvailable: boolean };
  getTotalItemsQueryKey?: (params: ItemQueryParams) => QueryKey;
  useQueryFn: (page: ItemQueryPage, params: ItemQueryParams) => UseQueryResult<Q, unknown>;
  initialSort?: string;
  enabled?: boolean;
  ignoreUrlParams?: boolean;
  keepPreviousTotalData?: boolean;
}): UseItemsReturnType<T> {
  const sortParam = getParameterByName("sort") ?? initialSort;
  const orderParam = getParameterByName("order") === "desc" ? "desc" : "asc";
  const limitParam = parseInt(getParameterByName("limit") || getTableLimitInLocalStorage(id));
  const searchTextParam = getParameterByName("search") ?? "";

  const queryClient = useQueryClient();
  const firstPageLoading = useRef(true);

  const [pages, setPages] = useState({ allPages: [firstPage], pageNumber: 0 });
  const page = pages.allPages.find(page => page.page === pages.pageNumber) ?? firstPage;

  const [params, setParams] = useState<ItemQueryParams>({
    sort: ignoreUrlParams ? initialSort : sortParam,
    order: ignoreUrlParams ? "asc" : orderParam,
    limit: ignoreUrlParams || Number.isNaN(limitParam) ? INITIAL_LIMIT : limitParam,
    searchText: ignoreUrlParams ? "" : searchTextParam,
    filters: filtersSetup.reduce(
      (acc, curr) => ({ ...acc, [curr.key]: (curr.type === "checkbox" && []) || "" }),
      {}
    ),
  });

  const {
    data: queryData,
    isLoading,
    isFetching,
    isStale,
    ...usQueryFnResults
  } = useQueryFn(page, params);
  const { data, nextPageAvailable } = useMemo(
    () => getArray(queryData, params),
    // getArray never changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryData, params]
  );

  const queryKey = getTotalItemsQueryKey && getTotalItemsQueryKey(params);
  const totalData = queryKey
    ? queryClient
        .getQueriesData<Q>({ queryKey, ...(!keepPreviousTotalData ? { stale: false } : {}) })
        ?.flatMap(([, data], index) => (pages.allPages[index] ? getArray(data, params).data : []))
    : [];

  const areFiltersApplied = Object.values(params.filters).some(v =>
    typeof v === "string" ? v !== "" : v.length !== 0
  );

  const handleParamsUpdate = (newParams: Partial<ItemQueryParams>) => {
    setPages({ allPages: [firstPage], pageNumber: 0 });
    setParams(params => ({ ...params, ...newParams }));
  };

  const handleResetPage = () => {
    setPages(pages => ({ ...pages, pageNumber: 0 }));
  };

  const handlePrevPage = () => {
    setPages(pages => ({ ...pages, pageNumber: pages.pageNumber - 1 }));
  };

  const handleNextPage = () => {
    const nextPageNumber = pages.pageNumber + 1;
    const nextPageExists = pages.allPages.some(page => page.page === nextPageNumber);

    if (!nextPageExists) {
      const lastItem = data[data.length - 1];
      const afterItemId = lastItem && queryData && idGetter(lastItem, queryData);
      setPages(pages => ({
        allPages: [
          ...pages.allPages,
          {
            page: nextPageNumber,
            afterItemId,
            fetching: false,
            fetched: false,
            nextPageAvailable: true,
          },
        ],
        pageNumber: nextPageNumber,
      }));
    } else if (pages.pageNumber !== nextPageNumber) {
      setPages(pages => ({ ...pages, pageNumber: nextPageNumber }));
    }
  };

  const handleLastPage = () => {
    const lastPage = pages.allPages[pages.allPages.length - 1];
    if (!lastPage.fetched) return;

    const nextLastPageNumber = lastPage.page + 1;

    if (lastPage.nextPageAvailable) {
      const lastItem = totalData[totalData.length - 1];
      const afterItemId = lastItem && queryData && idGetter(lastItem, queryData);
      setPages(pages => ({
        allPages: [
          ...pages.allPages,
          {
            page: nextLastPageNumber,
            afterItemId,
            fetching: false,
            fetched: false,
            nextPageAvailable: true,
          },
        ],
        pageNumber: nextLastPageNumber,
      }));
    } else if (pages.pageNumber !== lastPage.page) {
      setPages(pages => ({ ...pages, pageNumber: lastPage.page }));
    }
  };

  const handlePaginationUpdate = (
    newSort: string,
    newOrder: "asc" | "desc",
    newPage: number,
    newLimit: number
  ) => {
    if (params.sort !== newSort || params.order !== newOrder || params.limit !== newLimit) {
      handleParamsUpdate({ sort: newSort, order: newOrder, limit: newLimit });
    } else if (newPage > pages.pageNumber) {
      handleNextPage();
    } else if (newPage < pages.pageNumber) {
      handlePrevPage();
    }
  };

  const handleFiltersUpdate = useCallback((newSearchText: string, newFilters: Filters) => {
    handleParamsUpdate({ searchText: newSearchText, filters: newFilters });
  }, []);

  const updatePage = (pageNumber: number, newPage: Partial<ItemQueryPage>) => {
    setPages(pages => {
      const pageIndex = pages.allPages.findIndex(p => p.page === pageNumber);
      return {
        ...pages,
        allPages: [
          ...pages.allPages.slice(0, pageIndex),
          { ...pages.allPages[pageIndex], ...newPage },
          ...pages.allPages.slice(pageIndex + 1),
        ],
      };
    });
  };

  useEffect(() => {
    if (!enabled) return;

    if (isStale && !page.fetching) {
      updatePage(page.page, { fetching: true });
    } else if (isStale && page.fetched) {
      setPages({ allPages: [firstPage], pageNumber: 0 });
    } else if (!isStale) {
      updatePage(page.page, { fetching: false, fetched: true, nextPageAvailable });
      firstPageLoading.current = false;
    }
  }, [enabled, isStale, page.page, page.fetching, page.fetched, nextPageAvailable]);

  useEffect(() => {
    if (ignoreUrlParams) return;
    if (getParameterByName("page") !== String(pages.pageNumber)) {
      updateQueryParam("page", pages.pageNumber);
    }
    if (getParameterByName("sort") !== params.sort) {
      updateQueryParam("sort", params.sort);
    }
    if (getParameterByName("order") !== params.order) {
      updateQueryParam("order", params.order);
    }
    if (getParameterByName("limit") !== String(params.limit)) {
      updateQueryParam("limit", params.limit);
    }
    if (getParameterByName("search") !== params.searchText) {
      updateQueryParam("search", params.searchText);
    }
  }, [ignoreUrlParams, pages.pageNumber, params]);

  return {
    data,
    totalData,
    isLoading,
    isFetching,
    page: page.page,
    sort: params.sort,
    order: params.order,
    limit: params.limit,
    nextPageAvailable,
    onPaginationUpdate: handlePaginationUpdate,
    onParamsUpdate: handleParamsUpdate,
    onResetPage: handleResetPage,
    onPrevPage: handlePrevPage,
    onNextPage: handleNextPage,
    onLastPage: handleLastPage,
    onFiltersUpdate: handleFiltersUpdate,
    filters: params.filters,
    areFiltersApplied,
    searchText: params.searchText,
    firstPageLoading: firstPageLoading.current,
    ...usQueryFnResults,
  };
}

export function useInfiniteScrollForItemsWithQuery({
  onLastPage,
  getElement,
  enabled = true,
}: {
  onLastPage: () => void;
  getElement?: () => HTMLElement | undefined;
  enabled?: boolean;
}) {
  const [scrolled, setScrolled] = useState(0);

  useEffect(() => {
    if (!enabled) return;

    const element = getElement ? getElement() : document.documentElement;
    if (!element) return;

    const height = getElement ? getElement()?.getBoundingClientRect().height : window.innerHeight;
    if (!height) return;

    const distanceToBottom = element.scrollHeight - height - element.scrollTop;
    const isCloseToBottom = distanceToBottom / element.scrollHeight < 0.2;

    if (isCloseToBottom) {
      onLastPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, onLastPage, scrolled]);

  useEffect(() => {
    if (!enabled || getElement) return;

    function onScroll() {
      setScrolled(scrolled => scrolled + 1);
    }

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  const onScroll = useCallback(() => {
    setScrolled(scrolled => scrolled + 1);
  }, []);

  return {
    onScroll,
  };
}
