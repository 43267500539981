import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import uniq from "lodash/uniq";
import { makeStyles } from "tss-react/mui";

import { Styling, Theme } from "@cloudentity/acp-admin";

import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import FormSection from "../../../common/components/FormSection";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import { TRANSPARENT_HEX_VALUE } from "../../../common/components/nav/utils";
import { notifySuccess } from "../../../common/components/notifications/notificationService";
import ChipsGroupField from "../../../common/utils/forms/ChipsGroupField";
import ColorField from "../../../common/utils/forms/ColorField";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormFooter from "../../../common/utils/forms/FormFooter";
import SelectField from "../../../common/utils/forms/SelectField";
import TextField from "../../../common/utils/forms/TextField";
import { validators } from "../../../common/utils/forms/validation";
import { getDefaultStyling, getStyling } from "../../../common/utils/hooks/useStyling";
import { useCheckTenantPermissions } from "../../services/adminTenantsQuery";
import Preview from "./Preview";
import SetupLogo from "./SetupLogo";

const useStyles = makeStyles()(theme => ({
  paper: {
    padding: 32,
    borderRadius: 4,
    boxShadow: theme.custom.shadows?.surfaceResting,
    border: "none",
  },
  helperColors: {
    display: "flex",
    gap: 10,
    marginTop: 4,
  },
  colorBox: {
    cursor: "pointer",
    width: 20,
    height: 20,
    borderRadius: 4,
    border: "solid 1px lightgray",
  },
}));

const TRANSPARENT_BACKGROUND =
  "repeating-conic-gradient(#F0F0F0 0% 25%, transparent 0% 50%) 50% / 16px 16px";

function FormContainer({ children }) {
  const { classes } = useStyles();

  return <Paper className={classes.paper}>{children}</Paper>;
}

interface Props {
  themeId?: string;
  themes?: Theme[];
  styling: Styling;
  onUpdate: (styling?: Styling, themeId?: string) => Promise<any>;
  defaults?: Styling;
  mode: "workspace" | "tenant";
  visibleSteps?: string[];
  hasManagePermission: boolean;
}

export default function Appearance({
  themeId,
  themes,
  styling,
  onUpdate,
  defaults,
  mode,
  visibleSteps,
  hasManagePermission,
}: Props) {
  const { classes } = useStyles();

  const theme = useTheme();
  const defaultStyling = useMemo(() => getDefaultStyling(theme), [theme]);
  const stylingWithDefaults = useMemo(
    () => getStyling({ styling: defaults, defaults: defaultStyling }),
    [defaults, defaultStyling]
  );
  const initialStyling = useMemo(
    () => getStyling({ styling, defaults: stylingWithDefaults }),
    [styling, stylingWithDefaults]
  );

  const [progress, setProgress] = useState(false);

  const navigate = useNavigate();
  const checkTenantPermissionQuery = useCheckTenantPermissions();

  const [resetConfirmDialog, setResetConfirmDialog] = useState(false);

  const [logos, setLogos] = useState<
    { url: string; status: "pending" | "success" | "error" }[] | null
  >(null);
  const [palette, setPalette] = useState<string[]>([]);

  const initialData = useMemo(
    () => ({
      ...initialStyling,
      query: mode === "workspace" ? styling.logo_url : initialStyling.logo_url,
      logo_url: mode === "workspace" ? styling.logo_url : initialStyling.logo_url,
      font_url: mode === "workspace" ? styling.font_url : initialStyling.font_url,
      themeId,
    }),
    [styling, mode, themeId, initialStyling]
  );

  const form = useForm({
    id: "custom-branding-settings",
    initialValues: initialData,
    noManagePermission: !hasManagePermission,
    progress: progress,
  });

  function onSubmit() {
    setProgress(true);
    const nextData = omit(form.getValues(), ["query"]);

    const prevStyling = omit(initialData, ["query", "themeId"]);
    const nextStyling = omit(nextData, ["themeId"]);

    onUpdate(isEqual(nextStyling, prevStyling) ? undefined : nextStyling, nextData.themeId)
      .then(() => notifySuccess("Branding successfully updated"))
      .finally(() => setProgress(false));
  }

  function onRevertToDefaults() {
    const emptyStyling = getStyling();
    form.reset(initialData);

    setProgress(true);
    return onUpdate(emptyStyling)
      .then(() => notifySuccess("Branding successfully updated"))
      .finally(() => setProgress(false));
  }

  const themesAsOptions = useMemo(
    () => [
      { value: "", name: "Default" },
      ...(themes || []).map(t => ({ value: t.id || "", name: t.name || t.id || "" })),
    ],
    [themes]
  );

  const primaryPalette = useMemo(() => palette.filter(c => c !== TRANSPARENT_HEX_VALUE), [palette]);
  const headerPalette = palette;

  const currentData = form.watch();

  return (
    <Grid container spacing={3} data-testid="basic-branding-container">
      <Grid item xs={4}>
        <Form form={form}>
          <FormContainer>
            {mode === "workspace" && checkTenantPermissionQuery.data?.read_themes && (
              <>
                <FormSection
                  id="custom-branding-theme-title-label"
                  title="Theme"
                  style={{ color: theme.palette.secondary.dark }}
                >
                  <Button
                    variant="text"
                    size="small"
                    style={{ textTransform: "uppercase" }}
                    onClick={() => navigate("/appearance/themes")}
                  >
                    Manage
                  </Button>
                </FormSection>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectField
                      name="themeId"
                      label="Name"
                      options={themesAsOptions}
                      displayEmpty={true}
                      onChange={() => {}}
                      disabled={!hasManagePermission}
                      optional={false}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <FormSection
              id="custom-branding-settings-title-label"
              title="Branding"
              style={{ color: theme.palette.secondary.dark }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SetupLogo logos={logos} setLogos={setLogos} setPalette={setPalette} />
              </Grid>

              <Grid item xs={12}>
                <ColorField
                  name="colors.primary"
                  label="Primary color"
                  helperText={
                    <>
                      <span>Suggested colors</span>
                      <div className={classes.helperColors}>
                        {uniq(primaryPalette)
                          .filter(c => !!c)
                          .map(color => (
                            <div
                              key={color}
                              className={classes.colorBox}
                              style={{
                                background:
                                  color === TRANSPARENT_HEX_VALUE ? TRANSPARENT_BACKGROUND : color,
                              }}
                              onClick={() => form.setValue("colors.primary", color)}
                            />
                          ))}
                      </div>
                    </>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ColorField
                  name="colors.header"
                  label="Header color"
                  helperText={
                    <>
                      <span>Suggested colors</span>
                      <div className={classes.helperColors}>
                        {uniq(headerPalette)
                          .filter(c => !!c)
                          .map(color => (
                            <div
                              key={color}
                              className={classes.colorBox}
                              style={{
                                background:
                                  color === TRANSPARENT_HEX_VALUE ? TRANSPARENT_BACKGROUND : color,
                              }}
                              onClick={() => form.setValue("colors.header", color)}
                            />
                          ))}
                      </div>
                    </>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <ChipsGroupField
                  name="logo_size"
                  label="Logo size"
                  options={[
                    { label: "Small", value: "S" },
                    { label: "Medium", value: "M" },
                    { label: "Large", value: "L" },
                  ]}
                  onChange={value => form.setValue("logo_size", value)}
                  style={{ marginBottom: 16 }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="font_url"
                  label="Font URL"
                  helperText="Supported font formats: WOFF, WOFF2, TTF."
                  onChange={e => form.setValue("font_url", e.target.value)}
                  optional={false}
                  placeholder="Your font URL"
                  rules={{
                    validate: {
                      validURL: validators.validURL({ label: "Font URL" }),
                      validURLExtension: validators.validURLExtension({
                        type: "font",
                        extensions: ["woff", "woff2", "ttf"],
                      }),
                    },
                  }}
                  style={{ marginBottom: 16 }}
                />
              </Grid>
            </Grid>

            <FormFooter
              onSubmit={onSubmit}
              submitText="Publish"
              onCancel={
                hasManagePermission && !isEqual(styling, getStyling())
                  ? () => setResetConfirmDialog(true)
                  : undefined
              }
              cancelText="Reset"
              style={{ marginTop: 22 }}
            />

            {resetConfirmDialog && (
              <ConfirmationDialog
                title="Reset branding settings"
                content={
                  mode === "workspace"
                    ? "You're about to reset workspace branding parameters and use tenant default branding. This cannot be undone. Reset anyway?"
                    : "You're about to reset parameters to defaults. This cannot be undone. Reset anyway?"
                }
                confirmText="Reset and Publish"
                progress={progress}
                onCancel={() => setResetConfirmDialog(false)}
                onConfirm={() => {
                  onRevertToDefaults().then(() => setResetConfirmDialog(false));
                }}
              />
            )}
            <RouteLeavingGuard />
          </FormContainer>
        </Form>
      </Grid>

      <Grid item xs={8}>
        <Preview
          themeId={currentData.themeId ?? themeId}
          currentData={{
            ...currentData,
            logo_url:
              currentData.logo_url || (mode === "workspace" && initialStyling.logo_url) || "",
            font_url:
              currentData.font_url || (mode === "workspace" && initialStyling.font_url) || "",
          }}
          mode={mode}
          visibleSteps={visibleSteps}
        />
      </Grid>
    </Grid>
  );
}
