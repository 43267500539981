import React, { Suspense, useEffect } from "react";
import { useLocation } from "react-router";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { makeStyles } from "tss-react/mui";

import B2BOrganizationView from "../../b2b/B2BOrganizationView";
import B2BOrganizationsDirectory from "../../b2b/B2BOrganizationsDirectory";
import NoPermissionsEmptyState from "../../b2b/components/Users/NoPermissionsEmptyState";
import { useSilentAuthentication } from "../../common/auth/useSilentAuthentication";
import { TOP_BAR_HEIGHT } from "../../common/components/nav/utils";
import SelfServiceController from "../../common/components/selfServiceSettings/SelfServiceController";
import AccountExpiredView from "../../common/components/upgrade/AccountExpiredView";
import { getFromLocalStorage, setInLocalStorage } from "../../common/utils/localStorage.utils";
import { GlobalToursProvider } from "../GlobalTours/GlobalTours";
import B2BOrgWelcomeTour from "../GlobalTours/Tours/B2BOrgWelcomeTour";
import { useCheckTenantPermissions } from "../services/adminTenantsQuery";
import useOrganizationsSeqOrCursor from "./common/EnhancedTableAsync/useOrganizationsSeqOrCursor";
import PageContainer from "./common/PageContainer";
import { useOrganization } from "./common/useOrganization";
import TopBar from "./nav/TopBar";
import { recentlyUsedOrganizationsKey } from "./workspaces/WorkspaceSelector";

const useStyles = makeStyles()(theme => ({
  root: {
    "& *": {
      fontFamily: `"CustomTenantFont", ${theme.typography.fontFamily} !important`,
    },
  },
}));

interface Props {
  isLoading: boolean;
}

export default function AuthenticatedB2BAppBase({ isLoading }: Props) {
  const { classes } = useStyles();
  const [organizationId] = useOrganization();

  useSilentAuthentication();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAccountExpired = pathname === "/account-expired";

  const checkTenantPermissionsQuery = useCheckTenantPermissions({
    enabled: !isAccountExpired,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (checkTenantPermissionsQuery.error) {
      navigate("/error", { replace: true });
    }
  }, [checkTenantPermissionsQuery.error, navigate]);

  const data = useOrganizationsSeqOrCursor({
    enabled: !isAccountExpired,
  });

  useEffect(() => {
    if (pathname === "/" && !data.firstPageLoading && checkTenantPermissionsQuery.isSuccess) {
      if (checkTenantPermissionsQuery.data?.create_organization) {
        navigate(`/organizations`);
      } else if (data.totalData.length === 1) {
        navigate(`/organizations/${data.totalData.at(0)?.id}`);
      } else if (
        !checkTenantPermissionsQuery.data?.create_organization &&
        !checkTenantPermissionsQuery.data?.list_organizations &&
        !data.totalData.length
      ) {
        navigate(`/profile`);
      } else {
        navigate(`/organizations`);
      }
    }
  }, [checkTenantPermissionsQuery, data.firstPageLoading, pathname, data.totalData, navigate]);

  useEffect(() => {
    if (organizationId) {
      let recentOrganizations = [];
      try {
        const recentOrganizationsString = getFromLocalStorage(recentlyUsedOrganizationsKey);
        recentOrganizations = recentOrganizationsString
          ? JSON.parse(recentOrganizationsString)
          : [];
      } catch (e) {
        //
      }

      setInLocalStorage(
        recentlyUsedOrganizationsKey,
        JSON.stringify(
          [organizationId, ...recentOrganizations.filter(w => w !== organizationId)].slice(0, 5)
        )
      );
    }
  }, [organizationId]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PageContainer
        progress={(isLoading || checkTenantPermissionsQuery.isLoading) && !isAccountExpired}
      >
        <TopBar stripped />
        <Suspense fallback={<PageContainer progress />}>
          <GlobalToursProvider>
            <Routes>
              <Route path="/error" element={<NoPermissionsEmptyState />} />
              <Route path="/organizations" element={<B2BOrganizationsDirectory />} />
              <Route path="/administrators" element={<B2BOrganizationsDirectory />} />
              <Route path="/organizations/:id/*" element={<B2BOrganizationView />} />
              <Route
                path="/profile"
                element={
                  <div style={{ marginTop: TOP_BAR_HEIGHT }}>
                    <SelfServiceController
                      config={{
                        withDialog: false,
                        showMetadata: true,
                      }}
                      wrapperStyle={{ minHeight: `calc(100vh - ${TOP_BAR_HEIGHT}px)` }}
                    />
                  </div>
                }
              />
              <Route path="/account-expired" element={<AccountExpiredView />} />
              <Route path="/" element={null} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <B2BOrgWelcomeTour />
          </GlobalToursProvider>
        </Suspense>
      </PageContainer>
    </div>
  );
}
