import React, { useState } from "react";

import AppsIcon from "@mui/icons-material/Apps";
import WarningIcon from "@mui/icons-material/ReportProblem";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { Trash } from "react-feather";

import { AuthenticationContextAttribute } from "@cloudentity/acp-admin";

import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import { typeToApiString } from "../../services/adminIDPsApi";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { mapDataTypeToDisplayName } from "../authnContext/attrbutesDataTypeOptions";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import EnhancedTable from "../common/EnhancedTable";
import HeaderTile from "../common/HeaderTile";
import IdentitiesDetailsAttributesAdd from "./IdentitiesDetailsAttributesAdd";
import { getSourceMapping } from "./IdentitiesDetailsMappingsTableRow";
import { IdpUiModelType, splitAttrNameToSourceAndDisplayName } from "./identities.utils";

const transformAttribute = (attr: AuthenticationContextAttribute, index: number) => {
  const [source, variableName] = splitAttrNameToSourceAndDisplayName(attr.name ?? "") as [
    string,
    string,
  ];
  return {
    name: attr.name,
    variableName,
    displayName: attr.description,
    type: attr.type,
    source,
    index,
  };
};

const headCells = [
  { id: "variable_name", label: "Variable Name", align: "left" },
  { id: "description", label: "Display name", align: "left" },
  { id: "data_type", label: "Data Type", align: "left" },
  { id: "source", label: "Source", align: "left" },
  { id: "actions", label: "", align: "right" },
];

interface Props {
  method: IdpUiModelType;
  onUpdate: (provider: any) => Promise<void>;
  progress: boolean;
}

export default function IdentitiesDetailsAttributes({ method, onUpdate, progress }: Props) {
  const [addAttribute, setAddAttribute] = useState(false);
  const [removeAttributeConfirmDialog, setRemoveAttributeConfirmDialog] = useState<{
    index: number;
    name: string;
  } | null>(null);

  const handleRemoveAttribute = () => {
    const index = removeAttributeConfirmDialog?.index;
    if (index !== undefined) {
      onUpdate({
        ...method,
        attributes: [
          ...(method.attributes?.slice(0, index) ?? []),
          ...(method.attributes?.slice(index + 1) ?? []),
        ],
      }).finally(() => {
        setRemoveAttributeConfirmDialog(null);
      });
    }
  };

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(
    method?.authorization_server_id
  );

  if (!method) return null;

  return (
    <>
      <HeaderTile>
        <CardWithIconAndTitle
          icon={AppsIcon}
          title={method?.name || ""}
          subtitle={(method.method && typeToApiString[method.method]?.name) || method.method}
          id="identities-mappings-source-tile"
          noPadding
          noBorder
          style={{ flex: 1 }}
        />
        {checkWorkspacePermissionsQuery.data?.manage_idps && (
          <Button
            onClick={() => setAddAttribute(true)}
            color="primary"
            variant="outlined"
            id="identities-attributes-add-attribute-button"
          >
            + Add attribute
          </Button>
        )}
      </HeaderTile>

      <EnhancedTable
        id="idp-attributes-table"
        data={(method.attributes || []).map(transformAttribute)}
        headCells={headCells}
        createRow={(row, index, classes) => (
          <TableRow key={row?.name ?? "" + row.index} className={classes.rowWithHoverIcons}>
            <TableCell align="left" style={{ wordBreak: "break-word" }}>
              {row.variableName}
            </TableCell>
            <TableCell component="th" scope="row" align="left" style={{ wordBreak: "break-word" }}>
              {row.displayName}
            </TableCell>
            <TableCell component="th" scope="row" align="left">
              {mapDataTypeToDisplayName(row.type)}
              {row.type === "any" && (
                <Tooltip
                  title="This attribute doesn't have a type. You should remove it and add a typed attribute instead."
                  placement="top-end"
                >
                  <WarningIcon htmlColor="#ff9800" fontSize="small" style={{ marginLeft: 6 }} />
                </Tooltip>
              )}
            </TableCell>
            <TableCell align="left">
              {getSourceMapping(row.source, method.method as any, row.variableName)}
            </TableCell>
            <TableCell align="right" padding="none" style={{ padding: "0 24px" }}>
              {checkWorkspacePermissionsQuery.data?.manage_idps && (
                <IconButton
                  aria-label="delete"
                  onClick={e => {
                    e.stopPropagation();
                    setRemoveAttributeConfirmDialog({ index: row.index, name: row.variableName });
                  }}
                  className={classes.iconButton}
                  id={`identities-details-attributes-delete-button-${row.index}`}
                  size="large"
                >
                  <Trash size="14" />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        )}
      />

      {addAttribute && (
        <IdentitiesDetailsAttributesAdd
          existingAttrs={(method.attributes || []).map(a =>
            splitAttrNameToSourceAndDisplayName(a.name ?? "").join(".")
          )}
          methodType={method.method || ""}
          jitPoolId={method.jit?.provisioning?.pool_id}
          isJitEnabled={!!method.jit?.enabled}
          onCancel={() => setAddAttribute(false)}
          onCreate={data => {
            onUpdate({
              ...method,
              attributes: [...(method.attributes || []), data],
            }).then(() => {
              setAddAttribute(false);
            });
          }}
          progress={progress}
        />
      )}
      {removeAttributeConfirmDialog && (
        <ConfirmationDialog
          title="Delete Attribute"
          content={
            <>
              You're about to delete the <b>{removeAttributeConfirmDialog.name}</b> attribute. This
              cannot be undone. Delete anyway?
            </>
          }
          confirmText="Delete"
          onCancel={() => setRemoveAttributeConfirmDialog(null)}
          onConfirm={handleRemoveAttribute}
          progress={progress}
        />
      )}
    </>
  );
}
