import { QueryFunctionContext, useInfiniteQuery, useQuery } from "@tanstack/react-query";

import {
  OrganizationResponse,
  OrganizationsApiListOrganizationsRequest,
  OrganizationsApiListUserOrganizationsRequest,
  OrganizationsResponse,
  UserOrganizationsResponse,
} from "@cloudentity/acp-admin";

import adminOrganizationsApi from "./adminOrganizationsApi";

const GET_ORGANIZATION_QUERY = "GET_ORGANIZATION_QUERY";
const LIST_ORGANIZATIONS_QUERY = "LIST_ORGANIZATIONS_QUERY";
const LIST_ORGANIZATIONS_INFINITE_QUERY_KEY = [LIST_ORGANIZATIONS_QUERY, "all"];
const LIST_USER_ORGANIZATIONS_QUERY = "LIST_USER_ORGANIZATIONS_QUERY";
const LIST_USER_ORGANIZATIONS_INFINITE_QUERY_KEY = [LIST_USER_ORGANIZATIONS_QUERY, "all"];

export const getOrganizationQueryKey = wid => [GET_ORGANIZATION_QUERY, wid];

export const listOrganizationsQueryKey = (parentId?: string, limit?: number) => [
  LIST_ORGANIZATIONS_QUERY,
  ...(parentId !== undefined || limit !== undefined ? [parentId] : []),
  ...(limit !== undefined ? [limit] : []),
];

export const listUserOrganizationsQueryKey = (limit?: number) =>
  limit !== undefined ? [LIST_USER_ORGANIZATIONS_QUERY, limit] : [LIST_USER_ORGANIZATIONS_QUERY];

export const useGetOrganization = (wid, options?) =>
  useQuery<OrganizationResponse>({
    queryKey: getOrganizationQueryKey(wid),
    queryFn: async () => {
      const data = await adminOrganizationsApi.getOrganization({ wid });
      return data.data;
    },
    ...options,
  });

export const useListOrganizations = (
  { parentId, limit, ...params }: OrganizationsApiListOrganizationsRequest,
  options?: any
) =>
  useQuery<OrganizationsResponse>({
    queryKey: [...listOrganizationsQueryKey(parentId, limit), params],
    queryFn: async () => {
      const data = await adminOrganizationsApi.listOrganizations({ parentId, limit, ...params });
      return data.data;
    },
    ...options,
  });

export const useListUserOrganizations = (
  { limit, ...params }: OrganizationsApiListUserOrganizationsRequest,
  options?: any
) =>
  useQuery<UserOrganizationsResponse>({
    queryKey: [...listUserOrganizationsQueryKey(limit), params],
    queryFn: async () => {
      const data = await adminOrganizationsApi.listUserOrganizations({ limit, ...params });
      return data.data;
    },
    ...options,
  });

export const useListAllOrganizations = (
  params?: OrganizationsApiListOrganizationsRequest,
  options?: any
) => {
  const limit = 100;
  const query = useInfiniteQuery<OrganizationsResponse>({
    queryKey: [...LIST_ORGANIZATIONS_INFINITE_QUERY_KEY, params],
    queryFn: async ({
      pageParam,
    }: QueryFunctionContext<string[], { afterOrganizationId: string | undefined } | null>) => {
      const listOrganizationsRes = await adminOrganizationsApi.listOrganizations({
        template: params?.template,
        searchPhrase: params?.searchPhrase,
        parentId: params?.parentId,
        afterOrganizationId: pageParam?.afterOrganizationId,
        limit,
        order: "asc",
        sort: "name",
      });

      return listOrganizationsRes.data;
    },
    ...{
      ...options,
      getNextPageParam: lastPage => {
        if ((lastPage.organizations || []).length === limit) {
          return { afterOrganizationId: lastPage.organizations?.at(-1)?.id };
        }

        return null;
      },
    },
  });

  return query;
};

export const useListAllUserOrganizations = (
  { fetchAll }: { fetchAll?: boolean } = {},
  options?: any
) => {
  const limit = 100;
  const query = useInfiniteQuery<UserOrganizationsResponse>({
    queryKey: LIST_USER_ORGANIZATIONS_INFINITE_QUERY_KEY,
    queryFn: async ({
      pageParam,
    }: QueryFunctionContext<string[], { cursor: string | undefined } | null>) => {
      const listOrganizationsRes = await adminOrganizationsApi.listUserOrganizations({
        cursor: pageParam?.cursor,
        limit,
      });

      return listOrganizationsRes.data;
    },
    ...{
      ...options,
      getNextPageParam: lastPage => {
        if (lastPage.cursor) {
          return { cursor: lastPage.cursor };
        }

        return null;
      },
    },
  });

  if (fetchAll && query.hasNextPage && !query.isFetchingNextPage) {
    query.fetchNextPage();
  }

  return query;
};
