import React, { CSSProperties, ReactNode } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ withBottomBorder: boolean; mode: ModeType }>()(
  (theme, { withBottomBorder, mode }) => ({
    accordion: {
      border: "none",
      padding: "24px 24px 0",
      ...(mode === "warning" && {
        backgroundColor: "#FFF8F7",
      }),
    },
    accordionSummary: {
      borderBottom: withBottomBorder ? `1px solid ${theme.custom.greys.border}` : "none",
      margin: "0 0 24px",
      padding: 0,
    },
    accordionDetails: {
      padding: 0,
    },
    iconButton: {
      backgroundColor: mode === "default" ? "#F2F3F4" : "#FFE2E1",
      padding: 4,
      width: 30,
      height: 30,
    },
    label: {
      fontWeight: "bold",
    },
  })
);

type ModeType = "default" | "warning";

interface Props extends Omit<AccordionProps, "children"> {
  id: string;
  label: string | ReactNode;
  expanded?: boolean;
  onChange?: (_: any, expanded: boolean) => void;
  children: ReactNode;
  withBottomBorder?: boolean;
  mode?: ModeType;
  style?: CSSProperties;
}

const AccordionPaper = ({
  id,
  label,
  expanded,
  onChange,
  children,
  withBottomBorder = true,
  mode = "default",
  style = {},
}: Props) => {
  const { classes } = useStyles({ withBottomBorder, mode });

  return (
    <Accordion
      className={classes.accordion}
      disableGutters
      expanded={expanded}
      onChange={onChange}
      style={style}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.iconButton} />}
        className={classes.accordionSummary}
      >
        <Typography variant="textMD" id={`accordion-label-${id}`} className={classes.label}>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails id={`accordion-details-${id}`} className={classes.accordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionPaper;
