import { useMemo, useState } from "react";

import { PoolResponse } from "@cloudentity/acp-identity";

import {
  getFromLocalStorage,
  setInLocalStorage,
} from "../../../../../common/utils/localStorage.utils";
import { useListIDPsForIdentityPool } from "../../../../services/adminIDPsQuery";
import { useGetWorkspaces } from "../../../../services/adminServersQuery";

interface Props {
  pool?: PoolResponse;
  withEmptyValue?: boolean;
}

const poolWorkspaceIdKey = (poolId: string) => `pool-${poolId}-theme-workspace-id`;

export const setPoolWorkspaceId = (poolId: string, workspaceId: string) => {
  setInLocalStorage(poolWorkspaceIdKey(poolId), workspaceId);
};

export const getPoolWorkspaceId = (poolId: string) =>
  getFromLocalStorage(poolWorkspaceIdKey(poolId));

export const useIdentityPoolThemeSelector = ({ pool, withEmptyValue }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState<{ data: any } | null>(null);
  const connectedIDPsToThisPoolQuery = useListIDPsForIdentityPool(
    { ipID: pool?.id || "" },
    { enabled: !!pool?.id }
  );

  const connectedIDPsToThisPool = useMemo(
    () => connectedIDPsToThisPoolQuery.data?.idps || [],
    [connectedIDPsToThisPoolQuery.data?.idps]
  );

  const workspaceIds =
    connectedIDPsToThisPoolQuery.data?.idps
      ?.map(idp => idp.authorization_server_id)
      .filter((id): id is string => !!id) ?? [];

  const getWorkspacesQuery = useGetWorkspaces(workspaceIds);

  const poolConnectedToMoreThatOneIDP = connectedIDPsToThisPool.length > 1;

  const mustSelectThemeServerId =
    connectedIDPsToThisPool.length > 1 && !getPoolWorkspaceId(pool?.id || "");

  const options = useMemo(() => {
    return [
      ...(withEmptyValue ? [{ name: "", value: "" }] : []),
      ...connectedIDPsToThisPool.map(idp => ({
        name:
          (getWorkspacesQuery.data || []).find(server => server.id === idp.authorization_server_id)
            ?.name || idp.authorization_server_id!,
        value: idp.authorization_server_id!,
      })),
    ];
  }, [connectedIDPsToThisPool, getWorkspacesQuery.data, withEmptyValue]);

  const themeWorkspaceId =
    connectedIDPsToThisPool.length > 1
      ? getPoolWorkspaceId(pool?.id || "")
      : connectedIDPsToThisPool.length === 1
        ? connectedIDPsToThisPool[0].authorization_server_id
        : undefined;

  return {
    isLoading: connectedIDPsToThisPoolQuery.isLoading || getWorkspacesQuery.isLoading,
    isDialogOpen: isDialogOpen,
    closeDialog: () => setIsDialogOpen(null),
    openDialog: (data?) => setIsDialogOpen({ data }),
    options,
    mustSelectThemeServerId,
    poolConnectedToMoreThatOneIDP,
    themeWorkspaceId: themeWorkspaceId,
  };
};
