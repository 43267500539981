import React, { useMemo, useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import merge from "lodash/merge";
import { ArrowUpRight } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { SMSSettingsProviderEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import FormSection from "../../../../common/components/FormSection";
import IconTooltip from "../../../../common/components/IconTooltip";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import RouterLink from "../../../../common/components/RouterLink";
import {
  handleErrorWithNotify,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import DurationField from "../../../../common/utils/forms/DurationField";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import NumberField from "../../../../common/utils/forms/NumberField";
import SwitchField from "../../../../common/utils/forms/SwitchField";
import { validators } from "../../../../common/utils/forms/validation";
import { useFeature } from "../../../../common/utils/hooks/useFeature";
import adminMfaMethodsApi from "../../../services/adminMfaMethodsApi";
import {
  getMfaMethodQueryKey,
  getMfaMethodsQueryKey,
  useGetMfaMethods,
} from "../../../services/adminMfaMethodsQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import PageContent from "../../common/PageContent";
import PageHeader from "../../common/PageHeader";
import { setImplicitTTLValues } from "../../common/TextFieldTTLEditor";

const useStyles = makeStyles()(theme => ({
  paperRoot: {
    padding: 32,
  },
  paperTitle: {
    fontWeight: 500,
    lineHeight: "24px",
    color: theme.palette.secondary.light,
    paddingBottom: 8,
    marginBottom: 32,
  },
}));

function MfaSettings() {
  const { classes } = useStyles();
  const [progress, setProgress] = useState(false);

  const tid = getTenantId();
  const queryClient = useQueryClient();
  const getMfaMethodsQuery = useGetMfaMethods(tid);
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const smsMethod = getMfaMethodsQuery.data?.mfa_methods?.find(m => m.id === "sms");
  const isEmbeddedSMSProviderEnabled =
    smsMethod?.settings?.sms?.provider === SMSSettingsProviderEnum.Embedded;
  const isDisabledEmbeddedSMSProvider = useFeature("disable_embedded_sms_provider");

  const mfaMethods = useMemo(() => {
    if (getMfaMethodsQuery.data?.mfa_methods) {
      const email = getMfaMethodsQuery.data?.mfa_methods.find(m => m.id === "email");
      return {
        email: {
          ...email,
          settings: email?.settings && {
            ...email.settings,
            email: email.settings.email && {
              ...email.settings.email,
              otp: email.settings.email.otp && {
                ...email.settings.email.otp,
                expiration: setImplicitTTLValues(email.settings.email.otp.expiration),
              },
            },
          },
        },
        sms: {
          ...smsMethod,
          settings: smsMethod?.settings && {
            ...smsMethod.settings,
            sms: smsMethod.settings.sms && {
              ...smsMethod.settings.sms,
              otp: smsMethod.settings.sms.otp && {
                ...smsMethod.settings.sms.otp,
                expiration: setImplicitTTLValues(smsMethod.settings.sms.otp.expiration),
              },
            },
          },
          enabled:
            isEmbeddedSMSProviderEnabled && isDisabledEmbeddedSMSProvider
              ? false
              : smsMethod?.enabled,
        },
      };
    }
    return {
      email: { enabled: false },
      sms: { enabled: false },
    };
  }, [
    getMfaMethodsQuery.data?.mfa_methods,
    isDisabledEmbeddedSMSProvider,
    isEmbeddedSMSProviderEnabled,
    smsMethod,
  ]);

  const form = useForm({
    id: "mfa-settings",
    initialValues: mfaMethods,
    noManagePermission: !checkTenantPermissionsQuery.data?.manage_mfa_methods,
    progress: getMfaMethodsQuery.isLoading || progress,
  });

  async function updateMfaSettings(data) {
    setProgress(true);

    adminMfaMethodsApi
      .updateMfaMethod({ mfaID: "sms", mFAMethod: merge(mfaMethods?.sms, data.sms) })
      .then(() => queryClient.invalidateQueries({ queryKey: getMfaMethodQueryKey(tid, "sms") }))
      .then(() =>
        adminMfaMethodsApi
          .updateMfaMethod({
            mfaID: "email",
            mFAMethod: merge(mfaMethods?.email, data.email),
          })
          .then(() =>
            queryClient.invalidateQueries({ queryKey: getMfaMethodQueryKey(tid, "email") })
          )
      )
      .then(() => queryClient.invalidateQueries({ queryKey: getMfaMethodsQueryKey(tid) }))
      .then(() => notifySuccess("MFA settings saved successfully"))
      .catch(handleErrorWithNotify("Error occurred while trying to update mfa settings"))
      .finally(() => setProgress(false));
  }

  const isEmailVerificationEnabled = form.watch("email.enabled");
  const isPhoneVerificationEnabled = form.watch("sms.enabled");

  return (
    <Form form={form}>
      <PageHeader title="MFA Settings" />
      <PageContent progress={getMfaMethodsQuery.isLoading} fullWidth={false}>
        <Grid container>
          <Grid item xs={7}>
            <Paper className={classes.paperRoot}>
              <>
                <Typography className={classes.paperTitle}>
                  These Multi-Factor Authentications (MFA) settings apply to all workspaces that use
                  MFA Feature. To enable MFA in a workspace{" "}
                  <Link
                    href="https://docs.secureauth.com/ciam/en/multi-factor-authentication--mfa-.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    apply the Workspace MFA policy
                    <ArrowUpRight size={18} />
                  </Link>
                </Typography>

                <div style={{ display: "flex", alignItems: "center", marginBottom: 24 }}>
                  <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                    Verification Codes
                  </Typography>
                  <IconTooltip
                    id="verification-codes"
                    title="Users will receive emails via one of the selected methods depending on the configuration
                    of the MFA validator in the authorization policy and the availability of either or
                    both email and mobile in the user account."
                  />
                </div>
                {!isEmailVerificationEnabled && !isPhoneVerificationEnabled && (
                  <Alert
                    severity="warning"
                    title="No verification code delivery method selected"
                    style={{ marginBottom: 24 }}
                  >
                    <AlertTitle>No verification code delivery method selected</AlertTitle>
                    <Typography>
                      If MFA is used in any workspace the verification codes will not be delivered
                      to users.
                    </Typography>
                  </Alert>
                )}

                <FormSection title="Send to Email" id="send-to-email">
                  <SwitchField
                    name="email.enabled"
                    style={{
                      width: "inherit",
                      marginBottom: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                </FormSection>

                {isEmailVerificationEnabled && (
                  <>
                    <NumberField
                      id="mfa-email-delivery-provider-otp-length"
                      name="email.settings.email.otp.length"
                      title="Verification Code Length"
                      description="Choose between 4-10 characters for the single-use passcode length"
                      min={4}
                      max={10}
                    />

                    <DurationField
                      name="email.settings.email.otp.expiration"
                      label="Verification Code Lifetime"
                      description="Choose between 1-20 minutes for the single-use passcode expiration"
                      rules={{
                        validate: {
                          inRangeDuration: validators.inRangeDuration({
                            label: "Expiration",
                            min: "0h1m0s",
                            max: "0h20m0s",
                          }),
                        },
                      }}
                    />
                  </>
                )}

                <FormSection title="Send to Mobile" id="send-to-mobile">
                  <SwitchField
                    name="sms.enabled"
                    disabled={isEmbeddedSMSProviderEnabled && isDisabledEmbeddedSMSProvider}
                    disabledReason={
                      <span>
                        To enable this option go to{" "}
                        <RouterLink to="/message-providers/phone">Message Providers</RouterLink>{" "}
                        settings and enable phone configuration
                      </span>
                    }
                    style={{
                      width: "inherit",
                      marginBottom: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                </FormSection>

                {isPhoneVerificationEnabled &&
                  !(isEmbeddedSMSProviderEnabled && isDisabledEmbeddedSMSProvider) && (
                    <>
                      <NumberField
                        id="mfa-phone-delivery-provider-otp-length"
                        name="sms.settings.sms.otp.length"
                        title="Verification Code Length"
                        description="Choose between 4-10 characters for the single-use passcode length"
                        min={4}
                        max={10}
                      />

                      <DurationField
                        name="sms.settings.sms.otp.expiration"
                        label="Verification Code Lifetime"
                        description="Choose between 1-20 minutes for the single-use passcode expiration"
                        rules={{
                          validate: {
                            inRangeDuration: validators.inRangeDuration({
                              label: "Expiration",
                              min: "0h1m0s",
                              max: "0h20m0s",
                            }),
                          },
                        }}
                      />
                    </>
                  )}

                <FormFooter onSubmit={updateMfaSettings} />
              </>
            </Paper>
          </Grid>
        </Grid>
      </PageContent>
      <RouteLeavingGuard />
    </Form>
  );
}

export default MfaSettings;
