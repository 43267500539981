import React, { ReactNode } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ noBorder: boolean }>()((_, { noBorder }) => ({
  accordion: {
    border: "none",
    marginBottom: 32,
  },
  expanded: {
    marginBottom: 0,
  },
  accordionSummary: {
    borderBottom: noBorder ? "none" : "1px solid #F2F4FF",
    padding: 0,
  },
  accordionDetails: {
    padding: "16px 0",
  },
  iconButton: {
    backgroundColor: "#F2F3F4",
    padding: 4,
    borderRadius: "50%",
    width: 30,
    height: 30,
  },
}));

interface Props {
  id: string;
  label: string;
  expanded: boolean;
  onChange: ((_: any, expanded: boolean) => void) | undefined;
  children: ReactNode;
  noBorder?: boolean;
}

function IdentityPoolSettingsAccordion({
  id,
  label,
  expanded,
  onChange,
  children,
  noBorder = false,
}: Props) {
  const { classes } = useStyles({ noBorder });

  return (
    <Accordion
      className={classes.accordion}
      classes={{ expanded: classes.expanded }}
      disableGutters
      expanded={expanded}
      onChange={onChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.iconButton} />}
        className={classes.accordionSummary}
      >
        <Typography variant="h5" id={`accordion-label-${id}`}>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>{children}</AccordionDetails>
    </Accordion>
  );
}

export default IdentityPoolSettingsAccordion;
