import React from "react";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Shield } from "react-feather";

import { acpBaseURI } from "../../../../common/api/paths";
import RouterLink from "../../../../common/components/RouterLink";
import { useGetEnvironment } from "../../../services/adminEnvironmentQuery";
import InstructionWithGraphic from "../../common/InstructionWithGraphic";
import StepQuickstart from "../../common/StepQuickstart";

interface Props {
  id: string;
  prefix: string;
}

function PermissionQuickstart({ id, prefix }: Props) {
  const getEnvironmentQuery = useGetEnvironment();
  const grpcUrl = getEnvironmentQuery.data?.grpc_url;

  return (
    <Grid container>
      <Grid item xs={12}>
        <InstructionWithGraphic
          icon={Shield}
          title="Fine-grained relationship-based access control and permissions"
          text={
            <div>
              Learn how to integrate with SpiceDB.{" "}
              <Link href="https://docs.authzed.com/" target="_blank" rel="noopener noreferrer">
                <b>See documentation</b>
              </Link>
            </div>
          }
        />

        <StepQuickstart
          items={[
            {
              id: "1",
              title: "Create a token",
              description: (
                <>
                  Go to <RouterLink to={`/permissions/${id}/tokens`}>Tokens</RouterLink> and create
                  a new token. Save the API key.
                </>
              ),
            },
            {
              id: "2",
              title: "Apply a schema",
              description: (
                <>
                  Go to <RouterLink to={`/permissions/${id}/schema`}>Schema</RouterLink>, paste the
                  below code, and save the changes.
                </>
              ),
              code: [
                `definition ${prefix}user {}`,
                "",
                `definition ${prefix}post {`,
                `\u00A0\u00A0relation reader: ${prefix}user`,
                `\u00A0\u00A0relation writer: ${prefix}user`,
                " ",
                "\u00A0\u00A0permission read = reader + writer",
                "\u00A0\u00A0permission write = writer",
                "}",
              ],
            },
            {
              id: "3",
              title: "Store relationships",
              description: (
                <>
                  Grab the <code>revision</code> property from the response for the next step.
                </>
              ),
              code: [
                `curl -X POST "${acpBaseURI}/api/permissions/default/system/systems/${id}/relationships" \\`,
                `\u00A0\u00A0-H "X-Api-Key: $API_KEY" \\`,
                `\u00A0\u00A0-d '{ "updates": [{`,
                `\u00A0\u00A0\u00A0\u00A0"operation": "upsert",`,
                `\u00A0\u00A0\u00A0\u00A0"relation": "writer",`,
                `\u00A0\u00A0\u00A0\u00A0"resource_id": "1",`,
                `\u00A0\u00A0\u00A0\u00A0"resource_type": "${prefix}post",`,
                `\u00A0\u00A0\u00A0\u00A0"subject_id": "emilia",`,
                `\u00A0\u00A0\u00A0\u00A0"subject_type": "${prefix}user"`,
                `\u00A0\u00A0}] }'`,
              ],
            },
            {
              id: "4",
              title: "Check permissions",
              description: (
                <>
                  Provide the revision from <code>WriteRelationsResponse</code> to ensure data
                  consistency.
                </>
              ),
              code: [
                `curl -X POST "${acpBaseURI}/api/permissions/default/system/systems/${id}/check" \\`,
                `\u00A0\u00A0-H "X-Api-Key: $API_KEY" \\`,
                `\u00A0\u00A0-d '{`,
                `\u00A0\u00A0\u00A0\u00A0"revision": "$REVISION",`,
                `\u00A0\u00A0\u00A0\u00A0"permission": "read",`,
                `\u00A0\u00A0\u00A0\u00A0"resource_id": "1",`,
                `\u00A0\u00A0\u00A0\u00A0"resource_type": "${prefix}post",`,
                `\u00A0\u00A0\u00A0\u00A0"subject_id": "emilia",`,
                `\u00A0\u00A0\u00A0\u00A0"subject_type": "${prefix}user"`,
                `\u00A0\u00A0}'`,
              ],
            },
          ]}
        />
        <Typography variant="body2" style={{ marginLeft: 42 }}>
          More in our{" "}
          <Link
            href="https://docs.secureauth.com/ciam-apis/permissions.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Permissions APIs
          </Link>{" "}
          documentation.
        </Typography>

        <Alert severity="info" style={{ margin: "32px 4px 24px 42px" }}>
          <Typography variant="h5" style={{ marginBottom: 8 }}>
            Using GRPC
          </Typography>
          <Typography variant="textMD" id="grpc-info">
            For <b>SpiceDB SDK</b>, use the following address:{" "}
            <pre style={{ margin: "8px 0" }}>{`${grpcUrl}`}</pre>
          </Typography>
        </Alert>
      </Grid>
    </Grid>
  );
}

export default PermissionQuickstart;
