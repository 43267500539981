import debounce from "lodash/debounce";

import { notifyError } from "../components/notifications/notificationService";
import { basename } from "./paths";

const unauthorizedNotification = () => notifyError("You are not authorized.");

const debounceOptions = {
  maxWait: 500,
  leading: true,
  trailing: false,
};

const DEBOUNCE_WAIT = 1000;

const unauthorizedNotificationDebounced = debounce(
  unauthorizedNotification,
  DEBOUNCE_WAIT,
  debounceOptions
);

export const allowedMessagesFor401 = [
  "incorrect old password",
  "invalid totp code",
  "otp challenge verification failed",
  "totp code already used",
];

export const allowedMessagesFor403 = [
  "could not delete yourself",
  "could not deactivate yourself",
  "user not associated with any identity pool",
  "password already set",
  "license inactive",
];

export const unauthorizedRedirectAxios = err => {
  if (
    err?.response?.status === 403 &&
    err?.response?.data?.error === "license inactive" &&
    !window.location.href.endsWith("/account-expired")
  ) {
    window.location.href = `${basename}/account-expired`;
  }

  if (
    err?.response?.status === 403 &&
    !allowedMessagesFor403.includes(err?.response?.data?.error)
  ) {
    unauthorizedNotificationDebounced();
  }

  return Promise.reject(err);
};
