import { UserinfoResponse } from "@cloudentity/acp-oauth2";

import { TenantMetadataRegistration } from "../../../admin/components/getStartedV2/utils";
import { getTenantId } from "../../api/paths";
import { notifyError } from "../notifications/notificationService";

export const MARKETO_UPGRADE_REQUEST_FORM_ID = 1960;

export function getFormData(
  tenantMetadataRegistration: TenantMetadataRegistration | undefined,
  userInfoData: UserinfoResponse | undefined
) {
  const firstName = tenantMetadataRegistration?.first_name || userInfoData?.given_name || "";
  const lastName = tenantMetadataRegistration?.last_name || userInfoData?.given_name || "";
  const company = tenantMetadataRegistration?.company_name || "";
  const email = tenantMetadataRegistration?.email || userInfoData?.email || "";

  return {
    Email: email,
    FirstName: firstName,
    LastName: lastName,
    tenant: getTenantId(),
    Company: company,
  };
}

export function handleMarketoFormSubmit(
  formId: number,
  formData: any,
  setProgress: (progress: boolean) => void,
  onSubmited: () => void
) {
  const formEl = document.querySelector(`#mktoForm_${formId}`);
  if (formEl) {
    formEl.remove();
  }

  const newFormEl = document.createElement("form");
  newFormEl.setAttribute("id", `mktoForm_${formId}`);
  newFormEl.setAttribute("style", "display: none;");
  document.body.appendChild(newFormEl);

  const marketoId = document
    .querySelector("#marketo-forms")
    ?.getAttribute("data-marketo-instance-id");

  if (marketoId) {
    setProgress(true);

    (window as any).MktoForms2.loadForm("//pages.secureauth.com", marketoId, formId, form => {
      const timeout = setTimeout(() => {
        setProgress(false);
        notifyError("Couldn't send upgrade request");
      }, 10000);

      form.onSuccess(() => {
        clearTimeout(timeout);
        setProgress(false);
        onSubmited();
        return false;
      });

      form.addHiddenFields(formData);
      form.submit();
    });
  }
}
