import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { allowedMessagesFor401 } from "../common/api/unauthorized-redirect.interceptor";
import { logout } from "../common/auth/actions/actions";
import { getFromLocalStorage } from "../common/utils/localStorage.utils";
import SessionExpired from "./SessionExpired";
import SessionWillExpire from "./SessionWillExpire";
import adminAxiosInstance from "./adminAxiosInstance";

const getValueFromToken = (field, token) => {
  if (token) {
    let payload = token.split(".")[1];
    let base64 = payload.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      global.window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    let jsonPayloadParsed = JSON.parse(jsonPayload);

    return jsonPayloadParsed[field];
  }

  return undefined;
};

const getTimeToExpiration = exp => {
  const current = new Date().getTime() / 1000;
  return exp - current;
};

const isJWT = token => {
  return token && (token.match(/\./g) || []).length === 2;
};

export const AxiosErrorHandler = ({ children }) => {
  const [isAboutToExpire, setIsAboutToExpire] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [hideIsAboutToExpire, setHideIsAboutToExpire] = useState(false);
  const [timeLeftInSeconds, setTimeLeftInSeconds] = useState<undefined | number>(undefined);
  const [hasSilentAuthFailed, setHasSilentAuthFailed] = useState(false);
  const [timeToExpireInterval, setTimeToExpireInterval] = useState<NodeJS.Timeout>();

  const navigate = useNavigate();

  useEffect(() => {
    const listener = e => {
      if (e.origin !== window.location.origin) {
        return;
      }

      if (e.data === "silentAuthFailure") {
        setHasSilentAuthFailed(true);
      }
    };

    window.addEventListener("message", listener);

    return () => window.removeEventListener("message", listener);
  }, [setHasSilentAuthFailed]);

  useEffect(() => {
    const interval = setInterval(() => {
      const accessToken = getFromLocalStorage("access_token");
      if (accessToken && isJWT(accessToken)) {
        let expiresAtTime = getValueFromToken("exp", accessToken);
        let timeToExpiration = getTimeToExpiration(expiresAtTime);

        setTimeLeftInSeconds(Math.round(timeToExpiration));
        if (timeToExpiration < 0) {
          clearInterval(interval);
        }
      } else {
        clearInterval(interval);
      }
    }, 1000);

    setTimeToExpireInterval(interval);

    return () => clearInterval(interval);
  }, [navigate, setTimeToExpireInterval]);

  useEffect(() => {
    if (timeLeftInSeconds !== undefined) {
      if (timeLeftInSeconds <= 0) {
        setIsAboutToExpire(false);
        if (hasSilentAuthFailed) {
          setIsExpired(true);
        } else {
          logout();
        }
      } else if (timeLeftInSeconds < 60) {
        setIsAboutToExpire(true);
      }
    }
  }, [timeLeftInSeconds, hasSilentAuthFailed]);

  useEffect(() => {
    const responseInterceptor = adminAxiosInstance.interceptors.response.use(
      response => response,
      async err => {
        if (
          err?.response?.status === 401 &&
          !allowedMessagesFor401.includes(err?.response?.data?.error) &&
          !(err?.response?.headers?.["www-authenticate"] ?? "").includes(
            "insufficient_user_authentication"
          )
        ) {
          if (hasSilentAuthFailed) {
            setIsExpired(true);
            clearInterval(timeToExpireInterval);
          } else {
            logout();
          }
        }

        return Promise.reject(err);
      }
    );

    return () => adminAxiosInstance.interceptors.response.eject(responseInterceptor);
  }, [navigate, hasSilentAuthFailed, timeToExpireInterval]);

  return (
    <>
      {isExpired && <SessionExpired />}
      {isAboutToExpire && !isExpired && hasSilentAuthFailed && !hideIsAboutToExpire && (
        <SessionWillExpire
          timeLeftInSeconds={timeLeftInSeconds}
          onCancel={() => setHideIsAboutToExpire(true)}
        />
      )}
      {!isExpired && children}
    </>
  );
};
