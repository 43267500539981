import React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import sessionExpired from "../assets/images/icons/sessionExpired/session-expired.svg";
import background from "../assets/images/icons/upgrade/background-expired.jpg";
import { pathPrefix, portalMode, portalType } from "../common/api/paths";
import { logout } from "../common/auth/actions/actions";
import ConfirmationDialog from "../common/components/ConfirmationDialog";
import { setInLocalStorage } from "../common/utils/localStorage.utils";

const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  img: {
    width: "100%",
  },
  content: {
    marginTop: 24,
    "& > p": {
      marginTop: 16,
    },
  },
}));

export default function SessionExpired() {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <ConfirmationDialog
        id="session-expired-modal"
        onlyExtraActions
        extraActions={
          <Button
            variant="contained"
            onClick={() => {
              if (portalType === "admin" && portalMode === "portal") {
                setInLocalStorage("last_path", window.location.pathname.replace(pathPrefix, ""));
              }
              logout(portalType);
            }}
          >
            Log In
          </Button>
        }
        mode="info"
        content={
          <>
            <img src={sessionExpired} alt="session expired" className={classes.img} />

            <div className={classes.content}>
              <>
                <Typography variant="h3">Your session has expired</Typography>
                <Typography>
                  It looks like your session has timed out. For your security and to keep your data
                  safe, we’ve automatically logged you out.
                </Typography>
                <Typography>Please log back in to continue where you left off.</Typography>
              </>
            </div>
          </>
        }
      />
    </div>
  );
}
