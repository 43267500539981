import React from "react";
import { useMatch } from "react-router";
import { Link as RouterLink } from "react-router-dom";

// eslint-disable-next-line no-restricted-imports
import { SvgIconComponent } from "@mui/icons-material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Icon } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { TenantPermissionsResponse, WorkspacePermissionsResponse } from "@cloudentity/acp-admin";

import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { useCheckTenantPermissions } from "../../services/adminTenantsQuery";
import { useWorkspace } from "../common/useWorkspace";

interface Props {
  id: string;
  text: string;
  to: string;
  icon?: Icon | SvgIconComponent;
  img?: { normal: any; active: any };
  isActive?: boolean;
  ignoreMatchAndActiveToAllowNavigate?: boolean;
  hasTenantPermission?: keyof TenantPermissionsResponse;
  hasWorkspacePermission?: keyof WorkspacePermissionsResponse;
  onClick?: () => void;
}

export default function NavItem({
  id,
  text,
  to,
  icon: Icon,
  img,
  isActive,
  ignoreMatchAndActiveToAllowNavigate,
  hasTenantPermission,
  hasWorkspacePermission,
  onClick,
}: Props) {
  const { classes } = useStyles();
  const match = useMatch({ path: to, end: false });
  const matchExact = useMatch(to);

  const [workspace] = useWorkspace();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  if (hasTenantPermission && !checkTenantPermissionsQuery.data?.[hasTenantPermission]) {
    return null;
  }

  if (hasWorkspacePermission && !checkWorkspacePermissionsQuery.data?.[hasWorkspacePermission]) {
    return null;
  }

  const forbiddenClick = !ignoreMatchAndActiveToAllowNavigate && (matchExact || isActive);

  return (
    <ListItemButton
      id={id}
      data-to={to}
      data-nav-section-id={id}
      key={id}
      className={match || isActive ? classes.selected : undefined}
      classes={{ root: classes.listItemRoot }}
      {...{
        LinkComponent: RouterLink,
        to,
        onClick(e: React.MouseEvent) {
          if (forbiddenClick) {
            e.preventDefault();
            e.stopPropagation();
          } else {
            onClick?.();
          }
        },
      }}
    >
      <ListItemIcon
        color={match ? "red" : "blue"}
        classes={{ root: match || isActive ? classes.active : undefined }}
      >
        {Icon && <Icon />}
        {img && <img src={match || isActive ? img.active : img.normal} alt="item icon" />}
      </ListItemIcon>
      <ListItemText
        style={{ whiteSpace: "nowrap" }}
        classes={{ root: match || isActive ? classes.active : undefined }}
        disableTypography
        primary={<Typography variant="body2">{text}</Typography>}
      />
    </ListItemButton>
  );
}

const useStyles = makeStyles()(theme => ({
  selected: {
    background: `linear-gradient(270deg, ${theme.palette.primary.light} 0%, #FFFFFF 100%)`,
    boxShadow: `-4px 0px 0px 0px ${theme.palette.primary.main} inset`,
  },
  disabled: {
    opacity: "1 !important",
  },
  active: {
    color: theme.palette.primary.main,
  },
  listItemRoot: {
    paddingTop: 12,
    paddingBottom: 12,
    overflow: "hidden",
    maxHeight: 56,
  },
}));
