import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  OrganizationResponse,
  WorkspaceResponse,
  WorkspaceResponseTypeEnum,
} from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import RemoveConfirmationDialog from "../../../common/components/RemoveConfirmationDialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { useListWorkspacePools } from "../../services/adminIdentityPoolsQuery";
import adminServersApi from "../../services/adminServersApi";
import {
  listServerBindingsQueryKey,
  useListServerBindings,
} from "../../services/adminServersQuery";
import { useCheckTenantPermissions } from "../../services/adminTenantsQuery";
import { useOrganization } from "../common/useOrganization";
import { useWorkspace } from "../common/useWorkspace";
import BindThemToServerDialog from "./BindThemToServerDialog";
import TenantView from "./TenantView";
import UnbindThemeDialog from "./UnbindThemeDialog";
import WorkspaceDirectoryCreate from "./WorkspaceDirectoryCreate";
import WorkspaceDirectoryCreateDeveloperPortal from "./WorkspaceDirectoryCreateDeveloperPortal";
import { handleDeleteServer, WorkspaceTab } from "./workspace-directory.common";

type Mode = {
  view: "tenantView" | "create" | "createDeveloperPortal";
  data: any;
};

export default function WorkspaceDirectory({ path }: { path: string }) {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { goTo: string; returnTo: string | undefined } | undefined;
  const [workspace] = useWorkspace();
  const [organizationId] = useOrganization();

  const [mode, setMode] = useState<Mode>({
    view: state?.goTo === "create" ? "create" : "tenantView",
    data: {},
  });
  const [connectProgress, setConnectProgress] = useState("");
  const [deleteProgress, setDeleteProgress] = useState("");
  const [connectDialog, setConnectDialog] = useState<{ aid: string; rid: string } | null>(null);
  const [deleteDialog, setDeleteDialog] = useState<{
    id: string;
    name: string;
    type: WorkspaceResponseTypeEnum | undefined;
  }>();
  const [unbindDialog, setUnbindDialog] = useState<any | null>(null);

  const [bindThemeDialog, setBindThemeDialog] = useState<{
    server: WorkspaceResponse | OrganizationResponse;
  } | null>(null);
  const [unbindThemeDialog, setUnbindThemeDialog] = useState<{
    server: WorkspaceResponse | OrganizationResponse;
  } | null>(null);

  const queryClient = useQueryClient();
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const listWorkspacePools = useListWorkspacePools(
    { wid: deleteDialog?.id ?? "" },
    { enabled: deleteDialog?.type === WorkspaceResponseTypeEnum.Organization }
  );
  const workspacePools = listWorkspacePools.data?.pools ?? [];

  const removeServerFn = (id: string) => adminServersApi.removeServer({ wid: id });
  const removeServerMutation = useMutation({
    mutationFn: removeServerFn,
    ...{
      retry: 10,
    },
  });

  const handleShowSettings = (id: string) => {
    navigate(`/${id}/workspaces/oauth/general`);
  };

  const handleSelectServer = (id: string) => {
    navigate(`/${id}/overview`);
  };

  const handleConnect = ({ aid, rid }: { aid: string; rid: string }) => {
    if (!aid || !rid) return;
    setConnectProgress(aid);
    return adminServersApi
      .bindServer({ wid: aid, rid })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listServerBindingsQueryKey(getTenantId()) })
      )
      .then(() => notifySuccess("Developer portal successfully connected to workspace"))
      .then(() => setConnectDialog(null))
      .catch(
        notifyErrorOrDefaultTo(
          "Error occurred when trying to connect developer portal to workspace"
        )
      )
      .finally(() => setConnectProgress(""));
  };

  const handleUnbind = ({ aid, rid }: { aid: string; rid: string }) => {
    setConnectProgress(aid);
    return adminServersApi
      .unbindServer({ wid: aid, rid })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listServerBindingsQueryKey(getTenantId()) })
      )
      .then(() => notifySuccess("Developer portal successfully disconnected from workspace"))
      .catch(
        notifyErrorOrDefaultTo(
          "Error occurred when trying to disconnect developer portal from workspace"
        )
      )
      .finally(() => {
        setConnectProgress("");
        setUnbindDialog(null);
      });
  };

  const bindingsQuery = useListServerBindings(getTenantId(), {
    enabled: checkTenantPermissionsQuery.data?.read_server_bindings,
  });

  const bindingsByTenant = (bindingsQuery.data?.bindings || []).filter(
    b => b.tenant_id === getTenantId()
  );

  useEffect(() => {
    // clear history state with goTo so after page reload it's not longer available
    window.history.replaceState({}, "");
  }, []);

  return (
    <>
      {mode.view === "create" && (
        <WorkspaceDirectoryCreate
          onClose={(createdWorkspace: string | undefined) => {
            const isLaunchedFromWorkspace = state?.returnTo;
            if (isLaunchedFromWorkspace) {
              if (createdWorkspace) {
                handleSelectServer(createdWorkspace);
              } else {
                handleSelectServer(isLaunchedFromWorkspace);
              }
            } else {
              setMode({ view: "tenantView", data: {} });
            }
          }}
        />
      )}
      {mode.view === "createDeveloperPortal" && (
        <WorkspaceDirectoryCreateDeveloperPortal
          workspace={mode.data.aid}
          onCancel={() => {
            setMode({ view: "tenantView", data: {} });
          }}
          onFinish={() => {
            setMode({
              view: "tenantView",
              data: { initWorkspaceTab: WorkspaceTab.DEVELOPER_PORTALS_SEQ },
            });
          }}
        />
      )}
      {mode.view === "tenantView" && (
        <TenantView
          path={path}
          progress={bindingsQuery.isLoading}
          connectProgress={connectProgress}
          deleteProgress={deleteProgress}
          serversBindings={bindingsByTenant}
          initWorkspaceTab={mode.data.initWorkspaceTab}
          onCreateClick={() => setMode({ view: "create", data: {} })}
          onDelete={(id, name, type) => setDeleteDialog({ id, name, type })}
          onShowSettings={handleShowSettings}
          onSelectServer={handleSelectServer}
          onConnectToExisting={(aid: string, rid: string) => setConnectDialog({ aid, rid })}
          onCreateDeveloperPortal={({ aid }) =>
            setMode({ view: "createDeveloperPortal", data: { aid } })
          }
          onUnbind={data => setUnbindDialog({ data })}
          onBindToTheme={server => setBindThemeDialog({ server })}
          onUnbindTheme={server => setUnbindThemeDialog({ server })}
        />
      )}
      {connectDialog && (
        <ConfirmationDialog
          title="Connect developer portal"
          content="Are you sure you want to connect?"
          confirmText="Yes"
          onCancel={() => setConnectDialog(null)}
          onConfirm={() => handleConnect(connectDialog)}
          progress={!!connectProgress}
          mode="info"
        />
      )}
      {deleteDialog && (
        <RemoveConfirmationDialog
          title="Delete Workspace"
          type="workspace"
          name={deleteDialog.name}
          onCancel={() => setDeleteDialog(undefined)}
          onConfirm={() =>
            handleDeleteServer({
              server: deleteDialog,
              workspacePools,
              setProgress: setDeleteProgress,
              handleClose: () => setDeleteDialog(undefined),
              queryClient,
              removeServerMutation,
              currentWorkspace: workspace,
              currentOrganization: organizationId,
            })
          }
          progress={!!deleteProgress}
        />
      )}
      {unbindDialog && (
        <ConfirmationDialog
          title="Unbind developer portal"
          content="Are you sure you want to unbind the portal?"
          confirmText="Yes"
          onCancel={() => setUnbindDialog(null)}
          onConfirm={() => handleUnbind({ aid: unbindDialog.data.aid, rid: unbindDialog.data.rid })}
          progress={!!connectProgress}
        />
      )}
      {bindThemeDialog && (
        <BindThemToServerDialog
          server={bindThemeDialog.server}
          onClose={() => setBindThemeDialog(null)}
        />
      )}
      {unbindThemeDialog && (
        <UnbindThemeDialog
          server={unbindThemeDialog.server}
          onCancel={() => setUnbindThemeDialog(null)}
        />
      )}
    </>
  );
}
