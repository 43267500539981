import React, { useState } from "react";

import { makeStyles } from "tss-react/mui";

import { getTenantId } from "../../../common/api/paths";
import { useListServerCustomApps } from "../../services/adminCustomAppsQuery";
import InfoIconWithLink from "../common/InfoIconWithLink";
import PageContainer from "../common/PageContainer";
import PageContent from "../common/PageContent";
import PageHeader from "../common/PageHeader";
import { useWorkspace } from "../common/useWorkspace";
import CustomApplicationsTable from "./CustomApplicationsTable";
import CustomApplicationsTableToolbar from "./CustomApplicationsTableToolbar";

const useStyles = makeStyles()(() => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    "& > span": {
      marginRight: 8,
    },
  },
}));

export default function CustomApplications() {
  const { classes } = useStyles();
  const [searchText, setSearchText] = useState("");

  const [workspace] = useWorkspace();
  const customAppsQuery = useListServerCustomApps(getTenantId(), workspace);
  const customAppsFiltered = (customAppsQuery.data?.custom_apps || []).filter(
    a => !searchText || (a?.name || "").toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
  );

  return (
    <PageContainer>
      <PageHeader
        title={
          <div className={classes.flexContainer}>
            <span>Custom Applications</span>
            <InfoIconWithLink link="https://docs.secureauth.com/cloudentity/en/standardizing-user-attributes-coming-from-different-idps-into-common-authentication-context.html#enriching-authentication-context" />
          </div>
        }
      />
      <PageContent>
        <CustomApplicationsTableToolbar
          search={searchText}
          onUpdateSearch={search => setSearchText(search)}
        />
        <CustomApplicationsTable customApps={customAppsFiltered} />
      </PageContent>
    </PageContainer>
  );
}
