import React from "react";

import Editor, { loader } from "@monaco-editor/react";
import FormHelperText from "@mui/material/FormHelperText";
import { editor } from "monaco-editor";
import { makeStyles } from "tss-react/mui";

import FormInputLabel from "../../../../common/components/FormInputLabel";

import IMarker = editor.IMarker;

loader.config({
  paths: {
    vs: `${document.baseURI}assets/monaco/min/vs`,
  },
});

const useStyles = makeStyles()(theme => ({
  editor: {
    ".margin": {
      backgroundColor: "#F2F3F4 !important",
      ".line-numbers": {
        fontWeight: 400,
        color: theme.palette.secondary.light,
      },
    },
  },
  withError: {
    border: "1px solid #BD271E",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.04), 0px 3px 2px rgba(0, 0, 0, 0.04)",
    borderRadius: 2,
  },
}));

interface Props {
  id: string;
  value: string;
  path: string;
  errors?: IMarker[];
  onChange: (value: string, resetPayload?: boolean) => void;
  onValidate: (markers: IMarker[]) => void;
  disabled?: boolean;
}

export default function SchemaEditor({
  id,
  value,
  path,
  errors = [],
  onChange,
  onValidate,
  disabled = false,
}: Props) {
  const { classes, cx } = useStyles();
  return (
    <div style={{ position: "relative" }}>
      <Editor
        defaultLanguage="json"
        options={{
          minimap: {
            enabled: false,
          },
          overviewRulerBorder: false,
          lineDecorationsWidth: 0,
          scrollBeyondLastLine: false,
          wordBasedSuggestions: "off",
          readOnly: disabled,
        }}
        height="calc(100vh - 460px)"
        defaultValue={value}
        path={path}
        value={value}
        onChange={v => onChange(v ?? "")}
        wrapperProps={{
          id: `${id}-editor`,
        }}
        onValidate={onValidate}
        className={cx(classes.editor)}
      />
      {errors?.length > 0 && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            backgroundColor: "#fff",
            border: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <FormInputLabel
            id="console"
            label="Console"
            style={{ textTransform: "uppercase", padding: "4px 16px", marginBottom: 0 }}
          />
          <div style={{ padding: "12px 16px 24px 16px", backgroundColor: "rgb(255 249 247)" }}>
            <FormHelperText style={{ minHeight: 20 }} error={errors?.length > 0}>
              {errors &&
                errors.map((e, i) => (
                  <span key={i} style={{ display: "block" }}>
                    <span style={{ marginRight: 8 }}>ERR!</span>Line {e.startLineNumber}:{" "}
                    {e.message}
                  </span>
                ))}
            </FormHelperText>
          </div>
        </div>
      )}
    </div>
  );
}
