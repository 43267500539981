import React, { Dispatch, SetStateAction, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import uniq from "lodash/uniq";
import { makeStyles } from "tss-react/mui";

import { ServerResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-admin";
import { PoolResponse, PoolResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";
import { IDP } from "@cloudentity/acp-root";

import { getTenantId } from "../../../../../common/api/paths";
import { TOP_BAR_HEIGHT } from "../../../../../common/components/nav/utils";
import { notifyErrorOrDefaultTo } from "../../../../../common/components/notifications/notificationService";
import { BRAND_NAME } from "../../../../../common/theme/theme";
import SignInAndUserManagementUseCaseTour from "../../../../GlobalTours/Tours/SignInAndUserManagementUseCaseTour";
import adminIDPsApi from "../../../../services/adminIDPsApi";
import {
  getIDPQueryKey,
  listIDPsForIdentityPoolQueryKey,
  listIDPsQueryKey,
} from "../../../../services/adminIDPsQuery";
import identityPoolsApi from "../../../../services/adminIdentityPoolsApi";
import {
  getPoolQueryKey,
  listPoolsQueryKey,
  useGetPool,
} from "../../../../services/adminIdentityPoolsQuery";
import { useCheckWorkspacePermissions } from "../../../../services/adminPermissionsQuery";
import { useGetAuthorizationServer } from "../../../../services/adminServersQuery";
import { BUILD_IN_ADMIN_POOL_ID } from "../../../workspaceDirectory/administrator/AdministratorManagement";
import {
  notAllowedAuthenticationMechanismsByServer,
  AuthenticationMechanismsNotSupportedWarning,
} from "../../../workspaceDirectory/identityPools/identityPool/IdentityPool";
import IdentityPoolForDialog from "../../../workspaceDirectory/identityPools/identityPool/IdentityPoolForDialog";
import { poolAuthMechanismMapper } from "../../../workspaceDirectory/identityPools/identityPoolCreate/IdentityPoolsAuthnSelect";
import { addDefaultPreferredAuthMechanism } from "../../../workspaceDirectory/identityPools/utils";
import { embeddedIdps, identitiesDetailsPath, providers } from "../../identities.utils";
import IdentitiesListModeController from "../IdentitiesListModeController";
import ConfigureButton from "./ConfigureButton";
import CreateIdp from "./CreateIdp";
import IdentitiesListSimplePreview from "./IdentitiesListSimplePreview";
import IdentitiesListSimpleSwitchCard from "./IdentitiesListSimpleSwitchCard";
import { IdpsViewModeType } from "./utils";

const useStyles = makeStyles()(theme => ({
  container: {
    height: "100%",
    backgroundColor: theme.palette.background.default,
  },
  leftContainer: {
    height: "100%",
  },
  rightContainer: {
    position: "sticky",
    top: TOP_BAR_HEIGHT,
    overflow: "hidden",
    flex: 1,
  },
  paper: {
    height: "100%",
    overflowY: "auto",
    borderRadius: 0,
    padding: "32px 36px 16px",
    backgroundColor: theme.palette.background.default,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  name: {
    display: "flex",
    alignItems: "center",
  },
  empty: {
    color: "gray",
  },
}));

const methodsOrder = [
  "google_embedded",
  "github_embedded",
  "google",
  "github",
  "azure",
  "azureb2c",
  "okta",
  "cognito",
  "saml",
  "oidc",
].reverse();

const sortByMethod = (a: IDP, b: IDP) =>
  methodsOrder.indexOf(b.method ?? "") - methodsOrder.indexOf(a.method ?? "");

interface Props {
  identities: IDP[];
  mode: IdpsViewModeType;
  workspace: string;
  setMode: Dispatch<SetStateAction<IdpsViewModeType>> | undefined;
  tenantSignInAndSSO?: boolean;
  managePoolsInDialog?: boolean;
  hideExtensions?: boolean;
  isB2BPortal?: boolean;
  idpsLoading: boolean;
}

export const handlePoolMechanismChange = (
  queryClient: QueryClient,
  pool: PoolResponse | undefined,
  cardId: string,
  option: PoolResponseAuthenticationMechanismsEnum,
  checked: boolean,
  setCardProgress: (cardId: string) => void
) => {
  if (!pool) return;

  const tenantId = getTenantId();
  setCardProgress(cardId);

  const currentMechanisms = checked
    ? [...(pool?.authentication_mechanisms ?? []), option]
    : pool?.authentication_mechanisms?.filter(mechanism => mechanism !== option);

  const rawPayload = { ...pool, authentication_mechanisms: uniq(currentMechanisms) };
  const payload = addDefaultPreferredAuthMechanism(rawPayload);

  identityPoolsApi
    .updatePool({ ipID: pool.id ?? "", pool: payload })
    .then(res => queryClient.setQueryData(getPoolQueryKey(tenantId, pool.id ?? ""), res.data))
    .then(() => queryClient.invalidateQueries({ queryKey: listPoolsQueryKey(tenantId) }))
    .catch(notifyErrorOrDefaultTo("Error occurred while trying to update pool"))
    .finally(() => setCardProgress(""));
};

export const handleExistingIdpCheckboxChange = (
  queryClient: QueryClient,
  workspace: string,
  cardId: string,
  idp: IDP | undefined,
  checked: boolean,
  setCardProgress: (cardId: string) => void
) => {
  if (!idp) return;

  const tenantId = getTenantId();
  setCardProgress(cardId);

  adminIDPsApi
    .getIDP({ wid: workspace, type: idp.method, iid: idp.id })
    .then(res =>
      adminIDPsApi.updateIDP({
        aid: workspace,
        type: res.data.method,
        body: { ...res.data, disabled: !checked },
        iid: res.data.id,
      })
    )
    .then(() => queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(tenantId, workspace) }))
    .then(() =>
      queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(tenantId) })
    )
    .then(() =>
      queryClient.invalidateQueries({ queryKey: getIDPQueryKey(idp.id ?? "", workspace) })
    )
    .catch(notifyErrorOrDefaultTo("Error occurred while trying to update IDP"))
    .finally(() => setCardProgress(""));
};

export default function IdentitiesListSimple({
  identities,
  workspace,
  mode,
  setMode,
  tenantSignInAndSSO,
  managePoolsInDialog,
  hideExtensions,
  isB2BPortal,
  idpsLoading,
}: Props) {
  const { classes } = useStyles();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);
  const [createIdp, setCreateIdp] = useState("");
  const [poolDialog, setPoolDialog] = useState<IDP | null>(null);
  const [cardProgress, setCardProgress] = useState("");
  const [focusedCardIndex, setFocusedCardIndex] = useState(0);

  const poolIdps = identities.filter(idp => idp.method === "identity_pool");
  const poolProvider = providers.find(provider => provider.method === "identity_pool");
  const internalIdpsTypes = ["identity_pool", "static", "organization", "cloudentity", "external"];

  const externalIdps = identities.filter(idp => !internalIdpsTypes.includes(idp.method ?? ""));

  const missingExternalProviders = providers
    .filter(p => p.method !== "saml")
    .filter(
      ({ method }) =>
        !externalIdps.find(idp => idp.method === method) && !internalIdpsTypes.includes(method)
    );

  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace, {
    enabled: !tenantSignInAndSSO && !!workspace,
  });

  const getPoolQuery = useGetPool(poolIdps[0]?.identity_pool_id ?? "", {
    enabled: poolIdps.length === 1,
  });
  const pool = getPoolQuery.data;

  const allowedAuthenticationMechanisms = Object.values(
    PoolResponseAuthenticationMechanismsEnum
  ).filter(
    v =>
      (serverQuery.data?.authentication_mechanisms || []).includes(
        v as unknown as ServerResponseAuthenticationMechanismsEnum
      ) || (pool?.authentication_mechanisms || []).includes(v)
  );

  const handleCreateIdp = (method: string) => {
    setCreateIdp(method);
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={4} className={classes.leftContainer}>
          <Paper className={classes.paper} id="identities-list-simple-paper">
            <div className={classes.header}>
              <Typography variant="h4" className={classes.name}>
                {BRAND_NAME}
                {poolIdps.length === 1 && (
                  <div style={{ marginLeft: 8 }}>
                    <ConfigureButton
                      name={pool?.name || ""}
                      onClick={() => {
                        if (pool?.id === BUILD_IN_ADMIN_POOL_ID || managePoolsInDialog) {
                          setPoolDialog(pool);
                        } else if (pool?.workspace_id) {
                          navigate(
                            `/${pool?.workspace_id}/identities/${poolIdps[0].method}/${poolIdps[0].id}/configuration`
                          );
                        } else {
                          navigate(
                            `/identity-pools/pools/${poolIdps[0].identity_pool_id}/signin-and-signup`,
                            {
                              state: { backToWorkspace: location.pathname },
                            }
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </Typography>
              {setMode && <IdentitiesListModeController mode={mode} setMode={setMode} noMargin />}
            </div>
            <div>
              {(poolIdps.length === 0 && (
                <div className={classes.empty}>No {BRAND_NAME} provider</div>
              )) ||
                (poolIdps.length > 1 && (
                  <>
                    {poolIdps.map(idp => (
                      <IdentitiesListSimpleSwitchCard
                        key={idp.id}
                        id={idp.id ?? ""}
                        name={idp.name ?? ""}
                        icon={<img src={poolProvider?.icon} alt={idp.id} />}
                        idpAvatar
                        checked={!idp.disabled}
                        onChange={checked => {
                          setFocusedCardIndex(1);
                          handleExistingIdpCheckboxChange(
                            queryClient,
                            workspace,
                            idp.id ?? "",
                            idp,
                            checked,
                            setCardProgress
                          );
                        }}
                        cardId={idp.id ?? ""}
                        progress={cardProgress}
                        configurePoolInDialog={tenantSignInAndSSO ? idp : undefined}
                        configureLink={
                          tenantSignInAndSSO
                            ? undefined
                            : identitiesDetailsPath(
                                idp.authorization_server_id,
                                idp.method,
                                idp.id,
                                "configuration"
                              )
                        }
                        disabled={!checkWorkspacePermissionsQuery.data?.manage_idps}
                        hideExtensions={hideExtensions}
                      />
                    ))}
                  </>
                )) || (
                  <>
                    <AuthenticationMechanismsNotSupportedWarning
                      pool={pool}
                      serverId={workspace}
                      isB2BPortal={isB2BPortal}
                      style={{ marginBottom: 16 }}
                    />

                    {allowedAuthenticationMechanisms.map(option => {
                      const { id, label, icon } = poolAuthMechanismMapper[option];
                      const notSupportedAuthMechanisms = notAllowedAuthenticationMechanismsByServer(
                        [
                          ...(pool?.authentication_mechanisms ?? []),
                          ...(pool?.second_factor_authentication_mechanisms || []),
                        ],
                        serverQuery.data?.authentication_mechanisms || []
                      );

                      return (
                        <IdentitiesListSimpleSwitchCard
                          key={id}
                          id={id}
                          name={label}
                          icon={icon}
                          noSvgStyle={option === PoolResponseAuthenticationMechanismsEnum.Webauthn}
                          checked={!!pool?.authentication_mechanisms?.includes(option)}
                          onChange={checked => {
                            setFocusedCardIndex(1);
                            handlePoolMechanismChange(
                              queryClient,
                              pool,
                              id,
                              option,
                              checked,
                              setCardProgress
                            );
                          }}
                          cardId={id}
                          progress={cardProgress}
                          disabled={
                            !checkWorkspacePermissionsQuery.data?.manage_idps ||
                            (notSupportedAuthMechanisms.length === 1 &&
                              !notSupportedAuthMechanisms.includes(option)) ||
                            notSupportedAuthMechanisms.length > 1
                          }
                          error={
                            !(serverQuery.data?.authentication_mechanisms || []).includes(
                              option as unknown as ServerResponseAuthenticationMechanismsEnum
                            )
                          }
                          hideExtensions={hideExtensions}
                        />
                      );
                    })}
                  </>
                )}
            </div>
            <Typography variant="h4" style={{ margin: "32px 0 16px" }}>
              External Providers
            </Typography>
            <div>
              {externalIdps
                .slice()
                .sort(sortByMethod)
                .map(idp => {
                  const provider = providers.find(provider => provider.method === idp.method);

                  return provider ? (
                    <IdentitiesListSimpleSwitchCard
                      key={idp.id}
                      id={idp.id ?? ""}
                      name={idp.name ?? ""}
                      icon={<img src={provider.icon} alt={idp.id} />}
                      idpAvatar
                      checked={!idp.disabled}
                      onChange={checked => {
                        setFocusedCardIndex(0);
                        handleExistingIdpCheckboxChange(
                          queryClient,
                          workspace,
                          idp.id ?? "",
                          idp,
                          checked,
                          setCardProgress
                        );
                      }}
                      cardId={idp.id ?? ""}
                      progress={cardProgress}
                      configureIDPInDialog={tenantSignInAndSSO ? idp : undefined}
                      configureLink={
                        tenantSignInAndSSO
                          ? undefined
                          : identitiesDetailsPath(
                              idp.authorization_server_id,
                              idp.method,
                              idp.id,
                              "configuration"
                            )
                      }
                      disabled={!checkWorkspacePermissionsQuery.data?.manage_idps}
                      hideExtensions={hideExtensions}
                    />
                  ) : null;
                })}

              {missingExternalProviders
                .filter(p => !embeddedIdps.includes(p.method))
                .sort(sortByMethod)
                .map(idp => {
                  return (
                    <IdentitiesListSimpleSwitchCard
                      key={idp.method}
                      id={idp.method}
                      name={idp.name ?? ""}
                      icon={<img src={idp.icon} alt={idp.method} />}
                      idpAvatar
                      checked={false}
                      onChange={checked => {
                        setFocusedCardIndex(0);
                        if (checked) {
                          handleCreateIdp(idp.method);
                        }
                      }}
                      cardId={idp.method}
                      progress={cardProgress}
                      disabled={!checkWorkspacePermissionsQuery.data?.manage_idps}
                      hideExtensions={hideExtensions}
                    />
                  );
                })}
            </div>
          </Paper>
          {createIdp && (
            <CreateIdp
              method={createIdp}
              workspace={workspace}
              handleClose={() => setCreateIdp("")}
              onCreated={() => {}}
            />
          )}
        </Grid>
        <Grid item xs={8} className={classes.rightContainer}>
          <IdentitiesListSimplePreview
            workspace={workspace}
            poolIdps={poolIdps}
            externalIdps={externalIdps}
            pool={pool}
            focusedCardIndex={focusedCardIndex}
            setFocusedCardIndex={setFocusedCardIndex}
            idpsLoading={idpsLoading}
          />
        </Grid>
      </Grid>
      <SignInAndUserManagementUseCaseTour workspace={workspace} />
      {poolDialog && (
        <IdentityPoolForDialog
          poolId={poolDialog.id}
          serverId={poolDialog.workspace_id}
          onCancel={() => setPoolDialog(null)}
        />
      )}
    </>
  );
}
