import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import set from "lodash/set";

import { SupportedJSONSchema } from "@cloudentity/acp-identity";

import { nanoid } from "../../../../../common/utils/nanoid";

export const extractOnlyRequiredProperties = (schema: SupportedJSONSchema | undefined) => {
  if (!schema) {
    return {};
  }

  if (Object.keys(schema).includes("properties")) {
    const required = schema["required"] || [];
    const properties = schema["properties"] || {};
    if (Array.isArray(required)) {
      const newProperties = Object.keys(properties)
        .filter(p => required.includes(p))
        .reduce((acc, p) => ({ ...acc, [p]: properties[p] }), {});

      const newRequired = required.filter(r => Object.keys(properties).includes(r));
      const propsReduced = Object.keys(newProperties).reduce(
        (acc, p) => ({ ...acc, [p]: extractOnlyRequiredProperties(newProperties[p]) }),
        {}
      );

      return isEmpty(propsReduced)
        ? {}
        : {
            ...omit(schema, ["required", "properties"]),
            ...(newRequired.length > 0 ? { required: newRequired } : {}),
            properties: propsReduced,
          };
    }
  }

  return { ...schema };
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const fieldToTitle = f =>
  capitalizeFirstLetter(f.replace(/[^a-zA-Z0-9 ]/g, " ").toLocaleLowerCase());

export const fieldToLabelWithEveryFirstLetterCapitalized = f =>
  f
    .replace(/[^a-zA-Z0-9 ]/g, " ")
    .toLocaleLowerCase()
    .split(" ")
    .map(capitalizeFirstLetter)
    .join(" ");

export const mapFieldNameToTitle = (schema: SupportedJSONSchema) => {
  if (!schema) {
    return {};
  }

  if (Object.keys(schema).includes("properties")) {
    const properties = schema["properties"] || {};

    return {
      ...schema,
      properties: Object.keys(properties).reduce(
        (acc, p) => ({
          ...acc,
          [p]: {
            ...mapFieldNameToTitle(properties[p]),
            title: fieldToTitle(p),
          },
        }),
        {}
      ),
    };
  }

  return { ...schema };
};

export const getUIOrderBasedOnRequiredFields = (schema: SupportedJSONSchema) => {
  if (!schema) {
    return ["*"];
  }

  if (!schema.required) {
    return ["*"];
  }

  return [...schema.required, "*"];
};

const getPaths = (schema: SupportedJSONSchema, path = "", onlyRequired = false) => {
  if (!schema) {
    return [];
  }

  const newSchema = onlyRequired ? extractOnlyRequiredProperties(schema) : schema;
  const properties = onlyRequired
    ? newSchema.required || []
    : Object.keys(newSchema.properties || {});

  return properties
    .map(r => {
      const isNested = Object.keys(newSchema.properties[r]).includes("properties");
      if (!isNested) {
        return path ? `${path}.${r}` : r;
      }
      return getPaths(newSchema.properties[r], r, onlyRequired);
    })
    .flat();
};

export const getAllPaths = (schema: SupportedJSONSchema, path = "") => {
  return getPaths(schema, path, false);
};

export const getRequiredPaths = (schema: SupportedJSONSchema, path = "") => {
  return getPaths(schema, path, true);
};

export const getPathsAndTypes = (
  schema?: SupportedJSONSchema,
  key = "",
  path = "",
  type = ""
): { key: string; path: string; type: string }[] => {
  if (!schema) {
    return [];
  }

  const propertyPath = [path, key].filter(v => v).join(".");
  const propertyType = [schema.type, type].filter(v => v).join("_");

  if (schema.properties) {
    return Object.entries(schema.properties).flatMap(([key, property]) => {
      return getPathsAndTypes(property, key, propertyPath, type);
    });
  }

  if (schema.items) {
    return getPathsAndTypes(schema.items, key, path, propertyType);
  }

  if (!key && !propertyPath && !propertyType) {
    return [];
  }

  return [
    {
      key,
      path: propertyPath,
      type: propertyType,
    },
  ];
};

type Type = "null" | "boolean" | "integer" | "string" | "number" | "array" | "object";

const mockValueForType = (types: Type[]) => {
  const [type, ...otherTypes] = types;

  switch (type) {
    case "null":
      return null;
    case "boolean":
      return Math.random() > 0.5;
    case "string":
      return nanoid(5);
    case "integer":
    case "number":
      return Math.floor(Math.random() * 100);
    case "array":
      return [mockValueForType(otherTypes)];
    default:
      return "";
  }
};

const emptyValueForType = (types: Type[]) => {
  const [type, ...otherTypes] = types;

  switch (type) {
    case "null":
      return null;
    case "boolean":
      return false;
    case "string":
      return "";
    case "integer":
    case "number":
      return 0;
    case "array":
      return [emptyValueForType(otherTypes)];
    default:
      return "";
  }
};

export const generateMockJsonForSchema = (schema: any) => {
  const pathsAndTypes = getPathsAndTypes(schema);
  const obj = {};

  pathsAndTypes.forEach(({ path, type }) => {
    const types = type.split("_").reverse() as Type[];
    const value = mockValueForType(types);
    set(obj, path, value);
  });

  return obj;
};

export const generateMockJsonForSchemaWithEmptyValues = (schema: any) => {
  const pathsAndTypes = getPathsAndTypes(schema);
  const obj = {};

  pathsAndTypes.forEach(({ path, type }) => {
    const types = type.split("_").reverse() as Type[];
    const value = emptyValueForType(types);
    set(obj, path, value);
  });

  return obj;
};
