import { getTenantId } from "../../../common/api/paths";
import { useLocalStorage } from "../../../common/utils/hooks/useLocalStorage";
import { nanoid } from "../../../common/utils/nanoid";
import { useGetEnvironment } from "../../services/adminEnvironmentQuery";
import { useGetScripts } from "../../services/adminScriptsQuery";
import { useWorkspace } from "../common/useWorkspace";
import { zeroDate } from "../extensions/OutdatedRuntimeButton";

export const useNotifications = () => {
  const [workspace] = useWorkspace();
  const getScriptsQuery = useGetScripts(getTenantId(), workspace);
  const getEnvironmentQuery = useGetEnvironment();
  const [, setNavMode] = useLocalStorage(`${workspace}_nav_mode`);

  const numberOfScriptsWithDeprecatedRuntime = (getScriptsQuery.data?.scripts || [])
    .map(s =>
      (getEnvironmentQuery.data?.script_runtimes || []).find(sr => sr.env_version === s.env_version)
    )
    .filter(sr => sr?.valid_until !== zeroDate).length;

  const notifications =
    numberOfScriptsWithDeprecatedRuntime > 0
      ? [
          {
            id: nanoid(),
            linkLabel: "Extensions",
            linkTo: `/${workspace}/extensions`,
            message: `${numberOfScriptsWithDeprecatedRuntime} extension${
              numberOfScriptsWithDeprecatedRuntime > 1 ? "s" : ""
            } have deprecated runtime`,
            onClick: () => {
              setNavMode("advanced");
            },
          },
        ]
      : [];

  return {
    notifications,
  };
};
