import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { BookOpen } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { PoolResponse } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../common/api/paths";
import ConfirmationDialog from "../../../../../common/components/ConfirmationDialog";
import RouteLeavingGuard from "../../../../../common/components/RouteLeavingGuard";
import RouterLink from "../../../../../common/components/RouterLink";
import ApplicationReadOnlyInputWithCopy from "../../../../../common/components/applications/ApplicationReadOnlyInputWithCopy";
import IdentityPoolSideNavIcon from "../../../../../common/components/icons/IdentityPoolSideNavIcon";
import { notifyErrorOrDefaultTo } from "../../../../../common/components/notifications/notificationService";
import ColorField from "../../../../../common/utils/forms/ColorField";
import Form, { useForm } from "../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../common/utils/forms/FormFooter";
import TextField from "../../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../../common/utils/forms/validation";
import {
  getIDPQueryKey,
  listIDPsForIdentityPoolQueryKey,
  listIDPsQueryKey,
  useListIDPsForIdentityPool,
} from "../../../../services/adminIDPsQuery";
import adminIdentityPoolsApi from "../../../../services/adminIdentityPoolsApi";
import {
  listPoolsQueryKey,
  listUserWorkspacePoolsQueryKey,
  listWorkspacePoolsQueryKey,
  useListPools,
} from "../../../../services/adminIdentityPoolsQuery";
import PageContent from "../../../common/PageContent";
import { useWorkspace } from "../../../common/useWorkspace";
import { SaveFormType, usePoolRootUrl } from "../utils";
import IdentityPoolThemeSelector from "./IdentityPoolThemeSelector";
import {
  getPoolWorkspaceId,
  setPoolWorkspaceId,
  useIdentityPoolThemeSelector,
} from "./useIdentityPoolThemeSelector";

const useStyles = makeStyles()(theme => ({
  checkboxes: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  options: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  paper: {
    background: "#F7FAFF",
    padding: 32,
  },
  divider: {
    marginTop: 24,
    marginBottom: 24,
    marginLeft: -32,
    marginRight: -32,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  link: {
    display: "block",
    marginBottom: 16,
  },
  label: {
    fontWeight: "bold",
    fontSize: 12,
    color: theme.palette.secondary.light,
    textTransform: "uppercase",
    marginBottom: 16,
  },
}));

interface IdentityPoolConfigurationProps {
  handleUpdate: (data: SaveFormType) => void;
  pool: PoolResponse | undefined;
  noManagePermission: boolean;
}

export default function IdentityPoolConfiguration({
  handleUpdate,
  pool,
  noManagePermission,
}: IdentityPoolConfigurationProps) {
  const { classes } = useStyles();

  const [workspace] = useWorkspace();
  const { id = "" } = useParams<{ id: string; tab: string }>();
  const tenantId = getTenantId();
  const navigate = useNavigate();
  const { rootUrl, mode } = usePoolRootUrl();
  const [progress, setProgress] = useState(false);
  const [remove, setRemove] = useState(false);

  const queryClient = useQueryClient();
  const poolsQuery = useListPools({ tid: tenantId, limit: 100 });
  const listIdentityPoolIDPsQuery = useListIDPsForIdentityPool({ ipID: pool?.id! });
  const { poolConnectedToMoreThatOneIDP } = useIdentityPoolThemeSelector({ pool });

  const data = useMemo(
    () => ({
      ...pool,
      id: pool?.id,
      name: pool?.name,
      badge_color: pool?.badge_color?.toUpperCase() || "#BDBDBD",
      admin_initiated_registration: true,
      themeWorkspaceId: getPoolWorkspaceId(pool?.id || "") || "",
    }),
    [pool]
  );

  const form = useForm({
    id: "identity-pool",
    initialValues: data,
    progress,
    noManagePermission,
  });

  const poolNames = (poolsQuery.data?.pools ?? [])
    .map(pool => pool.name)
    .filter(name => name !== pool?.name);

  const handleRemove = () => {
    setProgress(true);

    adminIdentityPoolsApi
      .deletePool({ ipID: id, withIdp: true })
      .then(() => form.reset())
      .then(() => {
        if (mode === "workspace") {
          queryClient.removeQueries({ queryKey: listWorkspacePoolsQueryKey(workspace) });
          queryClient.removeQueries({ queryKey: listUserWorkspacePoolsQueryKey(workspace) });
        }
      })
      .then(() => queryClient.invalidateQueries({ queryKey: listPoolsQueryKey(getTenantId()) }))
      .then(() => queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(getTenantId()) }))
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(tenantId) })
      )
      .then(() =>
        Promise.all(
          (listIdentityPoolIDPsQuery.data?.idps || []).map(idp =>
            queryClient.invalidateQueries({ queryKey: getIDPQueryKey(idp.id!) })
          )
        )
      )
      .then(() => navigate(rootUrl))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to delete pool"))
      .finally(() => setProgress(false));
  };

  return (
    <PageContent fullWidth={false}>
      <Form form={form}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Paper style={{ padding: 32 }}>
              <TextFieldRequired
                name="name"
                label="Name"
                rules={{
                  validate: {
                    onlyAlphanumeric: validators.onlyAlphanumericWithWhitespacesDashUnderscore({
                      label: "Name",
                    }),
                    notUniq: v =>
                      poolNames.map(s => s.toLowerCase()).indexOf(v.toLowerCase()) === -1 ||
                      "Pool with a given name already exists",
                  },
                }}
              />
              <TextField name="description" label="Description" multiline maxRows={3} minRows={3} />

              <ColorField name="badge_color" label="Tag Color" />

              {poolConnectedToMoreThatOneIDP && (
                <IdentityPoolThemeSelector pool={pool!} withEmptyValue />
              )}

              <div className={classes.footer}>
                {!noManagePermission && (
                  <Button
                    variant="text"
                    id="pool-delete-button"
                    color="error"
                    onClick={() => setRemove(true)}
                  >
                    Delete Pool
                  </Button>
                )}
                <FormFooter
                  onSubmit={data => {
                    if (poolConnectedToMoreThatOneIDP) {
                      setPoolWorkspaceId(pool?.id || "", data.themeWorkspaceId || "");
                    }
                    handleUpdate({
                      ...data,
                      name: data.name.trim(),
                    });
                  }}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={5}>
            <Paper className={classes.paper}>
              <div className={classes.options}>
                <Avatar style={{ background: data.badge_color, marginBottom: 24 }}>
                  {data.name && data.name[0].toUpperCase()}
                </Avatar>
                <Typography fontFamily="monospace">{data.name}</Typography>
              </div>
              <Divider className={classes.divider} />
              <ApplicationReadOnlyInputWithCopy
                id="pool-id-info"
                label="Identity Pool Id"
                icon={IdentityPoolSideNavIcon}
                value={data.id}
              />
              <Divider className={classes.divider} />

              <ApplicationReadOnlyInputWithCopy
                id="learn-more"
                label="Learn more"
                icon={BookOpen}
                value={[
                  {
                    label: "Identity Management Solution Guide",
                    value:
                      "https://docs.secureauth.com/ciam/en/identity-management-for-b2b,-partner-and-hyper-scale-consumer-use-cases.html",
                  },
                  {
                    label: "Configuring Identity Pools",
                    value:
                      "https://docs.secureauth.com/ciam/en/store-users-and-user-populations-in-identity-pools.html",
                  },
                  {
                    label: "Identity Service API Reference",
                    value: "https://docs.secureauth.com/ciam-apis/identitysystem.html",
                  },
                ]}
                type="link"
              />
            </Paper>
          </Grid>
          <RouteLeavingGuard />
        </Grid>

        {remove && (
          <ConfirmationDialog
            title="Delete Pool"
            content={
              <>
                You're about to delete the <b>{pool?.name}</b> pool.{" "}
                {(listIdentityPoolIDPsQuery.data?.idps || []).length > 0 && (
                  <>
                    All connected IDPs will be deleted:
                    <ul>
                      {(listIdentityPoolIDPsQuery.data?.idps || []).map(idp => (
                        <li>
                          <RouterLink
                            to={`/${idp.authorization_server_id}/identities/${idp.method}/${idp.id}/configuration`}
                          >
                            {idp.name}
                          </RouterLink>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                This cannot be undone. Delete anyway?
              </>
            }
            confirmText="Delete"
            progress={progress}
            onCancel={() => setRemove(false)}
            onConfirm={handleRemove}
          />
        )}
      </Form>
    </PageContent>
  );
}
