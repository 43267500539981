import React from "react";

import SelfServiceController from "../../common/components/selfServiceSettings/SelfServiceController";

interface Props {
  preview?: boolean;
  areApplicationsEnabled?: boolean;
}

export default function Dashboard({ preview, areApplicationsEnabled }: Props) {
  return (
    <SelfServiceController
      config={{
        withApps: areApplicationsEnabled,
        withDialog: false,
        showMetadata: true,
      }}
      preview={preview}
    />
  );
}
