import React from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Grid from "@mui/material/Grid";

import { ScriptExecutionPointTypeEnum } from "@cloudentity/acp-admin";

import { BRAND_NAME } from "../../../common/theme/theme";
import { useFeature } from "../../../common/utils/hooks/useFeature";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import ConnectCustomApplicationExtension from "./ConnectCustomApplicationExtension";
import ConnectScriptExtension from "./ConnectScriptExtension";
import { IdpUiModelType } from "./identities.utils";

interface Props {
  method: IdpUiModelType;
  updateProgress: boolean;
  onUpdate: (provider: IdpUiModelType) => void;
}

export default function IdentitiesDetailsExtensions({ method, updateProgress, onUpdate }: Props) {
  const isAdminWorkspaceAccessEnabled = useFeature("admin_workspace_access");
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(
    method.authorization_server_id
  );

  const isCustomAppsExtensionVisible = !!checkWorkspacePermissionsQuery.data?.read_custom_apps;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={7}>
        {checkWorkspacePermissionsQuery.data?.read_scripts && (
          <ConnectScriptExtension
            label="Post Authentication script"
            targetFk={method.id || ""}
            type={ScriptExecutionPointTypeEnum.PostAuthnCtx}
            serverId={method.authorization_server_id!}
            hideButtonLink={
              method.authorization_server_id === "admin" && !isAdminWorkspaceAccessEnabled
            }
          />
        )}

        {isCustomAppsExtensionVisible && (
          <div style={{ marginTop: 24 }}>
            <ConnectCustomApplicationExtension
              idp={method}
              updateProgress={updateProgress}
              onUpdate={onUpdate}
              editable={!!checkWorkspacePermissionsQuery.data?.manage_idps}
              hideButtonLink={
                method.authorization_server_id === "admin" && !isAdminWorkspaceAccessEnabled
              }
            />
          </div>
        )}
      </Grid>
      <Grid item xs={12} lg={5}>
        <Alert id="identities-details-extensions-alert" severity="info">
          <AlertTitle>Extending Authentication process</AlertTitle>
          {isCustomAppsExtensionVisible && checkWorkspacePermissionsQuery.data?.read_scripts && (
            <p>
              {BRAND_NAME} offers two options for extending the user authentication process when
              using an Identity Provider: Post Authentication script and Post Authentication custom
              application.
            </p>
          )}

          {checkWorkspacePermissionsQuery.data?.read_scripts && (
            <p>
              The Post Authentication script allows for the execution of custom JavaScript code as
              part of the user authentication process, allowing for the modification of
              authentication context attributes, enhancement of the user context, or triggering
              custom API calls.
            </p>
          )}
          {isCustomAppsExtensionVisible && (
            <>
              <p>
                The Post Authentication custom application allows {BRAND_NAME} to interrupt the
                OAuth/SAML flow and redirect the user to a custom, business-specific application
                hosted by the customer. This application allows users to complete additional
                processes or interactions as needed during the authentication process.
              </p>
            </>
          )}
          {isCustomAppsExtensionVisible && checkWorkspacePermissionsQuery.data?.read_scripts && (
            <p>
              Both options provide the ability to enhance the user authentication process and meet
              the specific needs of the business.
            </p>
          )}
        </Alert>
      </Grid>
    </Grid>
  );
}
