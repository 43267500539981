import axios from "axios";
import bigDecimal from "js-big-decimal";

import { unauthorizedRedirectAxios } from "../common/api/unauthorized-redirect.interceptor";
import { getFromLocalStorage, setInLocalStorage } from "../common/utils/localStorage.utils";
import { nanoid } from "../common/utils/nanoid";
import { getParameterByName } from "../common/utils/query.utils";

const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(v => v, unauthorizedRedirectAxios);

let etag = getParameterByName("etag") || getFromLocalStorage("etag") || "";
setInLocalStorage("etag", etag);

axiosInstance.interceptors.response.use(v => {
  if (v.headers?.etag && v.config.method !== "get") {
    const etagFromHeaders = v.headers.etag.split(",")[0];
    if (!etag) {
      etag = etagFromHeaders;
      setInLocalStorage("etag", etag);
    } else {
      const newEtagDecoded = window.atob(etagFromHeaders);
      const previousEtagDecoded = window.atob(etag.split(",")[0]);

      try {
        const newParsed = JSON.parse(newEtagDecoded);
        const previousParsed = JSON.parse(previousEtagDecoded);

        if (bigDecimal.compareTo(newParsed.revision, previousParsed.revision) === 1) {
          etag = etagFromHeaders;
          setInLocalStorage("etag", etag);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  return v;
});

axiosInstance.interceptors.request.use(config => {
  if (config?.headers) {
    if (etag) {
      config.headers["If-Match"] = etag;
    }
    config.headers["X-Idempotency-Key"] = nanoid(32);
  }

  return config;
});

export const axiosInstanceWithoutLogoutRedirect = axios.create();

export default axiosInstance;
