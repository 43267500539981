import React, { useState } from "react";
import { useParams } from "react-router";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import { OrganizationResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-admin";
import { Pool, PoolResponse } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../common/api/paths";
import Dialog from "../../../../common/components/Dialog";
import IconTooltip from "../../../../common/components/IconTooltip";
import { notifySuccess } from "../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextField from "../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";
import {
  setDefaultAuthenticationMechanismsIfAvailableForWorkspace,
  useCreateWorkspaceIdentityPoolMutation,
} from "../../../services/adminIdentityPoolsMutations";
import { listWorkspacePoolsQueryKey } from "../../../services/adminIdentityPoolsQuery";
import { useGetOrganization } from "../../../services/adminOrganizationsQuery";

const useStyles = makeStyles()(theme => ({
  titleWithTooltip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  openTooltip: {
    backgroundColor: "#CFE9FF",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}));

interface Props {
  onCreated: (pool: PoolResponse) => void;
  onClose: () => void;
  workspaceId?: string;
}

export default function AddTeamDialog({ onCreated, onClose, workspaceId }: Props) {
  const { id } = useParams<{ id: string }>();
  const { cx, classes } = useStyles();

  const [openTooltip, setOpenTooltip] = useState(false);

  const createWorkspaceIdentityPoolMutation = useCreateWorkspaceIdentityPoolMutation();
  const getOrganizationQuery = useGetOrganization(workspaceId || id || "");

  const form = useForm({
    id: "add-team",
    progress: createWorkspaceIdentityPoolMutation.isPending || getOrganizationQuery.isLoading,
  });
  const queryClient = useQueryClient();

  const handleCreatePool = data => {
    const pool: Pool = {
      name: data.name.trim(),
      description: data.description?.trim(),
      tenant_id: getTenantId(),
      workspace_id: workspaceId || id,
      identifier_case_insensitive: true,
      metadata_schema_id: "organizations_pool_default_metadata",
      payload_schema_id: "organizations_pool_default_payload",
    };

    setDefaultAuthenticationMechanismsIfAvailableForWorkspace({
      pool,
      supportedAuthenticationMechanisms: getOrganizationQuery.data?.authentication_mechanisms || [],
      authenticationMechanism: OrganizationResponseAuthenticationMechanismsEnum.Password,
      setAuthenticationMechanismsAsPreferred: true,
    });

    let createdPool: PoolResponse;
    return createWorkspaceIdentityPoolMutation
      .mutateAsync({
        wid: workspaceId || id || "",
        pool,
        withIdp: true,
      })
      .then(res => {
        createdPool = res.data;
        return res;
      })
      .then(() => {
        notifySuccess(
          <span>
            User population <strong>{data.name}</strong> created
          </span>
        );
      })
      .then(() =>
        queryClient.removeQueries({
          queryKey: listWorkspacePoolsQueryKey(workspaceId || id || ""),
        })
      )
      .then(() => onCreated(createdPool))
      .then(onClose);
  };

  return (
    <Dialog
      id="identity-pool-create-dialog"
      title={
        <div className={classes.titleWithTooltip}>
          <span style={{ marginRight: 8 }}>Create user population</span>
          <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
            <IconButton
              onClick={() => setOpenTooltip(openTooltip => !openTooltip)}
              className={cx(openTooltip && classes.openTooltip)}
            >
              <IconTooltip
                id={`${id}-tooltip`}
                open={openTooltip}
                noIconMargin
                title={
                  <div>
                    <div>
                      What are <b>User populations</b>?
                    </div>
                    <p style={{ lineHeight: "18px" }}>
                      It's a powerful tool for organizations to logically separate identity and
                      access management process.
                    </p>
                    <div>
                      <b>
                        <Link
                          href="https://docs.secureauth.com/ciam/en/user-populations.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Learn more
                        </Link>
                      </b>
                    </div>
                  </div>
                }
                placement="bottom"
              />
            </IconButton>
          </ClickAwayListener>
        </div>
      }
      onClose={onClose}
    >
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label="Name"
          placeholder="e.g. suppliers, merchants, contractors"
          rules={{
            validate: {
              onlyAlphanumeric: validators.onlyAlphanumericWithWhitespacesDashUnderscore({
                label: "Name",
              }),
            },
          }}
        />

        <TextField name="description" label="Description" multiline maxRows={3} minRows={3} />

        <FormFooter
          onCancel={onClose}
          onSubmit={handleCreatePool}
          submitText="Create"
          submitWithEnterKey
        />
      </Form>
    </Dialog>
  );
}
