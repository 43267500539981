import React from "react";
import { Controller, RegisterOptions } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { InputBaseComponentProps } from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import FormInputLabel, { FormInputLabelProps } from "../../components/FormInputLabel";
import { useFormContext } from "./Form";

const useStyles = makeStyles()(theme => ({
  root: {
    minHeight: 48,
    ".MuiInputBase-input": {
      backgroundColor: theme.custom.greys.fieldBackground,
    },
    ".MuiOutlinedInput-notchedOutline": {
      boxShadow: theme.custom.shadows.formInner,
    },
  },
  option: {
    minHeight: 36,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  optionName: {
    fontWeight: 500,
    width: "100%",
  },
  optionDescription: {
    marginTop: 4,
    color: "#9EA1B7",
  },
}));

export interface SelectFieldProps extends Omit<SelectProps, "onChange" | "optional"> {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  options: { name: React.ReactNode; value: string; disabled?: boolean; description?: string }[];
  helperText?: string | React.ReactElement;
  optional?: boolean;
  onChange?: (value: string) => void;
  inputProps?: InputBaseComponentProps;
  labelProps?: Omit<FormInputLabelProps, "id" | "label">;
  rules?: Pick<RegisterOptions, "required" | "validate">;
  disabled?: boolean;
}

export default function SelectField({
  id,
  name,
  label,
  placeholder,
  options,
  helperText,
  optional,
  onChange,
  inputProps = {},
  labelProps,
  rules,
  disabled,
  ...props
}: SelectFieldProps) {
  const { classes } = useStyles();
  const { form, disabled: formDisabled, id: formId } = useFormContext();

  const required = rules?.required;
  const isOptional = optional !== undefined ? optional : !required;
  const inputID = `${id || formId}-${name}`;

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={props.defaultValue || ""}
      rules={rules}
      render={({ field, fieldState }) => {
        const error = fieldState.error;

        return (
          <FormControl
            variant="outlined"
            fullWidth
            error={!!error}
            style={{ marginBottom: 32, ...props.style }}
          >
            {label && (
              <FormInputLabel
                id={`${inputID}-label`}
                label={label}
                optional={isOptional}
                forId={`${inputID}-input`}
                {...labelProps}
              />
            )}
            <Select
              id={`${inputID}-select`}
              fullWidth
              onChange={e => {
                if (typeof onChange === "function") {
                  onChange(e.target.value as string);
                }
                field.onChange(e);
              }}
              className={classes.root}
              value={field.value ? field.value : ""}
              name={name}
              displayEmpty={props.displayEmpty}
              disabled={disabled || formDisabled}
              data-testid={props["data-testid"]}
              inputProps={{ ...inputProps, id: `${inputID}-input` }}
              style={
                placeholder && field.value === "" && props.displayEmpty ? { opacity: 0.38 } : {}
              }
              renderValue={props.renderValue}
            >
              {placeholder && (
                <MenuItem disabled value="" style={{ display: "none" }}>
                  {placeholder}
                </MenuItem>
              )}
              {(options || []).map(option => (
                <MenuItem
                  key={`select-option-${option.value}`}
                  value={option.value}
                  disabled={option.disabled || false}
                  className={classes.option}
                >
                  <div className={classes.optionName}>{option.name}</div>

                  {option.description && (
                    <Typography variant="textSM" className={classes.optionDescription}>
                      {option.description}
                    </Typography>
                  )}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText id={`${id}-helper-text`}>{error?.message || helperText}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}
