import React, { useEffect, useMemo } from "react";

import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Check } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { useFormContext } from "../../../common/utils/forms/Form";
import { useGetEnvironment } from "../../services/adminEnvironmentQuery";

const useStyles = makeStyles()(theme => ({
  logosContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 16,
    minHeight: 100,
    width: "100%",
    marginBottom: 16,
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    width: 100,
    height: 100,
    border: "1px solid #D0D5DD",
    borderRadius: 4,
    "&:hover": {
      cursor: "pointer",
      boxShadow: `0 0 0 1.5px ${theme.palette.secondary.dark}`,
    },
  },
}));

const appendProxyUrl = (url: string, imgProxyUrl: string) =>
  imgProxyUrl ? imgProxyUrl + url : url;

interface Props {
  logos: { url: string; status: "pending" | "success" | "error" }[] | null;
  setLogos: React.Dispatch<
    React.SetStateAction<{ url: string; status: "pending" | "success" | "error" }[] | null>
  >;
}

export default function LogosList({ logos, setLogos }: Props) {
  const { classes } = useStyles();
  const theme = useTheme();

  const { form } = useFormContext();
  const logoUrl = form.watch("logo_url");

  const getEnvironmentQuery = useGetEnvironment();
  const imgProxyUrl = getEnvironmentQuery.data?.image_proxy_url || "";

  const successLogosToDisplay = useMemo(
    () =>
      logos
        ?.slice()
        .sort((a, b) => a.url.localeCompare(b.url))
        .filter(item => item.status === "success")
        .map(item => item.url)
        .slice(0, 4) ?? null,
    [logos]
  );

  useEffect(() => {
    if (successLogosToDisplay) {
      const firstSuccessLogo = successLogosToDisplay.at(0);
      if (firstSuccessLogo) {
        form.setValue("logo_url", firstSuccessLogo);
      } else {
        form.setValue("logo_url", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successLogosToDisplay]);

  return (
    <div className={classes.logosContainer}>
      {logos?.map(({ url }, index) => {
        const isSelected = logoUrl === url;

        return (
          <div
            key={url}
            className={classes.logoContainer}
            style={{
              boxShadow: isSelected ? `0 0 0 1.5px ${theme.palette.secondary.dark}` : "",
              display: successLogosToDisplay?.includes(url) ? "flex" : "none",
              justifyContent: "center",
              background:
                "repeating-conic-gradient(#F0F0F0 0% 25%, transparent 0% 50%) 50% / 16px 16px",
            }}
            onClick={() => {
              form.setValue("logo_url", url);
            }}
          >
            {isSelected && <Check style={{ position: "absolute", top: 6, right: 6 }} size={12} />}
            <img
              id={`img-id-${url}`}
              src={appendProxyUrl(url, imgProxyUrl)}
              style={{
                maxWidth: 98,
                maxHeight: 98,
              }}
              alt="logo"
              onLoad={() => {
                setLogos(logos => [
                  ...(logos ?? []).slice(0, index),
                  { url, status: "success" },
                  ...(logos ?? []).slice(index + 1),
                ]);
              }}
              crossOrigin="anonymous"
              onError={() => {
                setLogos(logos => [
                  ...(logos ?? []).slice(0, index),
                  { url, status: "error" },
                  ...(logos ?? []).slice(index + 1),
                ]);
              }}
            />
          </div>
        );
      })}
      <NoLogoBox />
    </div>
  );
}

const NoLogoBox = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { form } = useFormContext();
  const logoUrl = form.watch("logo_url");

  return (
    <div
      className={classes.logoContainer}
      style={{
        justifyContent: "center",
        boxShadow: !logoUrl ? `0 0 0 1.5px ${theme.palette.secondary.dark}` : "",
      }}
      onClick={() => form.setValue("logo_url", null)}
    >
      {!logoUrl && <Check style={{ position: "absolute", top: 6, right: 6 }} size={12} />}
      <Typography>No Logo</Typography>
    </div>
  );
};
