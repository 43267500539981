import React, { useCallback, useEffect, useState } from "react";

import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ColorThief from "colorthief";
import debounce from "lodash/debounce";
import { Search } from "react-feather";
import { makeStyles } from "tss-react/mui";
import isURL from "validator/lib/isURL";

import { TRANSPARENT_HEX_VALUE } from "../../../common/components/nav/utils";
import { useFormContext } from "../../../common/utils/forms/Form";
import TextField from "../../../common/utils/forms/TextField";
import { validators } from "../../../common/utils/forms/validation";
import { useGetEnvironment } from "../../services/adminEnvironmentQuery";
import { useSearchImages } from "../../services/adminImagesQuery";
import { initialColors } from "../workspaceDirectory/WorkspacesColorInput";
import LogosList from "./LogosList";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "22px",
    color: theme.palette.secondary.dark,
  },
  icon: {
    color: theme.palette.primary.main,
    width: 16,
    height: 16,
  },
}));

interface Props {
  logos: { url: string; status: "pending" | "success" | "error" }[] | null;
  setLogos: React.Dispatch<
    React.SetStateAction<{ url: string; status: "pending" | "success" | "error" }[] | null>
  >;
  setPalette: React.Dispatch<React.SetStateAction<string[]>>;
}

const isQueryUrl = (query: string) =>
  isURL(query.trim(), {
    require_protocol: true,
    require_tld: false,
  }) || query === "/static/images/no-logo.svg";

export default function SetupLogo({ logos, setLogos, setPalette }: Props) {
  const { classes } = useStyles();

  const getEnvironmentQuery = useGetEnvironment();
  const hasImageSearch = !!getEnvironmentQuery.data?.has_google_image_search;

  const { form } = useFormContext();
  const query = form.watch("query") ?? null;
  const logoUrl = form.watch("logo_url") ?? null;

  const [customLogoQuery, setCustomLogoQuery] = useState(logoUrl);

  const searchImagesQuery = useSearchImages(
    { q: customLogoQuery + " logo png" },
    { enabled: hasImageSearch && !!customLogoQuery && !isQueryUrl(customLogoQuery) }
  );

  useEffect(() => {
    setTimeout(() => {
      if (!!customLogoQuery && isQueryUrl(customLogoQuery)) {
        setLogos(logos => [
          {
            url: customLogoQuery,
            status: logos?.find(logo => logo.url === customLogoQuery)?.status ?? "pending",
          },
        ]);
        return;
      }

      if (hasImageSearch && !!customLogoQuery && !isQueryUrl(customLogoQuery)) {
        setLogos(
          logos =>
            searchImagesQuery.data?.images?.map(image => ({
              url: image.url ?? "",
              status: logos?.find(logo => logo.url === image.url)?.status ?? "pending",
            })) || []
        );
        return;
      }

      setLogos([]);
    });
  }, [setLogos, customLogoQuery, hasImageSearch, searchImagesQuery.data]);

  useEffect(() => {
    if (logoUrl) {
      const img = document.getElementById(`img-id-${logoUrl}`) as HTMLImageElement;
      if (img) {
        function setValuesFromImg() {
          setTimeout(() => {
            const palette = calculatePalette(img);
            const opacity = opacityRatio(img);
            setPalette(opacity < 1 ? [...palette, TRANSPARENT_HEX_VALUE] : palette);
          });
        }

        if (img.complete) {
          setValuesFromImg();
        } else {
          img.addEventListener("load", () => {
            setValuesFromImg();
          });
        }
      }
    } else {
      setTimeout(() => {
        setPalette(
          [
            "#0083FF",
            "#f0f0f0",
            "#222533",
            ...initialColors.filter(c => !["#0083FF", "#f0f0f0", "#222533"].includes(c)),
          ].slice(0, 5)
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, logoUrl, logos]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdateQuery = useCallback(
    debounce(q => setCustomLogoQuery(q), 500),
    []
  );

  useEffect(() => {
    handleUpdateQuery(query);
  }, [handleUpdateQuery, query]);

  return (
    <>
      <TextField
        name="query"
        label="Logo"
        placeholder={hasImageSearch ? "Company name or URL" : "URL to your company logo"}
        helperText={
          hasImageSearch
            ? "Type company name or provide direct URL to your company logo"
            : "Provide direct URL to your company logo"
        }
        rules={{
          validate: hasImageSearch ? {} : { validURL: validators.validURL({ label: "Logo URL" }) },
        }}
        tooltip="The logo helps users identify your company. The icon for each application is imported from the application settings."
        optional={false}
        withStartAdornment={<Search className={classes.icon} />}
        withEndAdornment={
          <IconButton
            tabIndex={-1}
            onClick={() => {
              form.setValue("query", "", { shouldValidate: true });
              form.setValue("logo_url", "", { shouldValidate: true });
              setCustomLogoQuery("");
            }}
            style={{ padding: 6 }}
          >
            <Close className={classes.icon} />
          </IconButton>
        }
        style={{ marginBottom: 16 }}
      />
      <LogosList logos={logos} setLogos={setLogos} />
    </>
  );
}

const colorArrayToHex = arr =>
  `#${arr
    .map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("")}`;

const calculatePalette = (img: HTMLImageElement) => {
  try {
    const colorThief = new ColorThief();
    const dominantColor = colorThief.getColor(img);
    const palette = colorThief.getPalette(img, 5);

    const dominantColorAsHex = colorArrayToHex(dominantColor);
    return [
      dominantColorAsHex,
      "#f0f0f0",
      ...palette.map(arr => colorArrayToHex(arr)).filter(c => c !== dominantColorAsHex),
    ];
  } catch (e) {
    return [];
  }
};

function opacityRatio(img: HTMLImageElement) {
  try {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      return 1;
    }

    canvas.width = img.width;
    canvas.height = img.height;
    context?.drawImage(img, 0, 0);
    const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
    let opacity = 0;
    for (let i = 0; i < data.length; i += 4) {
      opacity += data[i + 3];
    }
    return opacity / 255 / (data.length / 4);
  } catch (e) {
    return 1;
  }
}
