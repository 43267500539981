import React, { useState } from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import icon from "../../../assets/images/icons/quickstart/icon-crown.svg";
import { getTenantId } from "../../../common/api/paths";
import Popover from "../../../common/components/Popover";
import { UpgradeButton } from "../../../common/components/upgrade/UpgradeButton";
import UpgradeModal from "../../../common/components/upgrade/UpgradeModal";
import { BRAND_NAME } from "../../../common/theme/theme";
import { getDayWord, getTrialInfo } from "../../../common/utils/date.utils";
import { useGetLicense } from "../../services/adminLicenseQuery";
import { useCheckTenantPermissions } from "../../services/adminPermissionsQuery";
import { useGetTenant } from "../../services/adminTenantsQuery";
import { useGetUserInfo } from "../../services/oauth2Query";
import { TenantMetadataRegistration } from "../getStartedV2/utils";

const useStyles = makeStyles()(() => ({
  button: {
    background: "linear-gradient(133deg, #FFEB3C -29.4%, #C3F380 39.05%, #4394F7 110.3%)",
    color: "black",
    borderRadius: 24,
    padding: "5px 16px",
    marginRight: 8,
  },
  popover: {
    "& > p": {
      marginTop: 16,
    },
    "& > button": {
      marginTop: 16,
    },
  },
}));

export default function TrialButton() {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [sentModal, setSentModal] = useState(false);

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canGetTenant = !!checkTenantPermissionsQuery.data?.get_tenant;

  const userinfoQuery = useGetUserInfo();
  const tenantQuery = useGetTenant(getTenantId(), { enabled: canGetTenant });
  const licenseQuery = useGetLicense({ enabled: canGetTenant });

  const { isTrial, daysLeft } = getTrialInfo(licenseQuery.data);
  const firstName =
    (tenantQuery.data?.metadata?.registration as TenantMetadataRegistration)?.first_name ||
    userinfoQuery.data?.given_name ||
    "";

  function handleClosePopover() {
    setAnchorEl(null);
  }

  return isTrial && canGetTenant && !tenantQuery.isLoading && !userinfoQuery.isLoading ? (
    <>
      <Button className={classes.button} onClick={e => setAnchorEl(e.currentTarget)}>
        <img src={icon} alt="icon" style={{ marginRight: 4 }} />
        Trial ends in {daysLeft} {getDayWord(daysLeft)}
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        header="🎉 You're making progress, why stop now?"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ marginTop: 16 }}
        maxWidth={320}
      >
        <div className={classes.popover}>
          <Typography>
            Thank you <b>{firstName}</b> for exploring more of what {BRAND_NAME} has to offer! 🎉
          </Typography>
          <Typography>
            🔒 Stay protected with enterprise-grade identity and access management.
          </Typography>
          <UpgradeButton
            onUpgradeSent={() => {
              handleClosePopover();
              setSentModal(true);
            }}
          />
        </div>
      </Popover>

      {sentModal && (
        <UpgradeModal
          onClose={() => {
            setSentModal(false);
          }}
        />
      )}
    </>
  ) : null;
}
