import { customAlphabet } from "../../../common/utils/nanoid";

export const subjectNameIdFormatOptions = [
  {
    name: "persistent",
    value: "urn:oasis:names:tc:SAML:2.0:nameid-format:persistent",
  },
  {
    name: "emailAddress",
    value: "urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress",
  },
  {
    name: "unspecified",
    value: "urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified",
  },
  {
    name: "X509SubjectName",
    value: "urn:oasis:names:tc:SAML:1.1:nameid-format:X509SubjectName",
  },
  {
    name: "WindowsDomainQualifiedName",
    value: "urn:oasis:names:tc:SAML:1.1:nameid-format:WindowsDomainQualifiedName",
  },
  {
    name: "encrypted",
    value: "urn:oasis:names:tc:SAML:2.0:nameid-format:encrypted",
  },
  {
    name: "entity",
    value: "urn:oasis:names:tc:SAML:2.0:nameid-format:entity",
  },
  {
    name: "kerberos",
    value: "urn:oasis:names:tc:SAML:2.0:nameid-format:kerberos",
  },
  {
    name: "transient",
    value: "urn:oasis:names:tc:SAML:2.0:nameid-format:transient",
  },
];

export function getUUID() {
  const nanoid = customAlphabet("1234567890abcdef", 10);
  return nanoid(32);
}
