import React, { useState } from "react";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { useGetUserInfo } from "../../../admin/services/oauth2Query";
import image from "../../../assets/images/icons/upgrade/account-expired-img.svg";
import background from "../../../assets/images/icons/upgrade/background-expired.jpg";
import ConfirmationDialog from "../ConfirmationDialog";
import { UpgradeButton } from "./UpgradeButton";

const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  img: {
    width: "100%",
  },
  content: {
    marginTop: 24,
    "& > p": {
      marginTop: 16,
    },
  },
}));

export default function AccountExpiredView() {
  const { classes } = useStyles();
  const userinfoQuery = useGetUserInfo();
  const [sent, setSent] = useState(false);
  const firstName = userinfoQuery.data?.given_name || "";

  return (
    <div className={classes.container}>
      <ConfirmationDialog
        id="account-expired-modal"
        onlyExtraActions
        extraActions={
          !sent ? (
            <>
              <UpgradeButton
                onUpgradeSent={() => {
                  setSent(true);
                }}
                label="Contact us"
                isAccountExpired
              />
            </>
          ) : undefined
        }
        mode="info"
        content={
          <>
            <img src={image} alt="account expired" className={classes.img} />

            <div className={classes.content}>
              {sent ? (
                <>
                  <Typography variant="h3" textAlign="center">
                    We received your request
                  </Typography>
                  <Typography textAlign="center">
                    Thank you {firstName} for taking the next step with us! 🎉
                  </Typography>
                  <Typography textAlign="center">
                    We've received your upgrade request, and our accounts team will be in touch
                    shortly to guide you through the next steps.
                  </Typography>
                  <Typography textAlign="center">
                    In the meantime, if you have any questions, feel free to reach out at{" "}
                    <Link href="mailto:ciamtrial@secureauth.com">ciamtrial@secureauth.com</Link>.
                    We're here to help!
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h3">Hi {firstName} 👋, your trial has expired!</Typography>
                  <Typography>
                    To continue enjoying full access to all CIAM features, along with your data and
                    configurations, please upgrade your plan.
                  </Typography>
                  <Typography>
                    In the meantime, if you have any questions, feel free to reach out at{" "}
                    <Link href="mailto:ciamtrial@secureauth.com">ciamtrial@secureauth.com</Link>.
                    We're here to help!
                  </Typography>
                </>
              )}
            </div>
          </>
        }
      />
    </div>
  );
}
