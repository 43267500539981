import React, { useState } from "react";
import { useNavigate } from "react-router";

import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useQueryClient } from "@tanstack/react-query";

import { ServerResponse, Theme } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import ConfirmationDialog from "../../../../common/components/ConfirmationDialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { listOrganizationsQueryKey } from "../../../services/adminOrganizationsQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import adminThemesApi from "../../../services/adminThemesApi";
import { listThemesQueryKey, useListThemes } from "../../../services/adminThemesQuery";
import useWorkspacesSeqOrCursor from "../../common/EnhancedTableAsync/useWorkspacesSeqOrCursor";
import EnhancedTableWithCollapsedRow from "../../common/EnhancedTableWithCollapsedRows/EnhancedTableWithCollapsedRows";
import { InnerRows } from "../../common/EnhancedTableWithCollapsedRows/Row";
import { InnerRowsProps } from "../../common/EnhancedTableWithCollapsedRows/types";
import PageContent from "../../common/PageContent";
import PageHeader from "../../common/PageHeader";
import WorkspaceAvatar from "../../nav/WorkspaceAvatar";
import { getServerTitle } from "../server-profiles";
import AppearanceToolbar from "./AppearanceToolbar";
import ThemeCreate from "./ThemeCreate";
import ThemeEdit from "./ThemeEdit";
import ThemesMenu, { ThemeMenuType } from "./ThemesMenu";
import ThemesWorkspaceMenu, { WorkspaceMenuType } from "./ThemesWorkspaceMenu";

const columns = [
  { label: "Theme Name", id: "chevron", align: "left", colSpan: 2 },
  { label: "", id: "actions", align: "center", style: { width: "5%" } },
];

function CustomInnerRows(props: InnerRowsProps) {
  const navigate = useNavigate();

  const data = useWorkspacesSeqOrCursor({
    themeId: props.rowId === "default" ? "" : props.rowId,
    ignoreUrlParams: true,
  });

  const innerRows = data.totalData.map(workspace => ({
    ...workspace,
    icon: <WorkspaceAvatar server={workspace} />,
    text: workspace.name ?? "",
    subText: getServerTitle(workspace) ?? "",
    onTextClick: () => navigate(`/${workspace.id}/appearance`),
  }));

  return data.firstPageLoading ? (
    <div style={{ display: "flex", justifyContent: "center", padding: 24 }}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <InnerRows {...props} innerRows={innerRows} />
      {!innerRows.length && <div style={{ color: "gray", padding: 8 }}>No workspaces</div>}
      {data.nextPageAvailable && (
        <LoadingButton
          onClick={data.onLastPage}
          loading={data.isFetching}
          variant="outlined"
          style={{ margin: "8px 16px" }}
        >
          Load more
        </LoadingButton>
      )}
    </>
  );
}

export default function Appearance() {
  const [searchText, setSearchText] = useState("");
  const [mainMenu, setMainMenu] = useState<ThemeMenuType | null>(null);
  const [innerRowMenu, setInnerRowMenu] = useState<WorkspaceMenuType | null>(null);

  const navigate = useNavigate();
  const [createDialog, setCreateDialog] = useState<{ sourceTheme: Theme | undefined } | null>(null);
  const [editDialog, setEditDialog] = useState<Theme | null>(null);

  const [deleteProgress, setDeleteProgress] = useState("");
  const [deleteDialog, setDeleteDialog] = useState<{ theme: Theme } | null>(null);

  const queryClient = useQueryClient();
  const themesQuery = useListThemes(getTenantId());
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const handleDeleteTheme = () => {
    setDeleteProgress(deleteDialog?.theme.id || "");
    adminThemesApi
      .deleteTheme({ themeID: deleteDialog?.theme.id || "" })
      .then(() => queryClient.invalidateQueries({ queryKey: listThemesQueryKey(getTenantId()) }))
      .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
      .then(() => notifySuccess("Theme successfully deleted"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to delete theme"))
      .finally(() => {
        setDeleteProgress("");
        setDeleteDialog(null);
      });
  };

  const data = [
    { id: "default", name: "Default", logo_url: "" },
    ...(themesQuery.data?.themes || []),
  ];
  const filteredData = data.filter(theme =>
    (theme.name || "").toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
  );

  return (
    <>
      <PageHeader title="Themes" />
      <PageContent>
        <AppearanceToolbar
          searchText={searchText}
          onUpdate={text => setSearchText(text)}
          onCreate={
            checkTenantPermissionsQuery.data?.manage_themes
              ? () => setCreateDialog({ sourceTheme: undefined })
              : undefined
          }
        />

        <EnhancedTableWithCollapsedRow
          id="themes-table"
          data={filteredData
            .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
            .map(theme => ({
              ...theme,
              icon: theme.logo_url ? (
                <img src={theme.logo_url} alt="theme" style={{ width: 40, height: 40 }} />
              ) : theme.name ? (
                <Avatar style={{ backgroundColor: "rgb(93, 176, 130)" }}>
                  {(theme.name || "")[0].toUpperCase()}
                </Avatar>
              ) : (
                <div style={{ width: 40, height: 40 }} />
              ),
              InnerRows: CustomInnerRows,
            }))}
          headCells={columns}
          setMainMenu={({ anchorEl, row }) => setMainMenu({ anchorEl, theme: row as Theme })}
          setInnerRowMenu={({ anchorEl, parentRow, row }) =>
            setInnerRowMenu({ anchorEl, theme: parentRow as Theme, server: row as ServerResponse })
          }
          rowProgress=""
          deleteRowProgress={deleteProgress}
          innerTableHeader="Workspaces"
          onNameClick={data => navigate(`/appearance/themes/editor/${data?.id}`)}
        />
      </PageContent>

      {mainMenu && (
        <ThemesMenu
          themeMenu={mainMenu}
          handleClose={() => setMainMenu(null)}
          onEditHTML={theme => navigate(`/appearance/themes/editor/${theme?.id}`)}
          onEditTheme={theme => {
            setEditDialog(theme);
            setMainMenu(null);
          }}
          onDeleteClick={() => {
            setDeleteDialog({ theme: mainMenu.theme });
            setMainMenu(null);
          }}
          onDuplicateTheme={theme => {
            setCreateDialog({ sourceTheme: theme });
            setMainMenu(null);
          }}
        />
      )}

      {innerRowMenu && (
        <ThemesWorkspaceMenu
          menu={innerRowMenu}
          handleClose={() => setInnerRowMenu(null)}
          onEditHTML={() => navigate(`/${innerRowMenu?.server.id}/appearance`)}
        />
      )}

      {createDialog && (
        <ThemeCreate
          sourceTheme={createDialog.sourceTheme}
          onClose={() => setCreateDialog(null)}
          onCreated={() => setCreateDialog(null)}
        />
      )}

      {editDialog && (
        <ThemeEdit
          theme={editDialog}
          onClose={() => setEditDialog(null)}
          onUpdated={() => setEditDialog(null)}
        />
      )}

      {deleteDialog && (
        <ConfirmationDialog
          title="Delete Theme"
          content={
            <>
              You're about to delete the <b>{deleteDialog.theme.name}</b> theme. This cannot be
              undone. Delete anyway?
            </>
          }
          confirmText="Delete"
          onCancel={() => setDeleteDialog(null)}
          onConfirm={handleDeleteTheme}
          progress={!!deleteProgress}
        />
      )}
    </>
  );
}
