import { getServerId, getTenantId, isDeveloperApp } from "../api/paths";

const APP_LOCALSTORAGE_PREFIX = `${getTenantId()}_${
  isDeveloperApp() ? "developer_" : ""
}${getServerId()}`;

export const getFromLocalStorage = (key: string) => {
  try {
    return localStorage.getItem(`${APP_LOCALSTORAGE_PREFIX}_${key}`) || undefined;
  } catch (e) {
    return undefined;
  }
};

export const setInLocalStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(`${APP_LOCALSTORAGE_PREFIX}_${key}`, value);
  } catch (e) {
    //
  }
};

export const removeFromLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(`${APP_LOCALSTORAGE_PREFIX}_${key}`);
  } catch (e) {
    //
  }
};
