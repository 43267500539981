import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import Grid from "@mui/material/Grid";
import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";

import InfiniteScrollLoading from "../admin/components/common/EnhancedTableAsync/InfiniteScrollLoading";
import { useInfiniteScrollForItemsWithQuery } from "../admin/components/common/EnhancedTableAsync/useItemsWithQuery";
import OrganizationCard from "../admin/components/workspaceDirectory/organizations/OrganizationCard";
import {
  useListAllOrganizations,
  useListAllUserOrganizations,
} from "../admin/services/adminOrganizationsQuery";
import { useCheckTenantPermissions } from "../admin/services/adminTenantsQuery";
import Progress from "../common/components/Progress";
import { useFeature } from "../user/services/userEnvironmentQuery";
import B2BOrganizationsEmptyState from "./B2BOrganizationsEmptyState";
import B2BOrganizationsToolbar from "./B2BOrganizationsToolbar";

function useListOrganizations(searchPhrase: string) {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListOrganizations = checkTenantPermissionsQuery?.data?.list_organizations;
  const fetchAll = useFeature("b2b_orgs_client_side_search");

  const allOrganizationsInCardsMode = useListAllOrganizations(
    { template: false, searchPhrase },
    { enabled: checkTenantPermissionsQuery.isSuccess && canListOrganizations }
  );

  const userOrganizationsInCardsMode = useListAllUserOrganizations(
    { fetchAll },
    { enabled: checkTenantPermissionsQuery.isSuccess && !canListOrganizations }
  );

  const organizationsInCardsMode = canListOrganizations
    ? allOrganizationsInCardsMode
    : userOrganizationsInCardsMode;

  const organizations = uniqBy(
    flatten((organizationsInCardsMode.data?.pages || []).map(page => page.organizations || [])),
    w => w.id
  );

  const filteredOrganizations =
    fetchAll && !canListOrganizations && searchPhrase
      ? organizations.filter(organization =>
          organization.name?.toLowerCase().includes(searchPhrase.toLowerCase())
        )
      : organizations;

  return {
    checkTenantPermissionsQuery,
    canListOrganizations,
    fetchAll,
    organizationsInCardsMode: canListOrganizations
      ? allOrganizationsInCardsMode
      : userOrganizationsInCardsMode,
    organizations: filteredOrganizations,
  };
}

export default function B2BOrganizationsCards({ onMenuOpen, onCreate, onSetGridMode }) {
  const navigate = useNavigate();

  const [searchPhrase, setSearchPhrase] = useState("");
  const {
    checkTenantPermissionsQuery,
    canListOrganizations,
    fetchAll,
    organizationsInCardsMode,
    organizations,
  } = useListOrganizations(searchPhrase);

  const { onScroll } = useInfiniteScrollForItemsWithQuery({
    onLastPage: () => {
      if (
        !organizationsInCardsMode.isLoading &&
        organizationsInCardsMode.hasNextPage &&
        !organizationsInCardsMode.isFetchingNextPage
      ) {
        organizationsInCardsMode.fetchNextPage();
      }
    },
  });

  useEffect(() => {
    onScroll();
  }, [onScroll, organizationsInCardsMode.data]);

  const isEmpty = !organizations.length && !searchPhrase;

  useEffect(() => {
    if (
      organizationsInCardsMode.isSuccess &&
      isEmpty &&
      checkTenantPermissionsQuery.isSuccess &&
      !checkTenantPermissionsQuery.data?.create_organization
    ) {
      navigate("/profile");
    }
  }, [
    isEmpty,
    organizationsInCardsMode,
    checkTenantPermissionsQuery.isSuccess,
    checkTenantPermissionsQuery.data,
    navigate,
  ]);

  const progress = organizationsInCardsMode.isLoading || checkTenantPermissionsQuery.isLoading;

  if (progress) {
    return <Progress />;
  }

  return (
    <>
      {isEmpty && <B2BOrganizationsEmptyState onCreate={onCreate} />}
      {!isEmpty && (
        <B2BOrganizationsToolbar
          searchText={searchPhrase}
          gridMode="cards"
          onUpdate={
            canListOrganizations || fetchAll
              ? text => {
                  setSearchPhrase(text);
                }
              : undefined
          }
          onCreate={onCreate}
          onSetGridMode={onSetGridMode}
        />
      )}
      {!isEmpty && (
        <Grid container spacing={3}>
          {organizations.length ? (
            organizations.map(organization => (
              <Grid item xs={12} sm={12} md={6} lg={3} key={organization.id}>
                <OrganizationCard
                  server={organization}
                  onSelectServer={() => navigate(`/organizations/${organization.id}`)}
                  onMenuOpen={onMenuOpen}
                />
              </Grid>
            ))
          ) : (
            <Grid item>
              <div style={{ color: "gray" }}>No organizations found</div>
            </Grid>
          )}

          <InfiniteScrollLoading isFetching={organizationsInCardsMode.isFetchingNextPage} />
        </Grid>
      )}
    </>
  );
}
