import React, { useMemo, useState } from "react";

import Editor, { loader } from "@monaco-editor/react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import isEqual from "lodash/isEqual";
import { makeStyles } from "tss-react/mui";

import { System } from "@cloudentity/acp-permissions";

import RouteLeavingGuardSimple from "../../../../common/components/RouteLeavingGuardSimple";
import {
  notifyError,
  notifyErrorOrDefaultTo,
} from "../../../../common/components/notifications/notificationService";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";

loader.config({
  paths: {
    vs: `${document.baseURI}assets/monaco/min/vs`,
  },
});

const useStyles = makeStyles()(theme => ({
  editorContainer: {
    border: "1px solid #D5D7D9",
    borderRadius: 4,
    padding: 1,
  },
  editor: {
    ".margin": {
      backgroundColor: "#F2F3F4 !important",
      ".line-numbers": {
        fontWeight: "bold",
      },
    },
  },
  labels: {
    paddingTop: 8,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  error: {
    color: theme.palette.error.main,
    lineHeight: "16px",
    marginTop: 4,
  },
}));

interface Props {
  system: System;
  onUpdate: (schema: string) => Promise<void>;
}

function PermissionSchema({ system, onUpdate }: Props) {
  const { classes } = useStyles();
  const [errors, setErrors] = useState<any>();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const originalSchema = useMemo(() => system.schema, [system]);

  const [schema, setSchema] = useState(system.schema ?? "");

  const isEdited = () => {
    try {
      return !isEqual(originalSchema, schema);
    } catch {
      return true;
    }
  };

  const handleSave = () => {
    setErrors("");
    onUpdate(schema).catch(e => {
      if (e.response?.data?.status_code === 422 && e.response?.data?.details) {
        setErrors(e.response.data.details);
        notifyError("Error occurred while trying to update schema");
      } else {
        notifyErrorOrDefaultTo("Error occurred while trying to update schema")(e);
      }
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Paper style={{ padding: 32 }}>
            <Typography variant="textMD" component="div" style={{ marginBottom: 16 }}>
              To learn more about the Schema Language read{" "}
              <Link
                href="https://docs.authzed.com/reference/schema-lang"
                target="_blank"
                rel="noopener noreferrer"
              >
                the specification
              </Link>
            </Typography>
            <div className={classes.editorContainer}>
              <Editor
                options={{
                  minimap: {
                    enabled: false,
                  },
                  scrollBeyondLastLine: false,
                  folding: false,
                  lineNumbersMinChars: 3,
                  readOnly: !checkTenantPermissionsQuery.data?.manage_permission_systems,
                }}
                height="400px"
                value={schema}
                onChange={v => setSchema(v ?? "")}
                wrapperProps={{
                  id: "permission-system-schema-editor",
                }}
                className={classes.editor}
              />
            </div>
            {errors && (
              <Typography variant="caption" component="div" className={classes.error}>
                Failed to compile schema.{" "}
                {errors.BaseMessage && errors.ErrorSourceCode
                  ? `${errors.BaseMessage}. Invalid token:\u00A0${errors.ErrorSourceCode}`
                  : JSON.stringify(errors)}
              </Typography>
            )}
            {checkTenantPermissionsQuery.data?.manage_permission_systems && (
              <div className={classes.labels} id="permission-system-schema-footer">
                <Button
                  id="permission-system-schema-save-button"
                  variant="contained"
                  style={{ marginTop: 8 }}
                  disabled={!schema || !isEdited()}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            )}
          </Paper>
        </Grid>
        <RouteLeavingGuardSimple when={isEdited()} />
      </Grid>
    </>
  );
}

export default PermissionSchema;
