let customNanoId: typeof import("nanoid") | typeof import("nanoid/non-secure");

if (process.env.NODE_ENV === "development") {
  customNanoId = require("nanoid/non-secure") as typeof import("nanoid/non-secure");
} else {
  customNanoId = require("nanoid") as typeof import("nanoid");
}

export const nanoid = customNanoId.nanoid;
export const customAlphabet = customNanoId.customAlphabet;
