import React, { useState } from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import { OrganizationResponse } from "@cloudentity/acp-admin";

import SelectablePaper from "../../../../common/components/SelectablePaper";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import { listOrganizationsQueryKey } from "../../../services/adminOrganizationsQuery";
import { listWorkspacesQueryKey } from "../../../services/adminServersQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import CardWithIconAndTitle from "../../common/CardWithIconAndTitle";
import { UseItemsReturnType } from "../../common/EnhancedTableAsync/useItemsWithQuery";
import TenantAddOrganization from "../../organizations/TenantAddOrganization";
import OrganizationsToolbar from "../../workspaceDirectory/organizations/OrganizationsToolbar";
import { providers } from "../identities.utils";

const useStyles = makeStyles()(theme => ({
  header: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.secondary.dark,
  },
}));

interface Props {
  data: UseItemsReturnType<OrganizationResponse>;
  organizationIDsWithConnectedIDPs: (string | undefined)[];
  selected: OrganizationResponse | null;
  setSelected: (selectedOrganization: OrganizationResponse | null) => void;
  onSubmit: (data: any) => void;
  onCancel: (() => void) | undefined;
  customSubmit: boolean;
}

export default function IdentitiesConfigurationOrganizationSelectExisting({
  data,
  organizationIDsWithConnectedIDPs,
  selected,
  setSelected,
  onSubmit,
  onCancel,
  customSubmit,
}: Props) {
  const { classes } = useStyles();

  const [createOrganizationDialog, setCreateOrganizationDialog] = useState(false);
  const queryClient = useQueryClient();

  const providerMapData = providers.find(p => p.method === "identity_pool");
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const isEmpty = !data.totalData.length && !data.areFiltersApplied && !data.searchText;

  return (
    <>
      <Typography className={classes.header}>Select organization</Typography>

      <div style={{ marginTop: 24 }}>
        <OrganizationsToolbar
          createButtonLabel="Create Organization"
          searchText={data.searchText}
          onUpdate={text => {
            data.onFiltersUpdate(text, data.filters);
            setSelected(null);
          }}
          customButton={
            checkTenantPermissionsQuery.data?.create_organization ? (
              <Button
                id="create-organization-button"
                onClick={() => setCreateOrganizationDialog(true)}
                style={{ fontSize: 12, fontWeight: 700 }}
              >
                + CREATE ORGANIZATION
              </Button>
            ) : undefined
          }
          searchDisabled={isEmpty}
        />
      </div>

      {data.totalData.length === 0 ? (
        <Typography style={{ padding: "16px 0" }}>No organizations</Typography>
      ) : (
        <Grid container spacing={2} style={{ marginBottom: 32 }}>
          {data.totalData.map(organization => {
            const isConnected = organizationIDsWithConnectedIDPs.includes(organization.id);
            return (
              <Grid item xs={4} key={organization.id}>
                <SelectablePaper
                  selected={selected?.id === organization.id}
                  onSelect={() => setSelected(organization)}
                  selectable={!isConnected}
                >
                  <CardWithIconAndTitle
                    img={providerMapData?.icon}
                    title={organization.name || organization.id || ""}
                    id={`organization-${organization.id}`}
                    noBorder
                    titleStyle={{ fontWeight: 600 }}
                    subtitle={isConnected ? "Already connected" : ""}
                    subtitleStyle={{
                      fontSize: 8,
                      maxWidth: "calc(100% - 28px)",
                      lineHeight: "10px",
                    }}
                    style={isConnected ? { backgroundColor: "#F2F3F4" } : {}}
                  />
                </SelectablePaper>
              </Grid>
            );
          })}

          {data.nextPageAvailable && (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Button
                  id="load-more-button"
                  variant="outlined"
                  onClick={() => data.onLastPage()}
                  loading={data.isFetching}
                >
                  Load more
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      {!customSubmit && (
        <>
          <Divider style={{ marginBottom: 48 }} />

          <FormFooter
            submitText="Next"
            onSubmit={onSubmit}
            onCancel={onCancel}
            disabled={!selected}
          />
        </>
      )}
      {createOrganizationDialog && (
        <TenantAddOrganization
          onCancel={() => {
            setCreateOrganizationDialog(false);
          }}
          onCreated={org => {
            return queryClient
              .invalidateQueries({ queryKey: listOrganizationsQueryKey() })
              .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
              .then(() => {
                setSelected({ id: org.id, name: org.name });
              });
          }}
          onSkip={() => setCreateOrganizationDialog(false)}
          onInviteSent={() => setCreateOrganizationDialog(false)}
        />
      )}
    </>
  );
}
