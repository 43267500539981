import { useCallback, useEffect, useState } from "react";

import { handleErrorWithNotify } from "../../../../common/components/notifications/notificationService";
import { getParameterByName, updateQueryParam } from "../../../../common/utils/query.utils";
import { getTableLimitInLocalStorage } from "../EnhancedTable";
import { Filters, FiltersSetup } from "./FiltersToolbar";

const INITIAL_SORT = "name";
const INITIAL_LIMIT = 10;

export type UseItemsReturnType<T> = {
  totalItems: T[];
  items: T[];
  itemsProgress: boolean;
  sort: string;
  order: "asc" | "desc";
  page: number;
  limit: number;
  nextPageAvailable: boolean;
  onResetPage: () => void;
  onPaginationUpdate: (
    newSort: string,
    newOrder: "asc" | "desc",
    newPage: number,
    newLimit: number
  ) => void;
  onFiltersUpdate: (newSearchText: string, newFilters: Filters) => void;
  filters: Filters;
  areFiltersApplied: boolean;
  searchText: string;
};

export type ItemQueryParams = {
  sort: string;
  order: "asc" | "desc";
  limit: number;
  searchText: string;
  filters: Filters;
  beforeItemId?: string;
  afterItemId?: string;
};

type StoreEntry<T> = { data: T[]; nextPageAvailable: boolean };

export default function useItems<T>({
  id,
  filtersSetup,
  idGetter,
  promiseFetch,
  initialSort = INITIAL_SORT,
}: {
  id: string;
  filtersSetup: FiltersSetup;
  idGetter: (item: T) => string | undefined;
  promiseFetch: (params: ItemQueryParams) => Promise<T[]>;
  initialSort?: string;
}): UseItemsReturnType<T> {
  const [itemsProgress, setItemsProgress] = useState(true);

  const [items, setItems] = useState<StoreEntry<T>>({ data: [], nextPageAvailable: false });
  const [store, setStore] = useState<{
    page: number;
    data: { [page: number]: StoreEntry<T> };
    queryParams: ItemQueryParams;
  }>({
    page: 0,
    data: {},
    queryParams: {
      sort: getParameterByName("sort") ?? initialSort,
      order: getParameterByName("order") === "desc" ? "desc" : "asc",
      limit: parseInt(
        getParameterByName("limit") || getTableLimitInLocalStorage(id) || INITIAL_LIMIT.toString()
      ),
      searchText: getParameterByName("search") ?? "",
      filters: filtersSetup.reduce(
        (acc, curr) => ({ ...acc, [curr.key]: (curr.type === "checkbox" && []) || "" }),
        {}
      ),
    },
  });

  const areFiltersApplied = Object.values(store.queryParams.filters).some(v =>
    typeof v === "string" ? v !== "" : v.length !== 0
  );

  useEffect(() => {
    if (store.data[store.page]) {
      setItems(store.data[store.page]);
      setItemsProgress(false);
      return;
    }

    setItemsProgress(true);
    promiseFetch({ ...store.queryParams, limit: store.queryParams.limit + 1 })
      .then(newItems => {
        setStore({
          ...store,
          data: {
            ...store.data,
            [store.page]: {
              data: newItems.slice(0, store.queryParams.limit),
              nextPageAvailable: newItems.length > store.queryParams.limit,
            },
          },
        });
      })
      .catch(handleErrorWithNotify("Error occurred while fetching items."))
      .finally(() => setItemsProgress(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  const handlePaginationUpdate = (
    newSort: string,
    newOrder: "asc" | "desc",
    newPage: number,
    newLimit: number
  ) => {
    if (
      store.queryParams.order !== newOrder ||
      store.queryParams.sort !== newSort ||
      store.queryParams.limit !== newLimit
    ) {
      setStore(store => ({
        page: 0,
        data: {},
        queryParams: {
          sort: newSort,
          order: newOrder,
          limit: newLimit,
          searchText: store.queryParams.searchText,
          filters: store.queryParams.filters,
        },
      }));
    } else {
      const items = store.data[store.page];
      const firstItem = items.data[0];
      const lastItem = items.data[items.data.length - 1];
      const beforeItemId = newPage < store.page && firstItem ? idGetter(firstItem) : undefined;
      const afterItemId = newPage > store.page && lastItem ? idGetter(lastItem) : undefined;

      setStore(store => ({
        ...store,
        page: newPage,
        queryParams: {
          sort: newSort,
          order: newOrder,
          limit: newLimit,
          searchText: store.queryParams.searchText,
          filters: store.queryParams.filters,
          beforeItemId,
          afterItemId,
        },
      }));
    }
  };

  const handleFiltersUpdate = useCallback((newSearchText: string, newFilters: Filters) => {
    setStore(store => ({
      page: 0,
      data: {},
      queryParams: {
        sort: store.queryParams.sort,
        order: store.queryParams.order,
        limit: store.queryParams.limit,
        searchText: newSearchText,
        filters: newFilters,
      },
    }));
  }, []);

  const handleResetPage = () => {
    setItems({ data: [], nextPageAvailable: false });
    setStore(store => ({
      page: 0,
      data: {},
      queryParams: {
        sort: store.queryParams.sort,
        order: store.queryParams.order,
        limit: store.queryParams.limit,
        searchText: store.queryParams.searchText,
        filters: store.queryParams.filters,
      },
    }));
  };

  useEffect(() => {
    if (Object.keys(store.data).length) {
      handleResetPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promiseFetch]);

  useEffect(() => {
    updateQueryParam("page", store.page);
    updateQueryParam("sort", store.queryParams.sort);
    updateQueryParam("order", store.queryParams.order);
    updateQueryParam("limit", store.queryParams.limit);
    updateQueryParam("search", store.queryParams.searchText);
  }, [store]);

  return {
    totalItems: Object.entries(store.data)
      .sort(([page1], [page2]) => Number(page1) - Number(page2))
      .flatMap(([, items]) => items.data),
    items: items.data,
    itemsProgress,
    sort: store.queryParams.sort,
    order: store.queryParams.order,
    limit: store.queryParams.limit,
    page: store.page,
    nextPageAvailable: items.nextPageAvailable,
    onResetPage: handleResetPage,
    onPaginationUpdate: handlePaginationUpdate,
    onFiltersUpdate: handleFiltersUpdate,
    filters: store.queryParams.filters,
    areFiltersApplied,
    searchText: store.queryParams.searchText,
  };
}
