import React from "react";

import { QueryClient } from "@tanstack/react-query";
import formatDistance from "date-fns/formatDistance";

import {
  GrantIdentityPoolRoleRequestRoleEnum,
  GrantIdentityPoolRoleRequestTypeEnum,
  GrantTenantRoleRequestRoleEnum,
  GrantWorkspaceRoleRequestRoleEnum,
  GrantWorkspaceRoleRequestTypeEnum,
  IdentityPoolRoleSubject,
  RevokeIdentityPoolRoleRequestRoleEnum,
  RevokeIdentityPoolRoleRequestTypeEnum,
  RevokeTenantRoleRequestRoleEnum,
  RevokeWorkspaceRoleRequestRoleEnum,
  RevokeWorkspaceRoleRequestTypeEnum,
  TenantRoleSubject,
  WorkspaceRoleSubject,
} from "@cloudentity/acp-admin";
import { BaseUserWithData, ExtendedUser, UserWithData } from "@cloudentity/acp-identity";

import { getTenantId, portalMode } from "../../../../../../../common/api/paths";
import { getFromLocalStorage } from "../../../../../../../common/utils/localStorage.utils";
import adminRolesApi from "../../../../../../services/adminRolesApi";
import {
  listIdentityPoolRoles,
  listUserRoles,
  listWorkspaceRoles,
} from "../../../../../../services/adminRolesQuery";
import { MoreIdentifiers } from "./IdentityPoolUserCellComponents";

export function getColumnsStorageKey(poolId: string) {
  return `pool-${poolId}-users-table-columns`;
}

export function getColumnsFromStorage(poolId: string, allColumns: string[]) {
  try {
    const activeColumnsFromStore = JSON.parse(
      getFromLocalStorage(getColumnsStorageKey(poolId) as any) as any
    );
    return activeColumnsFromStore.filter(column => allColumns.includes(column));
  } catch {
    return null;
  }
}

export function getFormattedDate(date: string) {
  try {
    return formatDistance(new Date(date), new Date(), { addSuffix: true });
  } catch {
    return date;
  }
}

export const getUserRolesDisplayNames = (
  user?: ExtendedUser | UserWithData,
  tenantSubjects: TenantRoleSubject[] = [],
  workspaceSubjects: WorkspaceRoleSubject[] = [],
  identityPoolSubjects: IdentityPoolRoleSubject[] = [],
  isOrganization = false
) => {
  const userRoles = getUserRolesBySubjects(
    user,
    tenantSubjects,
    workspaceSubjects,
    identityPoolSubjects
  );

  const rolesArray = [
    ...userRoles.tenant.map(ur => tenantRoleToDisplayRole(ur)),
    ...userRoles.workspace.map(ur => workspaceRoleToDisplayRole(ur, isOrganization)),
    ...userRoles.identityPool.map(ur => identityPoolRoleToDisplayRole(ur)),
  ];

  return rolesArray.length ? (
    <div>
      <span>{rolesArray[0]}</span>
      {rolesArray.length > 1 && <MoreIdentifiers identifiers={rolesArray.slice(1)} />}
    </div>
  ) : null;
};

export type GetUserRolesBySubjectsReturnType = {
  tenant: GrantTenantRoleRequestRoleEnum[];
  workspace: GrantWorkspaceRoleRequestRoleEnum[];
  identityPool: GrantIdentityPoolRoleRequestRoleEnum[];
};

export function getUserRolesBySubjects(
  user?: ExtendedUser | UserWithData | BaseUserWithData,
  tenantSubjects: TenantRoleSubject[] = [],
  workspaceSubjects: WorkspaceRoleSubject[] = [],
  identityPoolSubjects: IdentityPoolRoleSubject[] = []
): GetUserRolesBySubjectsReturnType {
  return {
    tenant: getTenantRoles(user, tenantSubjects),
    workspace: getWorkspaceRoles(user, workspaceSubjects),
    identityPool: getIdentityPoolRoles(user, identityPoolSubjects),
  };
}

const getTenantRoles = (
  user?: ExtendedUser | UserWithData | BaseUserWithData,
  tenantSubjects: TenantRoleSubject[] = []
) => {
  const tenantSubject = tenantSubjects.find(
    s => s.identity_pool_id === user?.user_pool_id && s.identity_pool_user_id === user?.id
  );

  return [
    ...(tenantSubject && tenantSubject.roles?.admin ? [GrantTenantRoleRequestRoleEnum.Admin] : []),
    ...(tenantSubject && tenantSubject.roles?.business_admin
      ? [GrantTenantRoleRequestRoleEnum.BusinessAdmin]
      : []),
    ...(tenantSubject && tenantSubject.roles?.auditor
      ? [GrantTenantRoleRequestRoleEnum.Auditor]
      : []),
    ...(tenantSubject && tenantSubject.roles?.member
      ? [GrantTenantRoleRequestRoleEnum.Member]
      : []),
  ];
};

const getWorkspaceRoles = (
  user?: ExtendedUser | UserWithData | BaseUserWithData,
  workspaceSubjects: WorkspaceRoleSubject[] = []
) => {
  const workspaceSubject = workspaceSubjects.find(
    s => s.identity_pool_id === user?.user_pool_id && s.identity_pool_user_id === user?.id
  );

  return [
    ...(workspaceSubject && workspaceSubject.roles?.admin
      ? [GrantWorkspaceRoleRequestRoleEnum.Admin]
      : []),
    ...(workspaceSubject && workspaceSubject.roles?.auditor
      ? [GrantWorkspaceRoleRequestRoleEnum.Auditor]
      : []),
    ...(workspaceSubject && workspaceSubject.roles?.user_manager
      ? [GrantWorkspaceRoleRequestRoleEnum.UserManager]
      : []),
    ...(workspaceSubject && workspaceSubject.roles?.manager
      ? [GrantWorkspaceRoleRequestRoleEnum.Manager]
      : []),
  ];
};

const getIdentityPoolRoles = (
  user?: ExtendedUser | UserWithData | BaseUserWithData,
  identityPoolSubjects: IdentityPoolRoleSubject[] = []
) => {
  const identityPoolSubject = identityPoolSubjects.find(
    s => s.identity_pool_id === user?.user_pool_id && s.identity_pool_user_id === user?.id
  );

  return [
    ...(identityPoolSubject && identityPoolSubject.roles?.user_manager
      ? [GrantIdentityPoolRoleRequestRoleEnum.UserManager]
      : []),
  ];
};

export function getUserRole(user: ExtendedUser | UserWithData, subjects) {
  const subject = subjects.find(
    s => s.identity_pool_id === user?.user_pool_id && s.identity_pool_user_id === user?.id
  );

  if (subject && subject.roles?.admin) {
    return GrantTenantRoleRequestRoleEnum.Admin;
  }

  if (subject && subject.roles?.business_admin) {
    return GrantTenantRoleRequestRoleEnum.BusinessAdmin;
  }

  if (subject && subject.roles?.auditor) {
    return GrantTenantRoleRequestRoleEnum.Auditor;
  }

  if (subject && subject.roles?.member) {
    return GrantTenantRoleRequestRoleEnum.Member;
  }

  return "";
}

export function tenantRoleToDisplayRole(
  role: GrantTenantRoleRequestRoleEnum | RevokeTenantRoleRequestRoleEnum | ""
) {
  switch (role) {
    case GrantTenantRoleRequestRoleEnum.Admin: {
      return "Super Admin";
    }
    case GrantTenantRoleRequestRoleEnum.BusinessAdmin: {
      return "Business Admin";
    }
    case GrantTenantRoleRequestRoleEnum.Auditor: {
      return "Auditor";
    }
    case GrantTenantRoleRequestRoleEnum.Member: {
      return "Member";
    }

    default: {
      return role;
    }
  }
}

export function workspaceRoleToDisplayRole(
  role: GrantWorkspaceRoleRequestRoleEnum | RevokeWorkspaceRoleRequestRoleEnum,
  isOrganization
) {
  switch (role) {
    case GrantWorkspaceRoleRequestRoleEnum.Admin: {
      return portalMode === "b2b" ? "Organization Admin" : "Admin";
    }
    case GrantWorkspaceRoleRequestRoleEnum.Auditor: {
      return "Auditor";
    }
    case GrantWorkspaceRoleRequestRoleEnum.UserManager: {
      return "User Manager";
    }
    case GrantWorkspaceRoleRequestRoleEnum.Manager: {
      return portalMode === "b2b"
        ? "Admin"
        : isOrganization
          ? "Organization Admin"
          : "Workspace Admin";
    }
    case GrantWorkspaceRoleRequestRoleEnum.Member: {
      return "Member";
    }
    default: {
      return role;
    }
  }
}

function identityPoolRoleToDisplayRole(
  role: GrantIdentityPoolRoleRequestRoleEnum | RevokeIdentityPoolRoleRequestRoleEnum
) {
  switch (role) {
    case GrantIdentityPoolRoleRequestRoleEnum.UserManager: {
      return "User Population Manager";
    }

    default: {
      return role;
    }
  }
}

export function grantWorkspaceRoles(
  queryClient: QueryClient,
  workspaceId: string,
  user: UserWithData | BaseUserWithData,
  roles: string[]
) {
  const common = {
    identity_pool_id: user.user_pool_id,
    identity_pool_user_id: user.id,
    tenant_id: getTenantId(),
  };

  const grantPromises = [
    () =>
      adminRolesApi.grantWorkspaceRole({
        wid: workspaceId,
        request: {
          role: GrantWorkspaceRoleRequestRoleEnum.Member,
          type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
          ...common,
        },
      }),
    ...(roles.includes("admin")
      ? [
          () =>
            adminRolesApi.grantWorkspaceRole({
              wid: workspaceId,
              request: {
                role: GrantWorkspaceRoleRequestRoleEnum.Admin,
                type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
                ...common,
              },
            }),
        ]
      : []),
    ...(roles.includes("auditor")
      ? [
          () =>
            adminRolesApi.grantWorkspaceRole({
              wid: workspaceId,
              request: {
                role: GrantWorkspaceRoleRequestRoleEnum.Auditor,
                type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
                ...common,
              },
            }),
        ]
      : []),
    ...(roles.includes("user_manager")
      ? [
          () =>
            adminRolesApi.grantWorkspaceRole({
              wid: workspaceId,
              request: {
                role: GrantWorkspaceRoleRequestRoleEnum.UserManager,
                type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
                ...common,
              },
            }),
        ]
      : []),
    ...(roles.includes("manager")
      ? [
          () =>
            adminRolesApi.grantWorkspaceRole({
              wid: workspaceId,
              request: {
                role: GrantWorkspaceRoleRequestRoleEnum.Manager,
                type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
                ...common,
              },
            }),
        ]
      : []),
    ...(roles.includes("team_manager")
      ? [
          () =>
            adminRolesApi.grantIdentityPoolRole({
              ipID: user.user_pool_id,
              request: {
                role: GrantIdentityPoolRoleRequestRoleEnum.UserManager,
                type: GrantIdentityPoolRoleRequestTypeEnum.IdentityPoolUser,
                identity_pool_id: user.user_pool_id,
                identity_pool_user_id: user.id,
                tenant_id: getTenantId(),
              },
            }),
        ]
      : []),
  ];

  return Promise.all(grantPromises.map(p => p()))
    .then(() => queryClient.invalidateQueries({ queryKey: listWorkspaceRoles(workspaceId) }))
    .then(() =>
      queryClient.invalidateQueries({ queryKey: listUserRoles(user.user_pool_id, user.id!) })
    )
    .then(() =>
      queryClient.invalidateQueries({ queryKey: listIdentityPoolRoles(user.user_pool_id) })
    );
}

export function revokeRolePromises() {}

export function grantAndRevokeWorkspaceRoles(
  queryClient: QueryClient,
  workspaceId: string,
  user: UserWithData | BaseUserWithData,
  userRolesBySubjects: {
    workspace: GrantWorkspaceRoleRequestRoleEnum[];
    identityPool: GrantIdentityPoolRoleRequestRoleEnum[];
  },
  roles: string[]
) {
  const tenantId = getTenantId();

  const common = {
    identity_pool_id: user.user_pool_id,
    identity_pool_user_id: user.id,
    tenant_id: tenantId,
  };

  const revokePromises = roles
    ? [
        ...(userRolesBySubjects.workspace.includes(GrantWorkspaceRoleRequestRoleEnum.Admin) &&
        !roles.includes("admin")
          ? [
              () =>
                adminRolesApi.revokeWorkspaceRole({
                  wid: workspaceId,
                  request: {
                    role: RevokeWorkspaceRoleRequestRoleEnum.Admin,
                    type: RevokeWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
                    ...common,
                  },
                }),
            ]
          : []),
        ...(userRolesBySubjects.workspace.includes(GrantWorkspaceRoleRequestRoleEnum.Auditor) &&
        !roles.includes("auditor")
          ? [
              () =>
                adminRolesApi.revokeWorkspaceRole({
                  wid: workspaceId,
                  request: {
                    role: RevokeWorkspaceRoleRequestRoleEnum.Auditor,
                    type: RevokeWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
                    ...common,
                  },
                }),
            ]
          : []),
        ...(userRolesBySubjects.workspace.includes(GrantWorkspaceRoleRequestRoleEnum.UserManager) &&
        !roles.includes("user_manager")
          ? [
              () =>
                adminRolesApi.revokeWorkspaceRole({
                  wid: workspaceId,
                  request: {
                    role: RevokeWorkspaceRoleRequestRoleEnum.UserManager,
                    type: RevokeWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
                    ...common,
                  },
                }),
            ]
          : []),
        ...(userRolesBySubjects.workspace.includes(GrantWorkspaceRoleRequestRoleEnum.Manager) &&
        !roles.includes("manager")
          ? [
              () =>
                adminRolesApi.revokeWorkspaceRole({
                  wid: workspaceId,
                  request: {
                    role: RevokeWorkspaceRoleRequestRoleEnum.Manager,
                    type: RevokeWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
                    ...common,
                  },
                }),
            ]
          : []),
        ...(userRolesBySubjects.identityPool.includes(
          GrantIdentityPoolRoleRequestRoleEnum.UserManager
        ) && !roles.includes("team_manager")
          ? [
              () =>
                adminRolesApi.revokeIdentityPoolRole({
                  ipID: user.user_pool_id,
                  request: {
                    role: RevokeIdentityPoolRoleRequestRoleEnum.UserManager,
                    type: RevokeIdentityPoolRoleRequestTypeEnum.IdentityPoolUser,
                    ...common,
                  },
                }),
            ]
          : []),
      ]
    : [];

  const grantPromises = roles
    ? [
        ...(roles.includes("admin") &&
        !userRolesBySubjects.workspace.includes(GrantWorkspaceRoleRequestRoleEnum.Admin)
          ? [
              () =>
                adminRolesApi.grantWorkspaceRole({
                  wid: workspaceId,
                  request: {
                    role: GrantWorkspaceRoleRequestRoleEnum.Admin,
                    type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
                    ...common,
                  },
                }),
            ]
          : []),
        ...(roles.includes("auditor") &&
        !userRolesBySubjects.workspace.includes(GrantWorkspaceRoleRequestRoleEnum.Auditor)
          ? [
              () =>
                adminRolesApi.grantWorkspaceRole({
                  wid: workspaceId,
                  request: {
                    role: GrantWorkspaceRoleRequestRoleEnum.Auditor,
                    type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
                    ...common,
                  },
                }),
            ]
          : []),
        ...(roles.includes("user_manager") &&
        !userRolesBySubjects.workspace.includes(GrantWorkspaceRoleRequestRoleEnum.UserManager)
          ? [
              () =>
                adminRolesApi.grantWorkspaceRole({
                  wid: workspaceId,
                  request: {
                    role: GrantWorkspaceRoleRequestRoleEnum.UserManager,
                    type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
                    ...common,
                  },
                }),
            ]
          : []),
        ...(roles.includes("manager") &&
        !userRolesBySubjects.workspace.includes(GrantWorkspaceRoleRequestRoleEnum.Manager)
          ? [
              () =>
                adminRolesApi.grantWorkspaceRole({
                  wid: workspaceId,
                  request: {
                    role: GrantWorkspaceRoleRequestRoleEnum.Manager,
                    type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
                    ...common,
                  },
                }),
            ]
          : []),
        ...(roles.includes("team_manager") &&
        !userRolesBySubjects.identityPool.includes(GrantIdentityPoolRoleRequestRoleEnum.UserManager)
          ? [
              () =>
                adminRolesApi.grantIdentityPoolRole({
                  ipID: user.user_pool_id,
                  request: {
                    role: GrantIdentityPoolRoleRequestRoleEnum.UserManager,
                    type: GrantIdentityPoolRoleRequestTypeEnum.IdentityPoolUser,
                    identity_pool_id: user.user_pool_id,
                    identity_pool_user_id: user.id,
                    tenant_id: tenantId,
                  },
                }),
            ]
          : []),
      ]
    : [];

  const invalidatePromises = roles
    ? [
        () => queryClient.invalidateQueries({ queryKey: listWorkspaceRoles(workspaceId) }),
        () => queryClient.invalidateQueries({ queryKey: listIdentityPoolRoles(user.user_pool_id) }),
        () =>
          queryClient.invalidateQueries({ queryKey: listUserRoles(user.user_pool_id, user.id) }),
      ]
    : [];

  return Promise.all(revokePromises.map(p => p()))
    .then(() => Promise.all(grantPromises.map(p => p())))
    .then(() => Promise.all(invalidatePromises.map(p => p())));
}
