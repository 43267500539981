import differenceInHours from "date-fns/differenceInHours";
import formatDistance from "date-fns/formatDistance";

import { License } from "@cloudentity/acp-admin";

export function getFormattedDate(date: string) {
  try {
    return formatDistance(new Date(date), new Date(), { addSuffix: true });
  } catch {
    return date;
  }
}

export function getTrialInfo(license: License | undefined) {
  const startDate = license?.start_date ?? Date.now();
  const endDate = license?.end_date ?? Date.now();
  const duration = Math.ceil(differenceInHours(new Date(endDate), new Date(startDate)) / 24);

  const endsIn = endDate ? Math.ceil(differenceInHours(new Date(endDate), Date.now()) / 24) : 0;

  return {
    isTrial: !!license?.enforcement_enabled && !!license?.end_date,
    daysLeft: endsIn < 0 ? 0 : endsIn,
    duration,
  };
}

export function getDayWord(counter: number) {
  return counter === 1 ? "day" : "days";
}
