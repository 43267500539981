import React from "react";

import { PoolResponse } from "@cloudentity/acp-identity";

import Progress from "../../../../../../../common/components/Progress";
import { useFeature } from "../../../../../../../common/utils/hooks/useFeature";
import { useGetEnvironment } from "../../../../../../services/adminEnvironmentQuery";
import { useCheckPoolPermissions } from "../../../../../../services/adminIdentityPoolsQuery";
import {
  useGetSchema,
  useGetWorkspaceSchema,
} from "../../../../../../services/adminIdentitySchemasQuery";
import {
  useCheckTenantPermissions,
  useCheckWorkspacePermissions,
} from "../../../../../../services/adminPermissionsQuery";
import { useWorkspace } from "../../../../../common/useWorkspace";
import { usePoolRootUrl } from "../../../utils";
import IdentityPoolUsers from "./IdentityPoolUsers";

interface Props {
  pool: PoolResponse;
}

export default function IdentityPoolUsersController({ pool }: Props) {
  const [workspace] = useWorkspace();

  const { mode: poolMode } = usePoolRootUrl();
  const getEnvironmentQuery = useGetEnvironment();
  const isWithRolesEnabled = useFeature("with_roles");

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace, {
    enabled: poolMode === "workspace" && !!workspace,
  });
  const checkPoolPermissionsQuery = useCheckPoolPermissions(pool?.id!);
  const canListSchemas = !!checkTenantPermissionsQuery.data?.list_identity_pools;

  const payloadSchemaQuery = useGetSchema(pool.payload_schema_id, {
    enabled: poolMode === "tenant" && canListSchemas,
  });
  const metadataSchemaQuery = useGetSchema(pool.metadata_schema_id, {
    enabled: poolMode === "tenant" && canListSchemas,
  });
  const businessMetadataSchemaQuery = useGetSchema(pool.business_metadata_schema_id, {
    enabled: poolMode === "tenant" && canListSchemas,
  });

  const payloadWorkspaceSchemaQuery = useGetWorkspaceSchema(
    pool?.workspace_id,
    pool.payload_schema_id,
    {
      enabled: poolMode === "workspace",
    }
  );
  const metadataWorkspaceSchemaQuery = useGetWorkspaceSchema(
    pool?.workspace_id,
    pool.metadata_schema_id,
    {
      enabled: poolMode === "workspace",
    }
  );
  const businessMetadataWorkspaceSchemaQuery = useGetWorkspaceSchema(
    pool?.workspace_id,
    pool.business_metadata_schema_id,
    {
      enabled: poolMode === "workspace",
    }
  );

  const isWorkspaceOrPoolsRolesVisible =
    poolMode === "workspace" &&
    isWithRolesEnabled &&
    (!!checkWorkspacePermissionsQuery.data?.read_roles ||
      !!checkPoolPermissionsQuery.data?.read_roles);

  const progress =
    payloadSchemaQuery.isFetching ||
    metadataSchemaQuery.isFetching ||
    businessMetadataSchemaQuery.isFetching ||
    payloadWorkspaceSchemaQuery.isFetching ||
    metadataWorkspaceSchemaQuery.isFetching ||
    businessMetadataWorkspaceSchemaQuery.isFetching ||
    checkTenantPermissionsQuery.isLoading ||
    checkWorkspacePermissionsQuery.isLoading ||
    checkPoolPermissionsQuery.isLoading ||
    getEnvironmentQuery.isLoading;

  return progress ? (
    <Progress isGlobalLoader />
  ) : (
    <IdentityPoolUsers
      pool={pool}
      payloadSchema={
        poolMode === "tenant"
          ? payloadSchemaQuery.data?.schema
          : payloadWorkspaceSchemaQuery.data?.schema
      }
      metadataSchema={
        poolMode === "tenant"
          ? metadataSchemaQuery.data?.schema
          : metadataWorkspaceSchemaQuery.data?.schema
      }
      businessMetadataSchema={
        poolMode === "tenant"
          ? businessMetadataSchemaQuery.data?.schema
          : businessMetadataWorkspaceSchemaQuery.data?.schema
      }
      isWorkspaceOrPoolsRolesVisible={isWorkspaceOrPoolsRolesVisible}
    />
  );
}
