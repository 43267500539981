import React, { useMemo, useRef, useState } from "react";

import Tab from "@mui/material/Tab";
import { useQueryClient } from "@tanstack/react-query";

import { Claim, ScriptExecutionPointTypeEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import { notifyErrorOrDefaultTo } from "../../../common/components/notifications/notificationService";
import { useFeatureWithWorkspaceEnv } from "../../../common/utils/hooks/useFeature";
import { getParameterByName, updateQueryParam } from "../../../common/utils/query.utils";
import adminClaimsApi from "../../services/adminClaimsApi";
import { getClaimsQueryKey, useGetClaims } from "../../services/adminClaimsQuery";
import { useGetScopes } from "../../services/adminScopesQuery";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import InfoIconWithLink from "../common/InfoIconWithLink";
import PageContainer from "../common/PageContainer";
import PageContent from "../common/PageContent";
import PageHeader from "../common/PageHeader";
import { useWorkspace } from "../common/useWorkspace";
import ConnectScriptExtension from "../identities/ConnectScriptExtension";
import { ClaimType } from "./claims/ClaimUtils";
import OAuthClaimsCreate from "./claims/OAuthClaimsCreate";
import OAuthClaimsEdit from "./claims/OAuthClaimsEdit";
import OAuthClaimsTab from "./claims/OAuthClaimsTab";
import OAuthVerifiedClaimsCreate from "./claims/OAuthVerifiedClaimsCreate";
import OAuthVerifiedClaimsEdit from "./claims/OAuthVerifiedClaimsEdit";
import OAuthVerifiedClaimsTab from "./claims/OAuthVerifiedClaimsTab";
import SamlClaimsCreate from "./claims/SamlClaimsCreate";
import SamlClaimsEdit from "./claims/SamlClaimsEdit";
import SamlClaimsTab from "./claims/SamlClaimsTab";

type InnerTabs =
  | "id_tokens"
  | "id_tokens_verified_claims"
  | "access_tokens"
  | "saml_assertion"
  | "custom_claims";

export default function OAuthClaims() {
  const isIdentityAssuranceEnabled = useFeatureWithWorkspaceEnv("identity_assurance");
  const [workspace] = useWorkspace();

  const tabs = [
    <Tab
      value="access_tokens"
      label="Access Tokens"
      id="access-tokens-claims-header"
      key="access-tokens-claims-header"
    />,
    <Tab
      value="id_tokens"
      label="ID Tokens"
      id="id-tokens-claims-header"
      key="id-tokens-claims-header"
    />,
    ...(isIdentityAssuranceEnabled
      ? [
          <Tab
            value="id_tokens_verified_claims"
            label="ID Tokens (Verified Claims)"
            id="id-tokens-verified-claims-claims-header"
            key="id-tokens-verified-claims-claims-header"
          />,
        ]
      : []),

    <Tab
      value="saml_assertion"
      label="SAML Assertion Attributes"
      id="saml-attributes-header"
      key="saml-attributes-header"
    />,
    <Tab
      value="custom_claims"
      label="Custom Claims"
      id="custom-claims-header"
      key="custom-claims-header"
    />,
  ];

  const onChangeTab = (tab: string) => {
    setTab(tab as InnerTabs);
    setHighlightedItem(undefined);
    updateQueryParam("tab", tab);
  };

  const tenantId = getTenantId();
  const getServerQuery = useGetAuthorizationServer(tenantId, workspace);
  const getClaimsQuery = useGetClaims(tenantId, workspace);
  const queryClient = useQueryClient();

  const getScopesQuery = useGetScopes(tenantId, workspace);
  const scopesNames = useMemo(
    () => getScopesQuery.data?.scopes?.map(scope => scope.name ?? "") ?? [],
    [getScopesQuery.data?.scopes]
  );

  const [progress, setProgress] = useState(false);
  const [progressRow, setProgressRow] = useState<string | null>(null);

  const claims = getClaimsQuery.data?.claims ?? [];

  const [showCreateDialog, setShowCreateDialog] = useState<{ type: ClaimType } | undefined>();
  const [showEditDialog, setShowEditDialog] = useState<Claim | undefined>();
  const [highlightedItem, setHighlightedItem] = useState<Claim | undefined>();
  const [removeConfirmDialog, setRemoveConfirmDialog] = useState<Claim | undefined>();
  const [tab, setTab] = useState<InnerTabs>(
    [
      "id_tokens",
      "id_tokens_verified_claims",
      "access_tokens",
      "saml_assertion",
      "custom_claims",
    ].includes(getParameterByName("tab") as InnerTabs)
      ? (getParameterByName("tab") as InnerTabs)
      : "access_tokens"
  );
  const highlightItemTimer = useRef<any>(null);

  const handleCreateClaim = (claims: Claim[]) => {
    setProgress(true);
    Promise.all(claims.map(c => adminClaimsApi.createClaim({ claim: c })))
      .then(() => setShowCreateDialog(undefined))
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getClaimsQueryKey(tenantId, workspace) })
      )
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to create claim"))
      .finally(() => setProgress(false));
  };

  const handleEditClaim = (claim: Claim) => {
    clearTimeout(highlightItemTimer.current);

    setProgress(true);
    setProgressRow(claim.id ?? "");
    adminClaimsApi
      .updateClaim({ updateClaimBody: claim, claim: claim.id! })
      .then(() => setShowEditDialog(undefined))
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getClaimsQueryKey(tenantId, workspace) })
      )
      .then(() => {
        setHighlightedItem(claim);
        highlightItemTimer.current = setTimeout(() => {
          setHighlightedItem(undefined);
        }, 2000);
      })
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to update claim"))
      .finally(() => {
        setProgressRow(null);
        setProgress(false);
      });
  };

  const handleToggleClaimOpaque = (claim: Claim) => {
    setProgress(true);
    setProgressRow(claim.id ?? "");
    adminClaimsApi
      .updateClaim({ updateClaimBody: { ...claim, opaque: !claim.opaque }, claim: claim.id! })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getClaimsQueryKey(tenantId, workspace) })
      )
      .then(() => setHighlightedItem(claim))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to update claim"))
      .finally(() => {
        setProgressRow(null);
        setProgress(false);
      });
  };

  const handleRemove = (claim: Claim) => {
    setProgress(true);
    setProgressRow(claim.id ?? "");
    adminClaimsApi
      .removeClaim({ claim: claim.id! })
      .then(() => setRemoveConfirmDialog(undefined))
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getClaimsQueryKey(tenantId, workspace) })
      )
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to delete claim"))
      .finally(() => {
        setProgressRow(null);
        setProgress(false);
      });
  };

  const idTokenClaims = claims
    .filter(c => c.type === "id_token")
    .filter(c => (isIdentityAssuranceEnabled ? !c.verified : true));

  const idTokenVerifiedClaims = claims.filter(c => c.type === "id_token" && !!c.verified);

  const accessTokenClaims = claims.filter(c => c.type === "access_token");
  const samlClaims = claims.filter(c => c.type === "saml_assertion");

  const attributes = getServerQuery.data?.authentication_context_settings?.attributes ?? [];

  return (
    <PageContainer>
      <PageHeader title="Claims" tabs={tabs} currentTab={tab} onChangeTab={onChangeTab} />
      <PageContent fullWidth>
        {tab === "id_tokens" && (
          <OAuthClaimsTab
            claims={idTokenClaims}
            fetching={getClaimsQuery.isFetching || getScopesQuery.isFetching}
            highlightedItem={highlightedItem}
            setShowCreateDialog={setShowCreateDialog}
            setShowEditDialog={setShowEditDialog}
            setRemoveConfirmDialog={setRemoveConfirmDialog}
            handleToggleClaimOpaque={handleToggleClaimOpaque}
            type="id_token"
            title="Total ID Token claims"
            id="id-tokens-claims"
            progressRow={queryClient.isFetching() ? null : progressRow}
            opaqueHeader="User info only"
            scopesNames={scopesNames}
          />
        )}

        {tab === "id_tokens_verified_claims" && isIdentityAssuranceEnabled && (
          <OAuthVerifiedClaimsTab
            claims={idTokenVerifiedClaims}
            fetching={getClaimsQuery.isFetching}
            highlightedItem={highlightedItem}
            setShowCreateDialog={setShowCreateDialog}
            setShowEditDialog={setShowEditDialog}
            setRemoveConfirmDialog={setRemoveConfirmDialog}
            type="id_token_verified_claim"
            title="Total ID Token claims"
            id="id-tokens-claims"
            progressRow={queryClient.isFetching() ? null : progressRow}
          />
        )}

        {tab === "access_tokens" && (
          <OAuthClaimsTab
            claims={accessTokenClaims}
            fetching={getClaimsQuery.isFetching || getScopesQuery.isFetching}
            highlightedItem={highlightedItem}
            setShowCreateDialog={setShowCreateDialog}
            setShowEditDialog={setShowEditDialog}
            setRemoveConfirmDialog={setRemoveConfirmDialog}
            handleToggleClaimOpaque={handleToggleClaimOpaque}
            type="access_token"
            title="Total Access Token claims"
            id="access-tokens-claims"
            progressRow={queryClient.isFetching() ? null : progressRow}
            opaqueHeader="Introspect only"
            scopesNames={scopesNames}
          />
        )}

        {tab === "saml_assertion" && (
          <SamlClaimsTab
            claims={samlClaims}
            fetching={getClaimsQuery.isFetching}
            highlightedItem={highlightedItem}
            setShowCreateDialog={setShowCreateDialog}
            setShowEditDialog={setShowEditDialog}
            setRemoveConfirmDialog={setRemoveConfirmDialog}
            type="saml_assertion"
            title="Total attributes"
            id="saml-attributes-claims"
            progressRow={queryClient.isFetching() ? null : progressRow}
          />
        )}

        {tab === "custom_claims" && (
          <ConnectScriptExtension
            stringLabel="Pre-token minting extension"
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>Pre-token minting extension</span>
                <InfoIconWithLink
                  link="https://docs.secureauth.com/ciam/en/enriching-token-claims-using-secureauth-extensions.html#about-enriching-token-claims"
                  label="Enrich Access and ID Tokens Claims"
                />
              </div>
            }
            caption="Select script to enrich tokens with custom claims or modify authorization context attributes"
            type={ScriptExecutionPointTypeEnum.TokenMinting}
            targetFk={workspace}
            serverId={workspace}
          />
        )}

        {showCreateDialog && showCreateDialog.type === "saml_assertion" && (
          <SamlClaimsCreate
            onCancel={() => setShowCreateDialog(undefined)}
            onCreate={handleCreateClaim}
            workspace={workspace}
            existingClaims={claims.filter(c => c.type === showCreateDialog?.type).map(c => c.name)}
            options={attributes}
            progress={progress}
          />
        )}

        {showCreateDialog && ["id_token", "access_token"].includes(showCreateDialog.type) && (
          <OAuthClaimsCreate
            onCancel={() => setShowCreateDialog(undefined)}
            onCreate={handleCreateClaim}
            workspace={workspace}
            model={showCreateDialog}
            existingClaims={claims.filter(c => c.type === showCreateDialog?.type).map(c => c.name)}
            options={attributes}
            progress={progress}
            scopesNames={scopesNames}
            scopesFetching={getScopesQuery.isFetching}
          />
        )}

        {showCreateDialog && showCreateDialog.type === "id_token_verified_claim" && (
          <OAuthVerifiedClaimsCreate
            onCancel={() => setShowCreateDialog(undefined)}
            onCreate={handleCreateClaim}
            workspace={workspace}
            existingClaims={claims.filter(c => c.type === showCreateDialog?.type).map(c => c.name)}
            progress={progress}
          />
        )}

        {showEditDialog && showEditDialog.type === "saml_assertion" && (
          <SamlClaimsEdit
            onCancel={() => setShowEditDialog(undefined)}
            onEdit={handleEditClaim}
            existingClaims={claims
              .filter(c => c.type === showEditDialog?.type)
              .map(c => c.name || "")}
            model={showEditDialog}
            options={attributes}
            progress={progress}
          />
        )}

        {showEditDialog &&
          ["id_token", "access_token"].includes(showEditDialog.type!) &&
          !showEditDialog.verified && (
            <OAuthClaimsEdit
              onCancel={() => setShowEditDialog(undefined)}
              onEdit={handleEditClaim}
              existingClaims={claims
                .filter(c => c.type === showEditDialog?.type)
                .map(c => c.name || "")}
              model={showEditDialog}
              options={attributes}
              progress={progress}
              workspace={workspace}
              scopesNames={scopesNames}
            />
          )}

        {showEditDialog && showEditDialog.type === "id_token" && showEditDialog.verified && (
          <OAuthVerifiedClaimsEdit
            onCancel={() => setShowEditDialog(undefined)}
            onEdit={handleEditClaim}
            existingClaims={claims
              .filter(c => c.type === showEditDialog?.type)
              .map(c => c.name || "")}
            model={showEditDialog}
            progress={progress}
          />
        )}

        {removeConfirmDialog && (
          <ConfirmationDialog
            title="Delete Claim"
            content={
              <>
                You're about to delete the <b>{removeConfirmDialog.name}</b> claim. This cannot be
                undone. Delete anyway?
              </>
            }
            confirmText="Delete"
            onCancel={() => setRemoveConfirmDialog(undefined)}
            onConfirm={() => handleRemove(removeConfirmDialog)}
            progress={progress}
          />
        )}
      </PageContent>
    </PageContainer>
  );
}
