import React, { useEffect, useState } from "react";

import Editor, { loader } from "@monaco-editor/react";
import BackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { StaticUser } from "@cloudentity/acp-admin";

import DialogFullScreen from "../../../common/components/DialogFullScreen";
import FormAccordion from "../../../common/components/FormAccordion";
import FormSection from "../../../common/components/FormSection";
import CheckboxField from "../../../common/utils/forms/CheckboxField";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormFooter from "../../../common/utils/forms/FormFooter";
import PhoneField from "../../../common/utils/forms/PhoneField";
import TextField from "../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../common/utils/forms/validation";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";

loader.config({
  paths: {
    vs: `${document.baseURI}assets/monaco/min/vs`,
  },
});

interface Props {
  user: StaticUser;
  inEdit?: boolean;
  serverId: string;
  onCancel: () => void;
  onSave: (data: StaticUser) => void;
  progress: boolean;
}

export default function StaticIDPEditUser({
  user,
  inEdit,
  serverId,
  onCancel,
  onSave,
  progress,
}: Props) {
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(serverId);

  const form = useForm({
    id: "static-idp-edit-user",
    initialValues: user,
    progress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps,
  });

  const [additionalAttributes, setAdditionalAttributes] = useState(
    JSON.stringify(user.additional_attributes, null, 2)
  );

  const [isSyntaxError, setSyntaxError] = useState(false);
  const handleValidate = annotations => setSyntaxError(annotations && annotations.length > 0);

  const email = form.watch("email");
  const emailVerified = form.watch("email_verified");
  const phoneNumber = form.watch("phone_number");
  const phoneNumberVerified = form.watch("phone_number_verified");

  useEffect(() => {
    if (!email && emailVerified) {
      form.setValue("email_verified", false);
    }
    if (!phoneNumber && phoneNumberVerified) {
      form.setValue("phone_number_verified", false);
    }
  }, [email, phoneNumber, emailVerified, phoneNumberVerified, form]);

  return (
    <DialogFullScreen
      id="static-idp-edit-user-dialog"
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          {inEdit ? (
            <>
              <IconButton
                id="static-idp-edit-user-dialog-back-button"
                onClick={() => onCancel()}
                style={{ marginRight: 16 }}
                size="large"
              >
                <BackIcon />
              </IconButton>
              Edit user
            </>
          ) : (
            <>Add user</>
          )}
        </div>
      }
    >
      <Form form={form}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={6}>
            <Paper style={{ padding: 32 }} variant="outlined" elevation={0}>
              <FormSection id="user-attributes-label" title="User attributes" />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextFieldRequired
                    name="username"
                    label="Username"
                    inputProps={{ autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextFieldRequired
                    name="password"
                    label="Password"
                    defaultVisibility={false}
                    toggleVisibility
                    inputProps={{ autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    name="email"
                    label="Email"
                    rules={{
                      validate: {
                        validEmail: validators.validEmail({ label: "Value" }),
                      },
                    }}
                    inputProps={{ autoComplete: "off" }}
                  />
                  <CheckboxField
                    name="email_verified"
                    label="Use email for MFA"
                    disabled={!email}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PhoneField
                    name="phone_number"
                    label="Phone"
                    selector="#static-idp-edit-user-phone-input"
                  />
                  <CheckboxField
                    name="phone_number_verified"
                    label="Use phone for MFA"
                    disabled={!phoneNumber}
                  />
                </Grid>
              </Grid>
              <FormAccordion
                title="Advanced settings"
                id="static-idp-edit-user"
                style={{ padding: 0 }}
              >
                <Typography style={{ padding: 22 }}>
                  Add additional user attributes below using standard JSON syntax
                </Typography>
                <Editor
                  defaultLanguage="json"
                  options={{
                    minimap: {
                      enabled: false,
                    },
                    scrollBeyondLastLine: false,
                    readOnly: !checkWorkspacePermissionsQuery.data?.manage_idps,
                  }}
                  height="200px"
                  value={additionalAttributes}
                  onChange={v => setAdditionalAttributes(v ?? "")}
                  wrapperProps={{
                    id: "static-idp-edit-user-editor",
                  }}
                  onValidate={handleValidate}
                />
              </FormAccordion>
              <FormFooter
                onCancel={onCancel}
                onSubmit={data =>
                  onSave({
                    ...data,
                    authentication_context: {
                      ...user.authentication_context,
                      name: data.username,
                    },
                    additional_attributes: JSON.parse(additionalAttributes || "{}"),
                  })
                }
                disabled={isSyntaxError}
              />
            </Paper>
          </Grid>
        </Grid>
      </Form>
    </DialogFullScreen>
  );
}
