import React, { lazy, useEffect, useMemo, useRef, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import { ArrowLeft, ArrowRight } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { PoolResponse } from "@cloudentity/acp-identity";
import { IDP } from "@cloudentity/acp-root";

import { getTenantId, previewBasePath } from "../../../../../common/api/paths";
import { useGetStyling } from "../../../../../common/api/stylingQuery";
import IconButton from "../../../../../common/components/IconButton";
import { getDefaultStyling, getStyling } from "../../../../../common/utils/hooks/useStyling";
import { useGetAuthorizationServer } from "../../../../services/adminServersQuery";
import { getBasicStyle } from "../../../customBranding/Preview";
import IdentitiesListSimplePreviewIframe from "./IdentitiesListSimplePreviewIframe";
import {
  createIdpButton,
  handleAddSeparator,
  handleIdpDiscoveryEnabled,
  handlePoolNoMethods,
  previewCardWidth,
  renderMethod,
} from "./utils";

const Confetti = lazy(() => import("../../../../../common/components/Confetti"));

const useStyles = makeStyles()(theme => ({
  container: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    background: "white",
    backgroundImage: "radial-gradient(#F4F4F4 1.5px, transparent 0)",
    backgroundSize: "8px 8px",
    backgroundPosition: "-4px -4px",
    border: "1px solid #E9EBFA",
    alignItems: "center",
    justifyContent: "center",
  },
  previewContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  iframesContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: 64,
    pointerEvents: "none",
    userSelect: "none",
  },
  shadowContainer: {
    position: "absolute",
    top: 0,
    height: "100%",
    width: 100,
  },
  shadowContainerLeft: {
    left: 0,
    boxShadow: "inset 100px 0px 43px -31px white",
  },
  shadowContainerRight: {
    right: 0,
    boxShadow: "inset -100px 0px 43px -31px white",
  },
  progress: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  previewMainLabel: {
    background: theme.custom.sa.greys.g80,
    padding: "0 8px",
    position: "absolute",
    top: "3vh",
    textAlign: "center",
    color: "white",
    borderRadius: 4,
    fontSize: 12,
  },
  empty: {
    color: "gray",
    position: "absolute",
    top: "40vh",
    left: "50%",
    transform: "translateX(-50%)",
  },
  bottomButtons: {
    position: "absolute",
    bottom: 24,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    marginTop: 48,
    zIndex: 2,
  },
  confettiContainer: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },
}));

const defaultUrl = `${previewBasePath}/login/preview?preview=true`;
const identityPoolUrl = `${previewBasePath}/preview?page=identity/login/index.tmpl&preview=true`;

interface Props {
  workspace: string;
  poolIdps: IDP[];
  externalIdps: IDP[];
  pool: PoolResponse | undefined;
  focusedCardIndex: number;
  setFocusedCardIndex: (focusedCardIndex: number) => void;
  idpsLoading: boolean;
  withConfetti?: boolean;
}

export default function IdentitiesListSimplePreview({
  workspace,
  poolIdps,
  externalIdps,
  pool,
  focusedCardIndex,
  setFocusedCardIndex,
  idpsLoading,
  withConfetti,
}: Props) {
  const { cx, classes } = useStyles();
  const theme = useTheme();

  const [isSelectIdpIframeLoaded, setIsSelectIdpIframeLoaded] = useState(false);
  const [isIdentityPoolIframeLoaded, setIsIdentityPoolIframeLoaded] = useState(false);
  const [progress, setProgress] = useState(false);
  const [isSelectIdpIframeVisible, setIsSelectIdpIframeVisible] = useState(false);
  const [isIdentityPoolIframeVisible, setIsIdentityPoolIframeVisible] = useState(false);
  const selectIdpIframe = useRef<HTMLIFrameElement>(null);
  const identityPoolIframe = useRef<HTMLIFrameElement>(null);
  const iframesContainerRef = useRef<HTMLIFrameElement>(null);

  const idpsForPreview = [
    ...poolIdps
      .filter(idp => !idp.hidden)
      .map(idp => (!idp.disabled ? { method: idp.method ?? "", name: idp.name ?? "" } : null))
      .filter((v): v is { method: string; name: string } => !!v),
    ...externalIdps
      .filter(idp => !idp.hidden)
      .map(idp => (!idp.disabled ? { name: idp.name ?? "", method: idp.method ?? "" } : null))
      .filter((v): v is { method: string; name: string } => !!v),
  ].filter(v => v);

  const showSelectIdpIframe = externalIdps.some(idp => !idp.disabled);
  const showIdentityPoolIframe =
    poolIdps.length === 1 && poolIdps[0].method === "identity_pool" && !poolIdps[0].disabled;

  const getAuthorizationServerQuery = useGetAuthorizationServer(getTenantId(), workspace);
  const idpDiscoveryEnabled = !!getAuthorizationServerQuery.data?.idp_discovery?.enabled;

  const handlePoolOptions = (content: Document) => {
    const form = content.querySelector<HTMLElement>("#sign-in-form");
    const poolMethodsContainer = content.querySelector<HTMLElement>("#pool-methods-container");
    const header = content.querySelector<HTMLElement>("#log-in-header");
    const elementWithMargin = content.querySelector<HTMLElement>(
      ".pool-login-remember-idp-switch-container"
    );
    const methods = pool?.authentication_mechanisms ?? [];

    if (pool && form) {
      if (poolMethodsContainer) {
        form.removeChild(poolMethodsContainer);
      }

      const methodsContainer = document.createElement("div");
      methodsContainer.id = "pool-methods-container";

      handleAddSeparator(methodsContainer, methods.length > 1);
      handlePoolNoMethods(form, methods.length === 0);

      [null, ...methods].forEach((method, index, arr) => {
        renderMethod(
          content,
          header,
          elementWithMargin,
          methodsContainer,
          method,
          index === arr.length - 1
        );
      });

      form.appendChild(methodsContainer);
    }
  };

  const getStylingQuery = useGetStyling(workspace);
  const defaultStyling = getDefaultStyling(theme);
  const styling = useMemo(
    () => getStyling({ styling: getStylingQuery.data, defaults: defaultStyling }),
    [defaultStyling, getStylingQuery.data]
  );
  const styles = useMemo(() => getBasicStyle(styling), [styling]);

  const reloadIframeContent = (
    iframeRef: React.RefObject<HTMLIFrameElement>,
    type: "select-idp" | "identity-pool"
  ) => {
    let cleanup = () => {};

    setProgress(true);
    if (iframeRef?.current) {
      let content =
        iframeRef.current?.contentWindow?.document || iframeRef.current?.contentDocument;
      if (content) {
        const cardContent = content.querySelector(".card > div") as HTMLElement;

        if (cardContent) {
          const cardContentCopy = cardContent.cloneNode(true) as HTMLElement;
          cardContentCopy.classList.add("preview-card-content");
          content.body.style.backgroundColor = "white";
          content.body.innerHTML = "";
          content.body.appendChild(cardContentCopy);
        }

        if (type === "identity-pool") {
          handlePoolOptions(content);
          setIsIdentityPoolIframeVisible(true);
        }

        if (type === "select-idp") {
          handleIdpDiscoveryEnabled(content, idpDiscoveryEnabled);
          setIsSelectIdpIframeVisible(true);
        }

        const idpsList = content.querySelector("#idps-list") as HTMLElement;
        if (idpsList) {
          idpsList.style.maxHeight = "unset";
          idpsList.innerHTML = "";

          idpsForPreview.forEach(({ name, method }) => {
            const button = createIdpButton(name, method);
            if (button) {
              idpsList.appendChild(button);
            }
          });
        }

        if (content.head) {
          const styleTag = content.querySelector("style.branding");
          if (!styleTag) {
            const style = content.createElement("style");
            style.id = "branding";
            style.textContent = styles;
            content.head.appendChild(style);
          } else {
            styleTag.textContent = styles;
          }
        }

        const submitButton = content.querySelector<HTMLElement>(".submit-button");
        if (submitButton) {
          submitButton.removeAttribute("disabled");
        }

        const previewCardContent = content.querySelector<HTMLElement>(".preview-card-content");
        if (previewCardContent) {
          const height = previewCardContent.getBoundingClientRect().height + 5;
          iframeRef.current.style.height = `${height}px`;
        }

        function blurActiveElement() {
          (content?.activeElement as Partial<HTMLElement>)?.blur?.();
        }

        content.addEventListener("focusin", blurActiveElement);
        cleanup = () => content.removeEventListener("focusin", blurActiveElement);
      }
    }
    setProgress(false);
    return cleanup;
  };

  useEffect(() => {
    if (isSelectIdpIframeLoaded) {
      return reloadIframeContent(selectIdpIframe, "select-idp");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectIdpIframeLoaded, idpsForPreview, styles]);

  useEffect(() => {
    if (isIdentityPoolIframeLoaded) {
      return reloadIframeContent(identityPoolIframe, "identity-pool");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdentityPoolIframeLoaded, idpsForPreview, styles]);

  useEffect(() => {
    function onResize() {
      const iframesContainer = iframesContainerRef.current;
      if (!iframesContainer) {
        return;
      }

      const actualFocusedCardIndex =
        showSelectIdpIframe && showIdentityPoolIframe ? focusedCardIndex : 0;
      const containerCenterX = iframesContainer.getBoundingClientRect().width / 2;
      const cardCenterX =
        actualFocusedCardIndex === 0
          ? previewCardWidth / 2
          : previewCardWidth + 64 + previewCardWidth / 2;

      iframesContainer.style.transform = `translateX(${containerCenterX - cardCenterX}px)`;
    }

    setTimeout(() => {
      if (!iframesContainerRef.current || iframesContainerRef.current.style.transition) {
        return;
      }
      iframesContainerRef.current.style.transition = "transform 1s";
    });

    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [focusedCardIndex, showSelectIdpIframe, showIdentityPoolIframe]);

  useEffect(() => {
    if (!showSelectIdpIframe) {
      setIsSelectIdpIframeVisible(false);
      setIsSelectIdpIframeLoaded(false);
    }
  }, [showSelectIdpIframe]);

  useEffect(() => {
    if (!showIdentityPoolIframe) {
      setIsIdentityPoolIframeVisible(false);
      setIsIdentityPoolIframeLoaded(false);
    }
  }, [showIdentityPoolIframe]);

  const loading =
    (showSelectIdpIframe && !isSelectIdpIframeVisible) ||
    (showIdentityPoolIframe && !isIdentityPoolIframeVisible) ||
    idpsLoading ||
    progress ||
    getAuthorizationServerQuery.isLoading;

  return (
    <div className={classes.container}>
      {loading && <LinearProgress className={classes.progress} />}
      {withConfetti && !loading && (
        <div className={classes.confettiContainer}>
          <Confetti />
        </div>
      )}
      <span className={classes.previewMainLabel}>Login page preview</span>
      <div className={classes.previewContainer}>
        <div className={classes.iframesContainer} ref={iframesContainerRef}>
          {showSelectIdpIframe && (
            <IdentitiesListSimplePreviewIframe
              url={defaultUrl}
              innerRef={selectIdpIframe}
              caption="All connections"
              isVisible={isSelectIdpIframeVisible}
              setIsIframeLoaded={setIsSelectIdpIframeLoaded}
            />
          )}
          {showIdentityPoolIframe && (
            <IdentitiesListSimplePreviewIframe
              url={identityPoolUrl}
              innerRef={identityPoolIframe}
              caption="Authentication methods"
              isVisible={isIdentityPoolIframeVisible}
              setIsIframeLoaded={setIsIdentityPoolIframeLoaded}
            />
          )}
        </div>
        {!showSelectIdpIframe && !showIdentityPoolIframe && !loading && (
          <div className={classes.empty}>Add first provider to show the preview</div>
        )}
        {showSelectIdpIframe && showIdentityPoolIframe && (
          <>
            <div className={cx(classes.shadowContainer, classes.shadowContainerLeft)} />
            <div className={cx(classes.shadowContainer, classes.shadowContainerRight)} />
            <div className={classes.bottomButtons}>
              <IconButton
                onClick={() => setFocusedCardIndex(0)}
                icon={ArrowLeft}
                style={{ background: "#fff", borderWidth: 2, color: "black" }}
                strokeWidth={3}
              />
              <IconButton
                onClick={() => setFocusedCardIndex(1)}
                icon={ArrowRight}
                style={{ marginLeft: 8, background: "#fff", borderWidth: 2, color: "black" }}
                strokeWidth={3}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
