import React, { useEffect } from "react";

import { ServerResponseProfileEnum } from "@cloudentity/acp-admin";

import signInExperienceImage from "../../../assets/images/backgrounds/tour/sing_in_experience.svg";
import { getTenantId } from "../../../common/api/paths";
import Carousel from "../../../common/components/Carousel";
import { BasicModalStep } from "../../../common/components/guidedTours/BasicModalStep";
import { BasicPopoverStep } from "../../../common/components/guidedTours/BasicPopoverStep";
import {
  StepActions,
  StepContent,
  StepTitle,
} from "../../../common/components/guidedTours/StepComponents";
import { TenantMetadataRegistration } from "../../components/getStartedV2/utils";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import { useGetTenant } from "../../services/adminTenantsQuery";
import { GlobalToursContext } from "../GlobalTours";
import Slide from "../components/Slide";

export const SIGN_IN_AND_USER_MANAGEMENT_USE_CASE_TOUR =
  "sign-in-and-user-management-use-case-tour";

export enum SignInAndUserManagementUseCaseTourSteps {
  SignInExperienceModal = "SignInExperienceModal",
  AdjustCustomerSignInOptions = "AdjustCustomerSignInOptions",
  SupportCustomerAndBusinessAccounts = "SupportCustomerAndBusinessAccounts",
  TrySignIn = "TrySignIn",
}

const dialogStyles = { minWidth: 600, border: "none", overflow: "visible" };
const cardStyles = { padding: 0, border: "none", overflow: "visible" };
const popoverStyle = { width: 460, padding: 24 };

const SignInAndUserManagementUseCaseTour = ({ workspace }: { workspace: string }) => {
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);
  const tenantQuery = useGetTenant(getTenantId());
  const registrationMetadata = tenantQuery.data?.metadata
    ?.registration as TenantMetadataRegistration;

  const tour = SIGN_IN_AND_USER_MANAGEMENT_USE_CASE_TOUR;

  const globalToursContext = React.useContext(GlobalToursContext);
  const currentTour = globalToursContext.getCurrentTour();
  const currentStep = globalToursContext.getCurrentStep();

  function onCompleteCurrentStep() {
    if (!currentTour || !currentStep) return;
    globalToursContext.complete(currentTour, currentStep.id);
  }

  function onGoToPreviousStep() {
    if (!currentTour || !currentStep) return;
    globalToursContext.goBack(currentTour, currentStep.id);
  }

  const hideTour =
    serverQuery.data?.name !== registrationMetadata?.company_name ||
    serverQuery.data?.profile !== ServerResponseProfileEnum.Consumer;

  useEffect(() => {
    if (hideTour) return;

    const TourSteps = [
      {
        id: SignInAndUserManagementUseCaseTourSteps.SignInExperienceModal,
        targets: [],
      },
      {
        id: SignInAndUserManagementUseCaseTourSteps.AdjustCustomerSignInOptions,
        targets: () => [document.querySelector<HTMLElement>("#identities-list-simple-paper")],
      },
      {
        id: SignInAndUserManagementUseCaseTourSteps.SupportCustomerAndBusinessAccounts,
        targets: () => [
          document.querySelector<HTMLElement>("#users-link"),
          document.querySelector<HTMLElement>("#organizations"),
        ],
      },
      {
        id: SignInAndUserManagementUseCaseTourSteps.TrySignIn,
        targets: () => [document.querySelector<HTMLElement>("#try-sign-in-button")],
      },
    ];

    globalToursContext.register({
      id: SIGN_IN_AND_USER_MANAGEMENT_USE_CASE_TOUR,
      runOnce: true,
      startOnRegister: true,
      steps: TourSteps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideTour]);

  if (hideTour) {
    return null;
  }

  return currentTour === tour ? (
    <>
      {currentStep?.id === SignInAndUserManagementUseCaseTourSteps.SignInExperienceModal && (
        <BasicModalStep
          ActionsComponent={false}
          ProgressComponent={false}
          dialogStyles={dialogStyles}
          cardStyles={cardStyles}
        >
          <Carousel
            slides={[
              {
                id: "1",
                image: signInExperienceImage,
                content: (
                  <Slide
                    header="Your Sign-In Screen is Ready!"
                    description={
                      <span>
                        Integrate your app and start onboarding customers.
                        <br /> Let use take you on a quick tour first.
                      </span>
                    }
                    buttonText="Continue"
                    handleButtonClick={onCompleteCurrentStep}
                    tour={tour}
                  />
                ),
              },
            ]}
          />
        </BasicModalStep>
      )}
      {currentStep?.id === SignInAndUserManagementUseCaseTourSteps.AdjustCustomerSignInOptions && (
        <BasicPopoverStep
          targets={currentStep.targets}
          scrollToElement
          placement="right-start"
          cardStyles={popoverStyle}
          onMaskClick={onCompleteCurrentStep}
        >
          <AdjustCustomerSignInOptionsStepContent
            onCompleteCurrentStep={onCompleteCurrentStep}
            onPrev={onGoToPreviousStep}
          />
        </BasicPopoverStep>
      )}
      {currentStep?.id ===
        SignInAndUserManagementUseCaseTourSteps.SupportCustomerAndBusinessAccounts && (
        <BasicPopoverStep
          targets={currentStep.targets}
          scrollToElement
          placement="right-start"
          cardStyles={popoverStyle}
          onMaskClick={onCompleteCurrentStep}
        >
          <SupportCustomerAndBusinessAccountsStepContent
            onCompleteCurrentStep={onCompleteCurrentStep}
            onPrev={onGoToPreviousStep}
          />
        </BasicPopoverStep>
      )}
      {currentStep?.id === SignInAndUserManagementUseCaseTourSteps.TrySignIn && (
        <BasicPopoverStep
          targets={currentStep.targets}
          scrollToElement
          placement="right-start"
          cardStyles={popoverStyle}
          onMaskClick={onCompleteCurrentStep}
        >
          <TrySignInStepContent
            onCompleteCurrentStep={onCompleteCurrentStep}
            onPrev={onGoToPreviousStep}
          />
        </BasicPopoverStep>
      )}
    </>
  ) : null;
};

const AdjustCustomerSignInOptionsStepContent = ({
  onCompleteCurrentStep,
  onPrev,
}: {
  onCompleteCurrentStep: () => void;
  onPrev: () => void;
}) => {
  return (
    <>
      <StepTitle id="1">Select Customer Sign-In Options</StepTitle>
      <StepContent>
        Provide customers with the most convenient sign-in options. Your customer admins will manage
        options themselves.
      </StepContent>
      <StepActions onNext={onCompleteCurrentStep} onPrev={onPrev} progressText="1 of 3" />
    </>
  );
};

const SupportCustomerAndBusinessAccountsStepContent = ({
  onCompleteCurrentStep,
  onPrev,
}: {
  onCompleteCurrentStep: () => void;
  onPrev: () => void;
}) => {
  return (
    <>
      <StepTitle id="2">Add Business and Consumer Accounts</StepTitle>
      <StepContent>
        Include self-registration, invitations and delegated account administration in your app.
        <p>
          We have created a <strong>Sample Company</strong> organization for you to explore the
          customer experience as an account admin.
        </p>
      </StepContent>
      <StepActions onNext={onCompleteCurrentStep} onPrev={onPrev} progressText="2 of 3" />
    </>
  );
};

const TrySignInStepContent = ({
  onCompleteCurrentStep,
  onPrev,
}: {
  onCompleteCurrentStep: () => void;
  onPrev: () => void;
}) => {
  const getTenantQuery = useGetTenant(getTenantId());
  const registrationMetadata = getTenantQuery.data?.metadata
    ?.registration as TenantMetadataRegistration;

  return (
    <>
      <StepTitle id="3">Experience Sign-In and Account Administration</StepTitle>
      <StepContent>
        Sign-in with <strong>{registrationMetadata?.email}</strong> to a sample app. Access
        integrated admin panel and invite other users to the app.
      </StepContent>
      <StepActions
        onNext={onCompleteCurrentStep}
        onPrev={onPrev}
        nextLabel="Finish"
        progressText="3 of 3"
      />
    </>
  );
};

export default SignInAndUserManagementUseCaseTour;
