import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  loading: {
    position: "absolute",
    left: "50%",
    bottom: 16,
    transform: "translateX(-50%)",
    backgroundColor: "white",
    zIndex: 2,
    padding: theme.spacing(1),
    textAlign: "center",
    borderRadius: "50%",
    boxShadow: theme.custom.shadows.surfaceHover,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 48,
    width: 48,
  },
}));

interface Props {
  isFetching: boolean;
}

export default function InfiniteScrollLoading({ isFetching }: Props) {
  const { classes } = useStyles();

  return isFetching ? (
    <div className={classes.loading}>
      <CircularProgress size={24} />
    </div>
  ) : null;
}
