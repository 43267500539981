import React, { ReactNode, useCallback, useState } from "react";

import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import { getTenantId } from "../../../common/api/paths";
import { TOP_BAR_HEIGHT } from "../../../common/components/nav/utils";
import { handleErrorWithNotify } from "../../../common/components/notifications/notificationService";
import adminTenantsApi from "../../services/adminTenantsApi";
import { getTenantQueryKey, useGetTenant } from "../../services/adminTenantsQuery";

export interface TenantMetadataRegistration {
  email: string;
  first_name: string;
  last_name: string;
  company_name: string;
  zone_id: string;
}

export enum Steps {
  WELCOME = "welcome",
  MAIN_USE_CASE_SIGN_IN = "sign-in",
  MAIN_USE_CASE_REGISTER_USER = "register-user",
  MAIN_USE_CASE_EXPLORE = "explore",
  OPENBANKING_USE_CASE_SELECT = "openbanking-select",
}

export const useCommonStyles = makeStyles()(theme => ({
  leftWithPreview: {
    width: 650,
    zIndex: 1,
  },
  right: {
    height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
    position: "sticky",
    top: TOP_BAR_HEIGHT,
  },
  header: {
    margin: "10px 0",
  },
  intro: {
    marginBottom: 24,
    position: "relative",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  additionalSection: {
    padding: "12px 0 32px",
  },
  buttonExplore: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#eef4fb",
    borderRadius: 4,
    padding: "0px 16px",
    minHeight: 32,
    "& > span": {
      fontWeight: 500,
    },
  },
  backButton: {
    color: theme.palette.secondary.light,
    marginLeft: -16,
  },
  smallInfo: {
    color: theme.custom.sa.neutrals.n80,
    fontSize: 12,
    marginTop: 12,
  },
  divider: {
    margin: "24px 0",
  },
  recommendedChip: {
    color: "#eab344",
    backgroundColor: theme.custom.sa.complimentary.warning.low,
    padding: "4px 8px",
    position: "absolute",
    top: 8,
    right: 8,
    borderRadius: 4,
  },
}));

export function SmallInfoText({ children, fontSize }: { children: ReactNode; fontSize?: number }) {
  const { classes: commonClasses } = useCommonStyles();

  return (
    <Typography
      variant="textMD"
      component="div"
      className={commonClasses.smallInfo}
      style={fontSize ? { fontSize } : {}}
    >
      {children}
    </Typography>
  );
}

export const useMarkGetStartedVisited = () => {
  const [progress, setProgress] = useState(false);

  const getTenantQuery = useGetTenant(getTenantId());
  const queryClient = useQueryClient();
  const shouldVisitGetStarted = !!getTenantQuery.data?.metadata?.should_visit_get_started;

  const markGetStartedVisited = useCallback(() => {
    setProgress(true);
    return adminTenantsApi
      .updateTenant({
        tenant: {
          ...getTenantQuery.data,
          metadata: { ...getTenantQuery.data?.metadata, should_visit_get_started: false } as any,
        },
      })
      .then(() => queryClient.invalidateQueries({ queryKey: getTenantQueryKey(getTenantId()) }))
      .catch(
        handleErrorWithNotify("Error occurred while trying to mark Get Started flow as visited")
      )
      .finally(() => setProgress(true));
  }, [getTenantQuery.data, queryClient]);

  return { markGetStartedVisited, progress, shouldVisitGetStarted };
};
