import React, { useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";
import merge from "lodash/merge";

import { EmailSettingsProviderEnum, MFAMethod } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import FormSection from "../../../../common/components/FormSection";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import {
  handleErrorWithNotify,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { BRAND_NAME } from "../../../../common/theme/theme";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormCardsField from "../../../../common/utils/forms/FormCardsField";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import RadioGroupField from "../../../../common/utils/forms/RadioGroupField";
import TextField from "../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";
import adminMfaMethodsApi from "../../../services/adminMfaMethodsApi";
import {
  getMfaMethodQueryKey,
  getMfaMethodsQueryKey,
  useGetMfaMethod,
  useGetMfaMethods,
} from "../../../services/adminMfaMethodsQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import PageContent from "../../common/PageContent";
import MessageProvidersEmailsTestMessage from "./MessageProvidersEmailsTestMessage";

export default function MessageProvidersEmails() {
  const [progress, setProgress] = useState(false);
  const tid = getTenantId();
  const queryClient = useQueryClient();
  const getMfaMethodsQuery = useGetMfaMethods(tid);
  const getMfaMethodEmailQuery = useGetMfaMethod(tid, "email");

  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const defaultEmailSettings = getMfaMethodEmailQuery.data?.default_email_settings;

  const updateMfaMethod = (newMfaMethod: MFAMethod) => {
    setProgress(true);
    adminMfaMethodsApi
      .updateMfaMethod({ mfaID: "email", mFAMethod: newMfaMethod })
      .then(() => queryClient.invalidateQueries({ queryKey: getMfaMethodsQueryKey(tid) }))
      .then(() => queryClient.invalidateQueries({ queryKey: getMfaMethodQueryKey(tid, "email") }))
      .then(() => notifySuccess("Email configuration saved successfully"))
      .catch(handleErrorWithNotify("Error occurred while trying to update mfa methods"))
      .finally(() => setProgress(false));
  };

  const initialData = useMemo(() => {
    if (getMfaMethodsQuery.data) {
      return (
        (getMfaMethodsQuery.data?.mfa_methods || []).find(v => v.id === "email") ??
        ({} as MFAMethod)
      );
    }

    return {} as MFAMethod;
  }, [getMfaMethodsQuery.data]);

  const form = useForm({
    id: "mfa-email-delivery",
    initialValues: initialData,
    noManagePermission: !checkTenantPermissionsQuery.data?.manage_mfa_methods,
    progress,
  });

  const provider = form.watch("settings.email.provider");

  return (
    <Form form={form} noFormTag>
      <PageContent
        progress={
          getMfaMethodsQuery.isLoading ||
          getMfaMethodEmailQuery.isLoading ||
          checkTenantPermissionsQuery.isLoading
        }
        fullWidth={false}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Paper style={{ padding: 32 }}>
              <FormSection id="mfa-email-delivery-provider-label" title="Email configuration" />

              <FormCardsField
                name="settings.email.provider"
                cards={[
                  {
                    title: BRAND_NAME,
                    subtitle: `Send email using the ${BRAND_NAME} SMTP server`,
                    value: EmailSettingsProviderEnum.Embedded,
                  },
                  {
                    title: "Custom smtp",
                    subtitle: "Send emails using your own SMTP server",
                    value: EmailSettingsProviderEnum.Smtp,
                  },
                ]}
              />

              {provider === EmailSettingsProviderEnum.Smtp && (
                <>
                  <RadioGroupField
                    name="auth.email.auth"
                    title="SMTP Auth mechanism"
                    values={[
                      { key: "plain", label: "Plain", value: "plain" },
                      { key: "crammd5", label: "CRAM-MD5", value: "crammd5" },
                      { key: "login", label: "LOGIN", value: "login" },
                    ]}
                    rules={{
                      required: "Auth mechanism is required",
                    }}
                  />

                  <TextFieldRequired name="auth.email.host" label="SMTP host" />

                  <TextFieldRequired
                    name="auth.email.port"
                    label="Port"
                    type="number"
                    valueAsNumber
                    rules={{
                      validate: {
                        validNumeric: validators.validNumeric({ label: "Port" }),
                        notZero: v => {
                          if (v === 0) {
                            return "Port cannot be equal to 0";
                          }
                          return true;
                        },
                      },
                    }}
                  />

                  <TextFieldRequired
                    name="auth.email.username"
                    label="Username"
                    helperText="Username which is used to authenticate to SMTP server"
                  />

                  <TextFieldRequired
                    name="auth.email.password"
                    label="Password"
                    helperText="Password which is used to authenticate to SMTP server"
                    defaultVisibility={false}
                    toggleVisibility
                  />
                </>
              )}

              {provider === EmailSettingsProviderEnum.Smtp && (
                <>
                  <FormSection id="mfa-email-settings-label" title="Email settings" />

                  <TextField
                    name="settings.email.custom_from"
                    label="Sender friendly name"
                    placeholder={
                      defaultEmailSettings?.from || "SecureAuth <noreply@secureauth.com>"
                    }
                    rules={{
                      validate: {
                        isEmail: validators.validEmail({
                          label: "Sender friendly name",
                          customErrorMsg: `Allowed format is "John Doe <jdoe@secureauth.com>" or "jdoe@secureauth.com"`,
                          options: { allow_display_name: true, require_tld: false },
                        }),
                      },
                    }}
                    optional={false}
                  />
                </>
              )}

              <FormFooter
                onSubmit={data =>
                  updateMfaMethod(
                    merge(initialData, {
                      ...data,
                      settings: {
                        ...data.settings,
                        email: {
                          ...data.settings?.email,
                          custom_from: data.settings?.email?.custom_from?.trim(),
                        },
                      },
                    })
                  )
                }
              />
            </Paper>
          </Grid>

          <Grid item xs={12} lg={4}>
            <MessageProvidersEmailsTestMessage />
          </Grid>
        </Grid>
      </PageContent>
      <RouteLeavingGuard />
    </Form>
  );
}
