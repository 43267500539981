import React, { useRef, useState } from "react";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import isEmpty from "lodash/isEmpty";

import FormSection from "../../../../common/components/FormSection";
import Popover from "../../../../common/components/Popover";
import Progress from "../../../../common/components/Progress";
import { UpgradeButton } from "../../../../common/components/upgrade/UpgradeButton";
import UpgradeModal from "../../../../common/components/upgrade/UpgradeModal";
import { BRAND_NAME } from "../../../../common/theme/theme";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import {
  useFeature,
  useFeatureIsOrganizationsEnabled,
} from "../../../../common/utils/hooks/useFeature";
import { useGetLicense } from "../../../services/adminLicenseQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import { embeddedIdps, ProviderMapperType, providers, ProvidersType } from "../identities.utils";
import IdentitiesCreateIdpCard from "./IdentitiesCreateIdpCard";

export interface IdentitiesCreateAddStepProps {
  onNext?: (selected: ProviderMapperType | undefined) => void;
  onCancel?: () => void;
  customSubmit?: boolean;
  selected: ProviderMapperType | undefined;
  setSelected: (selected: ProviderMapperType | undefined) => void;
}

export default function IdentitiesCreateAddStep({
  onNext,
  onCancel,
  selected,
  setSelected,
  customSubmit = false,
}: IdentitiesCreateAddStepProps) {
  const form = useForm({ id: "identities-create-add-step" });
  const cloudentityIDPEnabled = useFeature("cloudentity_idp");
  const isOrganizationsEnabled = useFeatureIsOrganizationsEnabled();
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const licenseQuery = useGetLicense();
  const hasEnterpriseIdpsCapability =
    !licenseQuery.data?.enforcement_enabled || licenseQuery.data?.has_enterprise_idps_capability;

  const container = useRef<HTMLDivElement>(null);

  const [upgradeAnchorEl, setUpgradeAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [upgradeSentModal, setUpgradeSentModal] = useState(false);

  const cloudentityProviders = providers
    .filter(p => cloudentityIDPEnabled || p.method !== "cloudentity")
    .filter(p => isOrganizationsEnabled || p.method !== "organization")
    .filter(p => p.type === "cloudentity")
    .filter(p =>
      checkTenantPermissionsQuery.data?.create_identity_pool ? true : p.method !== "identity_pool"
    );

  const thirdPartyProviders = providers.filter(
    p =>
      p.type !== ProvidersType.custom &&
      p.type !== ProvidersType.cloudentity &&
      p.method !== "saml" &&
      !embeddedIdps.includes(p.method)
  );

  return checkTenantPermissionsQuery.isLoading || licenseQuery.isLoading ? (
    <Progress />
  ) : (
    <Form form={form}>
      {cloudentityProviders.length > 0 && (
        <>
          <FormSection
            title={`${BRAND_NAME} Providers`}
            id="cloudentity-providers-title"
            noBorder
          />
          <div style={{ marginBottom: 32, display: "flex", gap: 24 }}>
            {cloudentityProviders.map(provider => (
              <IdentitiesCreateIdpCard
                key={provider.method}
                provider={provider}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          </div>
        </>
      )}

      <FormSection
        title={`Third Party Providers (${thirdPartyProviders.length})`}
        id="third-party-providers-title"
        noBorder
        style={{ paddingTop: 8 }}
      ></FormSection>
      <div ref={container} style={{ marginBottom: 32, display: "flex", gap: 24, flexWrap: "wrap" }}>
        {thirdPartyProviders.map(provider => (
          <IdentitiesCreateIdpCard
            key={provider.method}
            provider={provider}
            selected={selected}
            setSelected={setSelected}
            subtitle={provider.description}
            isAvailable={hasEnterpriseIdpsCapability || provider.type !== "enterprise"}
            setUpgradeAnchorEl={setUpgradeAnchorEl}
          />
        ))}
      </div>

      <FormSection
        title="Other Providers"
        id="other-providers-title"
        noBorder
        style={{ paddingTop: 8 }}
      />
      <div style={{ paddingBottom: 32, display: "flex", gap: 24 }}>
        {providers
          .filter(
            p =>
              p.type !== ProvidersType.cloudentity &&
              p.type !== ProvidersType.enterprise &&
              p.type !== ProvidersType.social
          )
          .map(provider => (
            <IdentitiesCreateIdpCard
              key={provider.method}
              provider={provider}
              selected={selected}
              setSelected={setSelected}
              subtitle={provider.description}
            />
          ))}
      </div>
      {!customSubmit && (
        <>
          <Divider style={{ marginBottom: 48 }} />

          <FormFooter
            onCancel={onCancel}
            disabled={isEmpty(selected)}
            onSubmit={() => onNext && onNext(selected)}
            submitText="Next"
          />
        </>
      )}

      <Popover
        open={Boolean(upgradeAnchorEl)}
        anchorEl={upgradeAnchorEl}
        onClose={() => setUpgradeAnchorEl(null)}
        header="Enterprise Connection"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ marginTop: 16 }}
        maxWidth={320}
        withArrow
      >
        <div style={{ marginBottom: 16 }}>
          <Typography variant="textSM">
            Upgrade to the Enterprise plan to add more connections and unlock an entirely new level
            of features.
          </Typography>
        </div>
        <UpgradeButton
          onUpgradeSent={() => {
            setUpgradeAnchorEl(null);
            setUpgradeSentModal(true);
          }}
        />
      </Popover>

      {upgradeSentModal && (
        <UpgradeModal
          onClose={() => {
            setUpgradeSentModal(false);
          }}
        />
      )}
    </Form>
  );
}
