import React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import sessionWillExpire from "../assets/images/icons/sessionExpired/session-will-expire.svg";
import { pathPrefix, portalMode, portalType } from "../common/api/paths";
import { logout } from "../common/auth/actions/actions";
import ConfirmationDialog from "../common/components/ConfirmationDialog";
import { setInLocalStorage } from "../common/utils/localStorage.utils";

const useStyles = makeStyles()(theme => ({
  img: {
    width: "100%",
  },
  content: {
    marginTop: 24,
    "& > p": {
      marginTop: 16,
    },
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
}));

export default function SessionWillExpire({
  timeLeftInSeconds,
  onCancel,
}: {
  timeLeftInSeconds: undefined | number;
  onCancel: () => void;
}) {
  const { classes } = useStyles();

  return (
    <ConfirmationDialog
      id="session-expired-modal"
      onlyExtraActions
      extraActions={
        <>
          <Button onClick={() => onCancel()}>Cancel</Button>
          <Button
            variant="contained"
            style={{ marginLeft: 12 }}
            onClick={() => {
              if (portalType === "admin" && portalMode === "portal") {
                setInLocalStorage("last_path", window.location.pathname.replace(pathPrefix, ""));
              }
              logout(portalType);
            }}
          >
            Logout
          </Button>
        </>
      }
      mode="info"
      onCancel={() => {}}
      content={
        <>
          <img src={sessionWillExpire} alt="session expired" className={classes.img} />

          <div className={classes.content}>
            <>
              <Typography variant="h3">
                Your session will expire in{" "}
                <span className={classes.primaryColor}>{Math.round(timeLeftInSeconds || 0)}</span>{" "}
                sec
              </Typography>
              <Typography>
                For your security, your session will expire. Make sure all changes are saved.
              </Typography>
            </>
          </div>
        </>
      }
    />
  );
}
