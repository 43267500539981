import React from "react";

import IconButton from "@mui/material/IconButton";
import { makeStyles } from "tss-react/mui";

import icon from "../../../../assets/images/icons/quickstart/icon-crown.svg";
import SelectablePaper from "../../../../common/components/SelectablePaper";
import CardWithIconAndTitle from "../../common/CardWithIconAndTitle";
import { ProviderMapperType } from "../identities.utils";

const useStyles = makeStyles()(() => ({
  button: {
    position: "absolute",
    top: 8,
    right: 8,
  },
}));

interface Props {
  provider: ProviderMapperType;
  selected: ProviderMapperType | undefined;
  setSelected: (selected: ProviderMapperType | undefined) => void;
  subtitle?: string;
  isAvailable?: boolean;
  setUpgradeAnchorEl?: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}

export default function IdentitiesCreateIdpCard({
  provider,
  selected,
  setSelected,
  subtitle,
  isAvailable = true,
  setUpgradeAnchorEl,
}: Props) {
  const { classes } = useStyles();

  return (
    <SelectablePaper
      selected={selected?.method === provider.method}
      onSelect={() => setSelected(provider)}
      selectable={isAvailable}
      data-value={provider.method}
      data-isavailable={isAvailable}
      style={{ width: 300, position: "relative" }}
      disabled={!isAvailable}
    >
      <CardWithIconAndTitle
        img={provider.icon}
        title={provider.name}
        subtitle={subtitle || undefined}
        id={`idp-${provider.name.replace(/ /g, "-")}`}
        noBorder
        titleStyle={{ fontWeight: 600 }}
        subtitleStyle={subtitle ? { fontSize: 10, maxWidth: "calc(100% - 8px)" } : {}}
      />
      {!isAvailable && setUpgradeAnchorEl && (
        <IconButton onClick={e => setUpgradeAnchorEl(e.currentTarget)} className={classes.button}>
          <img src={icon} alt="icon" />
        </IconButton>
      )}
    </SelectablePaper>
  );
}
