import React, { ReactNode } from "react";

import PopoverMUI, { PopoverProps as PopoverMUIProps } from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { X } from "react-feather";
import { makeStyles } from "tss-react/mui";

import IconButton from "./IconButton";

const useStyles = makeStyles<{ maxWidth?: number; withArrow?: boolean }>()(
  (theme, { maxWidth, withArrow }) => ({
    paper: {
      backgroundColor: theme.custom.sa.dark.dark,
      color: "white",
      padding: 16,
      maxWidth: maxWidth || 210,
      ...(withArrow && {
        overflowX: "unset",
        overflowY: "unset",
        "&::before": {
          content: '""',
          position: "absolute",
          top: -20,
          right: "50%",
          width: 20,
          height: 20,
          backgroundColor: theme.custom.sa.dark.dark,
          boxShadow: theme.shadows[1],
          transform: "translate(50%, 50%) rotate(-45deg)",
          clipPath:
            "polygon(-10px -10px, calc(100% + 10px) -10px, calc(100% + 10px) calc(100% + 10px))",
        },
      }),
    },
    closeIcon: {
      position: "absolute",
      top: 4,
      right: 4,
      color: "white",
    },
  })
);

interface PopoverProps extends PopoverMUIProps {
  header?: ReactNode;
  onCloseIcon?: () => void;
  maxWidth?: number;
  withArrow?: boolean;
}

const Popover = ({
  children,
  header,
  onCloseIcon,
  maxWidth,
  withArrow,
  ...props
}: PopoverProps) => {
  const { classes } = useStyles({ maxWidth, withArrow });

  return (
    <PopoverMUI
      classes={{
        paper: classes.paper,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      {...props}
    >
      {header && (
        <Typography variant="textSM" style={{ fontWeight: 600, lineHeight: "22px" }}>
          {header}
        </Typography>
      )}
      {!!onCloseIcon && (
        <IconButton icon={X} onClick={onCloseIcon} noBorder className={classes.closeIcon} />
      )}
      {children}
    </PopoverMUI>
  );
};

export default Popover;
