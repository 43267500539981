import React from "react";
import { useParams } from "react-router";

import { useQueryClient } from "@tanstack/react-query";

import { ServerResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-admin";
import { Pool, PoolResponse } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../common/api/paths";
import Dialog from "../../../../common/components/Dialog";
import { notifySuccess } from "../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextField from "../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";
import {
  setDefaultAuthenticationMechanismsIfAvailableForWorkspace,
  useCreateWorkspaceIdentityPoolMutation,
} from "../../../services/adminIdentityPoolsMutations";
import { listWorkspacePoolsQueryKey } from "../../../services/adminIdentityPoolsQuery";
import { useGetAuthorizationServer } from "../../../services/adminServersQuery";

interface Props {
  onCreated: (pool: PoolResponse) => void;
  onClose: () => void;
  workspaceId?: string;
}

export default function AddIdentityPoolDialog({ onCreated, onClose, workspaceId }: Props) {
  const { id } = useParams<{ id: string }>();

  const createWorkspaceIdentityPoolMutation = useCreateWorkspaceIdentityPoolMutation();
  const getServerQuery = useGetAuthorizationServer(getTenantId(), workspaceId || id || "");

  const form = useForm({
    id: "add-team",
    progress: createWorkspaceIdentityPoolMutation.isPending || getServerQuery.isLoading,
  });
  const queryClient = useQueryClient();

  const handleCreatePool = data => {
    const pool: Pool = {
      name: data.name.trim(),
      description: data.description?.trim(),
      tenant_id: getTenantId(),
      workspace_id: workspaceId || id,
      identifier_case_insensitive: true,
      metadata_schema_id: "organizations_pool_default_metadata",
      payload_schema_id: "organizations_pool_default_payload",
    };

    setDefaultAuthenticationMechanismsIfAvailableForWorkspace({
      pool,
      supportedAuthenticationMechanisms: getServerQuery.data?.authentication_mechanisms || [],
      authenticationMechanism: ServerResponseAuthenticationMechanismsEnum.Password,
      setAuthenticationMechanismsAsPreferred: true,
    });

    let createdPool: PoolResponse;
    return createWorkspaceIdentityPoolMutation
      .mutateAsync({
        wid: workspaceId || id || "",
        pool,
        withIdp: true,
      })
      .then(res => {
        createdPool = res.data;
        return res;
      })
      .then(res => {
        notifySuccess(
          <span>
            <>
              Identity pool <strong>{data.name}</strong> with Identity Provider{" "}
              <strong>{res?.data?.name ?? ""}</strong> created
            </>
          </span>
        );
      })
      .then(() =>
        queryClient.removeQueries({
          queryKey: listWorkspacePoolsQueryKey(workspaceId || id || ""),
        })
      )
      .then(() => onCreated(createdPool))
      .then(onClose);
  };

  return (
    <Dialog id="identity-pool-create-dialog" title="Create identity pool" onClose={onClose}>
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label="Pool name"
          rules={{
            validate: {
              onlyAlphanumeric: validators.onlyAlphanumericWithWhitespacesDashUnderscore({
                label: "Name",
              }),
            },
          }}
        />

        <TextField name="description" label="Description" multiline maxRows={3} minRows={3} />

        <FormFooter
          onCancel={onClose}
          onSubmit={handleCreatePool}
          submitText="Create"
          submitWithEnterKey
        />
      </Form>
    </Dialog>
  );
}
